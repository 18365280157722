import { AddCircle, Edit } from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton, Paper } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { CampaignActioner } from 'services/campaign';

function CampaignListComponent() {
  const [data, setData] = useState({ loading: true, docs: [] });
  const history = useHistory();

  useEffect(() => {
    if (data.loading) {
      CampaignActioner.list().then(docs => {
        setData(f => ({ ...f, loading: false, docs }));
      });
    }
    return () => {};
  }, [data.loading]);

  const {
    messages: {
      scenes: {
        campaign: { list: intl },
      },
    },
  } = useIntl();
  const columnTitles = intl.columnTitles;

  const columns = useMemo(
    () => [
      {
        header: columnTitles.name,
        accessorKey: 'name',
      },
      {
        header: columnTitles.description,
        accessorKey: 'description',
      },
    ],
    [columnTitles.description, columnTitles.name],
  );

  if (data.loading) {
    return (
      <Paper className="padding-small">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="height-circular"
        >
          <CircularProgress color="primary" />
        </Grid>
      </Paper>
    );
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={data.docs}
      enableDensityToggle={false}
      enableColumnActions={false}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 25, 50, 100, 150, { label: 'All', value: data?.docs?.length ?? 0 }],
      }}
      muiTableBodyCellProps={{
        //simple styling with the `sx` prop, works just like a style prop in this example
        sx: {
          fontWeight: 'normal',
          fontSize: '110%',
        },
      }}
      positionActionsColumn="last"
      positionGlobalFilter="left" //show the global filter on the left side of the top toolbar
      initialState={{
        showGlobalFilter: true, //show the global filter by default
        density: 'compact',
        pagination: {
          pageSize: 25,
        },
      }}
      enableRowActions={true}
      renderRowActions={({ row }, index) => {
        return (
          <IconButton
            key={index}
            size="small"
            component={Link}
            to={paths.front.campaign.edit.replace(':id', row.original._id)}
          >
            <Edit />
          </IconButton>
        );
      }}
      enableToolbarInternalActions={true}
      renderTopToolbarCustomActions={() => {
        return (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={() => history.push(paths.front.campaign.create)}
          >
            {intl.button.add}
          </Button>
        );
      }}
    />
  );
}

const CampaignList = CampaignListComponent;

export { CampaignList };
