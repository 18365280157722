import { createContext, useContext, useRef } from 'react';

const TableContext = createContext();

export const TableProvider = ({ children }) => {
  const ref = useRef();

  return <TableContext.Provider value={ref}>{children}</TableContext.Provider>;
};

export const useTableContext = () => useContext(TableContext);
