import { AddCircle, Edit } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { MultipleSelector } from 'components/MultipleSelector';
import { Selector } from 'components/Selector';
import { TimeInput } from 'components/TimeInput';
import moment from 'moment';
import 'moment/locale/fr';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { ComonEnums } from 'services/comon';

moment.locale('fr');

function AddAvailabilitiesComponent({
  setOpenCreate,
  openCreate,
  addAvailabilities,
  editAvailability,
  disabled,
  editItem,
  editItemIndex,
  data,
  ...props
}) {
  const emptyAvailabilities = {
    day: [],
    start_hour: null,
    end_hour: null,
  };
  const [newAvailabilities, setNewAvailabilities] = useState(editItem ?? emptyAvailabilities);
  const intl = props.intl.messages.components.availabilities;

  const weekDay = ComonEnums.getDays();

  function setFieldFunction(name) {
    return value => {
      setNewAvailabilities(a => ({ ...a, [name]: value }));
    };
  }

  function addNewAvailabilities() {
    if (newAvailabilities.day.some(d => d === 'ALL_DAYS')) {
      const weekDays = weekDay.filter(d => d.value !== 'ALL_DAYS' && !data.some(dt => dt.day === d.value));
      addAvailabilities(weekDays.map(d => ({ ...newAvailabilities, day: d.value })));
    } else {
      addAvailabilities(newAvailabilities.day.map(d => ({ ...newAvailabilities, day: d })));
    }
  }

  return (
    <>
      {openCreate && (
        <Grid justifyContent="space-evenly" container>
          <Grid item xs={8} sm={8} className="padding-small">
            <Grid justifyContent="space-evenly" container>
              <Typography component="span" variant="h6" className="marginB20">
                {intl.table.dialogTitle.toUpperCase()}
              </Typography>
            </Grid>
            {editItem !== undefined ? (
              <Selector
                labelId="day"
                label={intl.labels.day}
                selected={newAvailabilities.day}
                setSelected={setFieldFunction('day')}
                items={weekDay.filter(w => w.value !== 'ALL_DAYS')}
                disabled={disabled}
              />
            ) : (
              <MultipleSelector
                labelId="day"
                label={intl.labels.day}
                selected={newAvailabilities.day}
                setSelected={setFieldFunction('day')}
                items={weekDay}
                disabled={disabled}
              />
            )}
            <Grid item container className="marginT20 marginB20" spacing={1} fullwidth>
              <Grid item xs={6} sm={6}>
                <TimeInput
                  labelId="start_hour"
                  label={intl.labels.start_hour}
                  value={newAvailabilities.start_hour}
                  setFieldFunction={setFieldFunction('start_hour')}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TimeInput
                  labelId="end_hour"
                  label={intl.labels.end_hour}
                  value={newAvailabilities.end_hour}
                  setFieldFunction={setFieldFunction('end_hour')}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className="marginB20" justifyContent="space-evenly" container>
            <Button
              variant="contained"
              color="primary"
              startIcon={editItem !== undefined ? <Edit /> : <AddCircle />}
              onClick={() => {
                editItem !== undefined ? editAvailability(newAvailabilities, editItemIndex) : addNewAvailabilities();
                setNewAvailabilities(emptyAvailabilities);
                setOpenCreate(false);
              }}
            >
              {editItem !== undefined ? intl.table.edit : intl.table.add}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}

const AddAvailabilities = injectIntl(AddAvailabilitiesComponent);

export { AddAvailabilities };
