import { TableFormAndDisplay } from 'components/TableFormAndDisplay';
import { injectIntl } from 'react-intl';
import { SubjectUtils } from 'services/subject';
import '../../style/VolunteerForm.css';
import { AddSubjects } from './components';

function SubjectFormComponent({ subjects, data, setData, disabled, mode, ...props }) {
  const intl = props.intl.messages.components.subjects;
  const labels = intl.labels;
  const allData = SubjectUtils.getBuitifullData(data, subjects.subjects, subjects.themeSubjects);
  const allSubjects = SubjectUtils.getBuitifullSubject(subjects.subjects, subjects.themeSubjects);

  function deleteSubjects(index) {
    return () => {
      const newSubjects = [...data];
      newSubjects.splice(index, 1);
      setData(newSubjects);
    };
  }

  function addSubjects(newData) {
    let newSubjects = [...data];
    newSubjects = newSubjects.concat(newData);
    setData(newSubjects);
  }

  function editSubjects(newData, index) {
    let newSubjects = [...data];
    newSubjects[index] = newData;
    setData(newSubjects);
  }

  function getColumns(mode) {
    const columns = [
      {
        id: 'name',
        align: 'center',
        label: labels.name,
        render: (row, index) => row.name,
      },
      {
        id: 'type',
        align: 'center',
        label: labels.type,
        render: (row, index) => row.type,
      },
      {
        id: 'level',
        align: 'center',
        label: labels.level,
        render: (row, index) => row.level,
      },
    ];

    return columns;
  }

  return (
    <TableFormAndDisplay
      columns={getColumns(mode)}
      rows={allData}
      title={intl.title}
      table={intl.table}
      mode={mode}
      useEdit={true}
      onDelete={deleteSubjects}
    >
      <AddSubjects subjects={allSubjects} addSubjects={addSubjects} editSubjects={editSubjects} disabled={disabled} />
    </TableFormAndDisplay>
  );
}

const SubjectForm = injectIntl(SubjectFormComponent);

export { SubjectForm };
