import { Paper } from '@mui/material';
import { Loader } from 'components';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { BigWorkshopForm } from 'scenes';
import { BigWorkshopActioner } from 'services/bigWorkshop';
import { WorkshopActioner } from 'services/workshop';

function BigWorkshopEditComponent(props) {
  const [, reload] = useState();
  const id = props.match.params.id;
  const intl = props.intl.messages.scenes.workshop.edit;

  function editForm(values, workshops, bigWorkshops) {
    return (
      <BigWorkshopForm
        bigWorkshops={bigWorkshops}
        workshops={workshops}
        values={values}
        reload={() => reload({})}
        mode={'edit'}
      />
    );
  }

  function loadInfos() {
    return BigWorkshopActioner.get(id)
      .then(infos => {
        const values = { ...infos, id };
        return values;
      })
      .catch(err => {
        throw err;
      });
  }

  function renderEditForm(render) {
    Promise.all([loadInfos(), WorkshopActioner.list(), BigWorkshopActioner.list()]).then(
      ([values, workshops, bigWorkshops]) => {
        render(editForm(values, workshops, bigWorkshops));
      },
    );
  }

  return (
    <Paper className="padding-small">
      <h2 className="text-centered">{intl.title}</h2>
      <Loader render={renderEditForm} />
    </Paper>
  );
}

const BigWorkshopEdit = injectIntl(BigWorkshopEditComponent);

export { BigWorkshopEdit };
