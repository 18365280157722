import { Check, Close, Delete } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { Table } from 'components/Table';
import { TextInput } from 'components/TextInput';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { AddReminders } from './AddReminder';

function ReminderFormComponent({ data, setData, setGeneralStatus, generalStatus, disabled, ...props }) {
  const intl = props.intl.messages.scenes.common.reminder;
  const labels = intl.labels;
  function setRemindersFields(index, field, value) {
    const newReminders = [...data];
    newReminders[index][field] = value;
    setData(newReminders);
    if (index === data.length - 1) {
      setGeneralStatus(value);
    }
  }

  function setCommentFields(index, field) {
    return value => {
      const newAvailabilities = [...data];
      newAvailabilities[index][field] = value;
      setData(newAvailabilities);
    };
  }

  function deleteReminders(index) {
    return () => {
      const newReminders = [...data];
      newReminders.splice(index, 1);
      setData(newReminders);
      setGeneralStatus(newReminders[newReminders.length - 1].status);
    };
  }

  function addReminders(newData) {
    let newReminders = [...data];
    newReminders = newReminders.concat(newData);
    setData(newReminders);
    setGeneralStatus(newData.status);
  }

  const columns = [
    {
      id: 'date',
      align: 'left',
      label: labels.date,
      render: (row, index) => <Typography variant="subtitle">{moment(row.date).format('DD/MM/YYYY')}</Typography>,
    },
    {
      id: 'date',
      align: 'left',
      label: labels.comment,
      render: (row, index) => (
        <TextInput
          name="comment"
          label={labels.comment}
          value={row.comment}
          setField={setCommentFields(index, 'comment')}
          disabled={row.status !== 'REJECTED'}
        />
      ),
    },
    {
      id: 'status',
      label: labels.status,
      align: 'center',
      render: (row, index) => (
        <div>
          <div className="divButton">
            <Button
              variant={row.status === 'ACCEPTED' ? 'contained' : row.status === 'REJECTED' ? 'outlined' : 'outlined'}
              color="primary"
              startIcon={<Check />}
              onClick={() => setRemindersFields(index, 'status', 'ACCEPTED')}
              disabled={generalStatus === 'HERE' || generalStatus === 'ABSENT'}
            >
              {intl.status.accepted}
            </Button>
            <Button
              variant={row.status === 'ACCEPTED' ? 'outlined' : row.status === 'REJECTED' ? 'contained' : 'outlined'}
              color="secondary"
              startIcon={<Close />}
              onClick={() => setRemindersFields(index, 'status', 'REJECTED')}
              disabled={generalStatus === 'HERE' || generalStatus === 'ABSENT'}
            >
              {intl.status.rejected}
            </Button>
          </div>
        </div>
      ),
    },
    {
      id: 'delete',
      label: '',
      align: 'right',
      render: (row, index) => (
        <div>
          {index === 0 ? null : (
            <ConfirmDialog
              onConfirm={deleteReminders(index)}
              button={({ onClick }) => (
                <IconButton onClick={onClick}>
                  <Delete color={disabled ? 'disabled' : 'error'} size="small" />
                </IconButton>
              )}
            ></ConfirmDialog>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="padding-small full-width marginB20">
      <Typography variant="h5" gutterBottom>
        {intl.title}
      </Typography>
      <Table columns={columns} rows={data} />
      {data[data.length - 1].status === 'REJECTED' ? (
        <AddReminders addReminders={addReminders} disabled={disabled} />
      ) : null}
    </div>
  );
}

const ReminderForm = injectIntl(ReminderFormComponent);

export { ReminderForm };
