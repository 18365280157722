import { AccountBox } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import moment from 'moment';
import { useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { injectIntl } from 'react-intl';
import { ComonUtils } from 'services/comon';
import { AvailabilitiesUtils } from 'tools/availabilities';
import '../Matching.css';

function ItemDragComponent(props) {
  const { key, entity_type, type, item, index, isShow, hasAvailabilities, isFilter, withFilter } = props;

  const openProfil = ComonUtils.openProfil;

  const common_ai = useMemo(
    () => AvailabilitiesUtils.sortAvailabilities(hasAvailabilities(item, withFilter)),
    [hasAvailabilities, item, withFilter],
  );
  const isSelected = useMemo(
    () => (type === 'selected' ? true : isShow(item, withFilter) && isFilter(item)),
    [isFilter, isShow, item, type, withFilter],
  );
  const isError = useMemo(() => type === 'selected' && !isShow(item, true), [isShow, item, type]);

  return (
    <Draggable key={key} draggableId={item._id} index={index}>
      {(provided, snapshot) => {
        return isSelected ? (
          <div
            className={snapshot.isDragging ? 'dragger dragging' : isError ? 'dragger itemError' : 'dragger'}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="draggerContent">
              <span className="draggerIcon">
                <IconButton size="medium" onClick={() => openProfil(entity_type, item._id)}>
                  <AccountBox color="primary" />
                </IconButton>
              </span>
              <span className="dragger-title">
                <h5>
                  {item.general_information.last_name} {item.general_information.first_name}
                </h5>
                {item.general_information.mobile && <h6>tel : {item.general_information.mobile}</h6>}
                {item.general_information.nationality && (
                  <h6>{item.general_information?.nationality?.toUpperCase()}</h6>
                )}
                {item.general_information.arrival_date && (
                  <h6>{moment(item.general_information.arrival_date).format('DD/MM/YYYY')}</h6>
                )}
              </span>
              <span className="dragger-content">
                {common_ai.map(c_ai => (
                  <h6 key={`${c_ai.day}-${c_ai.start_hour}-${c_ai.end_hour}`} className="dragger-ai">
                    {c_ai.day?.toUpperCase()}: {c_ai.start_hour}-{c_ai.end_hour}
                  </h6>
                ))}
              </span>
              <br />
            </div>
          </div>
        ) : (
          <div
            className="item-no-display"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          ></div>
        );
      }}
    </Draggable>
  );
}

const ItemDrag = injectIntl(ItemDragComponent);

export { ItemDrag };
