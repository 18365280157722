import { Typography } from '@mui/material';
import { TableFormAndDisplay } from 'components/TableFormAndDisplay';
import { injectIntl } from 'react-intl';
import { ArrayUtils } from 'tools';
import { AddAvailabilities } from './components/AddAvailabilities';

function AvailabilitiesComponent({ data, setData, disabled, mode, ...props }) {
  const intl = props.intl.messages.components.availabilities;
  const labels = intl.labels;

  function deleteAvailabilities(index) {
    return () => {
      const newAvailabilities = ArrayUtils.copyJsonObjectArray(data);
      newAvailabilities.splice(index, 1);
      setData(newAvailabilities);
    };
  }

  function addAvailabilities(newData) {
    let newAvailabilities = ArrayUtils.copyJsonObjectArray(data);
    newAvailabilities = newAvailabilities.concat(newData);
    setData(newAvailabilities);
  }

  function editAvailability(newData, index) {
    let newAvailabilities = ArrayUtils.copyJsonObjectArray(data);
    newAvailabilities[index] = newData;
    setData(newAvailabilities);
  }

  function getColumns() {
    const columns = [
      {
        id: 'day',
        align: 'center',
        label: labels.day,
        render: (row, index) => <Typography key={`${index}-day`}>{row.day}</Typography>,
      },
      {
        id: 'start_hour',
        align: 'center',
        label: labels.startHour,
        render: (row, index) => <Typography key={`${index}-start`}>{row.start_hour}</Typography>,
      },
      {
        id: 'end_hour',
        align: 'center',
        label: labels.endHour,
        render: (row, index) => <Typography key={`${index}-end`}>{row.end_hour}</Typography>,
      },
    ];

    return columns;
  }

  return (
    <TableFormAndDisplay
      columns={getColumns()}
      rows={data}
      title={intl.title}
      table={intl.table}
      mode={mode}
      useEdit={true}
      onDelete={deleteAvailabilities}
      disabled={disabled}
    >
      <AddAvailabilities addAvailabilities={addAvailabilities} editAvailability={editAvailability} />
    </TableFormAndDisplay>
  );
}

const Availabilities = injectIntl(AvailabilitiesComponent);

export { Availabilities };
