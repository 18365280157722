import { Container, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Selector } from 'components/Selector';
import { TextInput } from 'components/TextInput';
import { useConfigContext } from 'hooks';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { ValueUtils } from 'tools';
import { EntitiesListForm } from './components/EntitiesListForm';
import { PositionedWorkshopButtons } from './PositionedWorkshopButtons';

const vod = ValueUtils.valueOrDefault;

function getInitialValues(campaign, site, values = {}, participants) {
  return {
    workshop: vod(values.workshop, ''),
    title: vod(values.title, ''),
    positioned_date: vod(values.positioned_date, moment()),
    animator: vod(values.animator, ''),
    participants: vod(participants, []),
    old_participants: vod(participants, []),
    campaign,
    site,
    id: values.id,
  };
}

function PositionedWorkshopFormComponent(props) {
  const { reload, mode, values, templates, participants } = props;
  const { campaign, site } = useConfigContext();
  const initialValues = getInitialValues(campaign, site, values, participants);
  const [fields, setFields] = useState({
    ...initialValues,
    errors: {
      workshop: false,
      animator: false,
      positionedDate: false,
    },
    loading: false,
  });

  const intl = props.intl.messages.scenes.positionedWorkshop.form;

  function setFieldFunction(name) {
    return value => {
      setFields(f => ({ ...f, [name]: value }));
    };
  }

  function setFieldWithErrorFunction(name) {
    return value => {
      setFields(f => ({
        ...f,
        [name]: value,
        errors: { ...f.errors, [name]: f.errors[name] && value !== '' ? false : f.errors[name] },
      }));
    };
  }

  const id_bigworkshop = useMemo(() => {
    return templates.bigworkshops.filter(bw => bw.workshop.includes(fields.workshop))?.shift()?._id;
  }, [templates, fields.workshop]);

  const new_workshops = useMemo(() => {
    return templates.workshops.map(w => ({
      label: `[ ${
        templates?.bigworkshops
          ?.filter(bw => bw.workshop.includes(w._id))
          ?.shift()
          ?.name?.toUpperCase() ?? ''
      } ] ${w.name}`,
      value: w._id,
    }));
  }, [templates]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Selector
            labelId="workshop"
            label={intl.workshop}
            selected={fields.workshop}
            setSelected={setFieldWithErrorFunction('workshop')}
            items={new_workshops}
            error={fields.errors.workshop}
            disabled={fields.loading || fields.participants.length > 0}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextInput
            name="title"
            label={intl.title}
            value={fields.title}
            setField={setFieldWithErrorFunction('title')}
            disabled={fields.loading}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <DatePicker
            renderInput={props => <TextField fullWidth style={{ marginTop: '3%' }} {...props} />}
            margin="normal"
            id="positioned_date"
            label={intl.positionedDate}
            inputFormat="DD/MM/YYYY HH:mm"
            name="positioned_date"
            value={fields.positioned_date}
            onChange={setFieldWithErrorFunction('positioned_date')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            inputVariant="outlined"
            fullWidth
            ampm={false}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Selector
            labelId="animator"
            label={intl.animator}
            selected={fields.animator}
            setSelected={setFieldWithErrorFunction('animator')}
            items={templates.animators.map(a => ({ label: `${a.firstname} ${a.lastname}`, value: a._id }))}
            error={fields.errors.animator}
            disabled={fields.loading}
          />
        </Grid>

        <EntitiesListForm
          setData={setFieldFunction('participants')}
          templates={templates}
          idBigWorkshop={id_bigworkshop}
          data={fields.participants}
          mode="form"
          disabled={fields.loading}
        />
        <PositionedWorkshopButtons
          fields={fields}
          setFields={setFields}
          initialValues={initialValues}
          reload={reload}
          templates={templates}
          mode={mode}
        />
      </Grid>
    </Container>
  );
}

const PositionedWorkshopForm = injectIntl(PositionedWorkshopFormComponent);

export { PositionedWorkshopForm };
