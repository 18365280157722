import { paths } from 'routes';
import { AuthenticatedRoute } from 'routes/components';
import { MatchingCreate, MatchingEdit, MatchingList, MatchingProfil } from 'scenes';

const matchingRoutes = [
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.matching.home,
      exact: true,
      component: MatchingList,
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.matching.create,
      exact: true,
      component: MatchingCreate,
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.matching.edit,
      exact: true,
      component: MatchingEdit,
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.matching.profil,
      exact: true,
      component: MatchingProfil,
    },
  },
];

export { matchingRoutes };
