import { AccountBox } from '@mui/icons-material';
import { Box, Container, Grid, IconButton, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { CheckboxField } from 'components/CheckboxField';
import { Selector } from 'components/Selector';
import { TextInput } from 'components/TextInput';
import { useConfigContext } from 'hooks';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { InterviewEnums } from 'services/interview';
import { ValueUtils } from 'tools';
import { ArrayToSelector } from 'tools/arrayToSelector';
import { InterviewSubmitButton } from './components';

const vod = ValueUtils.valueOrDefault;

function getInitialValues(values = {}, id, type, campaign, userID, site, mode) {
  return {
    id: vod(values.id, ''),
    type: vod(values.type, ''),
    title: vod(values.title, ''),
    interviewed_id: vod(values.interviewed_id, id),
    interviewed_type: vod(values.interviewed_type, type),
    user: vod(values.user, userID),
    campaign: vod(values.campaign, campaign),
    site: vod(values.site, site),
    interviewed_classmate_id: vod(values.interviewed_classmate_id, null),
    interviewed_classmate_type: vod(values.interviewed_classmate_type, type === 'student' ? 'volunteer' : 'student'),
    school_subject: vod(values.school_subject, ''),
    school_subject_hours: vod(values.school_subject_hours, {}),
    stop_date: vod(values.stop_date, null),
    date: vod(values.date, null),
    theme: vod(values.theme, ''),
    volunteer_stop: vod(values.volunteer_stop, false),
    student_stop: vod(values.student_stop, false),
    lacle_stop: vod(values.lacle_stop, false),
    details: vod(values.details, ''),
    errors: {
      interviewed_id: false,
      interviewed_type: false,
    },
    loading: mode === 'view' ?? false,
  };
}

function InterviewFormComponent({ reload, mode, values, templates, type, interviewedId, ...props }) {
  const { campaign, site } = useConfigContext();
  const initialValues = getInitialValues(values, interviewedId, type, campaign, props.userID, site, mode);
  const [fields, setFields] = useState({
    ...initialValues,
  });

  function setClassmatesValues(type) {
    if (type === 'student') {
      return ArrayToSelector.getGeneralInformationNamesArray(templates.volunteers);
    } else {
      return ArrayToSelector.getGeneralInformationNamesArray(templates.students);
    }
  }
  function setInterviewedValues(type) {
    if (type === 'student') {
      return templates.students.find(element => element._id === fields.interviewed_id);
    } else {
      return templates.volunteers.find(element => element._id === fields.interviewed_id);
    }
  }
  const classmates = setClassmatesValues(fields.interviewed_type);
  const interviewed = setInterviewedValues(fields.interviewed_type);
  const users = ArrayToSelector.getNamesArray(templates.users);
  const campaigns = ArrayToSelector.getArray(templates.campaigns);
  const sites = ArrayToSelector.getArray(templates.sites);
  const intl = props.intl.messages.scenes.interview.form;
  const subjects = ArrayToSelector.getArray(templates.themes);
  const types = InterviewEnums.getTypes();
  const themes = InterviewEnums.getThemes();

  function setFieldFunction(name) {
    return value => {
      setFields(f => ({ ...f, [name]: value }));
    };
  }
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid container item xs={12} sm={12} className="padding-small">
          <Typography className="title" variant="h6">
            {intl.title.replace(
              ':name',
              `${interviewed?.general_information?.last_name}  ${interviewed?.general_information?.first_name} (${
                intl.labels[fields.interviewed_type]
              })`,
            )}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} className="padding-small">
          <Selector
            labelId="type"
            label={intl.labels.type}
            selected={fields.type}
            setSelected={setFieldFunction('type')}
            items={types}
            disabled={fields.loading}
          />
        </Grid>
        <Grid container item xs={12} sm={12} className="padding-small">
          <TextInput
            name="title"
            label={intl.labels.title}
            value={fields.title}
            setField={setFieldFunction('title')}
            disabled={fields.loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <DatePicker
            renderInput={props => <TextField fullWidth style={{ marginTop: '3%' }} {...props} />}
            margin="normal"
            id="date"
            label={intl.labels.date}
            inputFormat="DD/MM/YYYY"
            name="date"
            value={fields.date}
            onChange={setFieldFunction('date')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            inputVariant="outlined"
            fullWidth
            disabled={fields.loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={1}>
              {fields.interviewed_classmate_id !== null && (
                <IconButton
                  sx={{ paddingTop: '125%' }}
                  color="primary"
                  component={Link}
                  to={paths.front[fields.interviewed_classmate_type].edit.replace(
                    ':id',
                    fields.interviewed_classmate_id,
                  )}
                  target="_blank"
                >
                  <AccountBox />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={11}>
              <Selector
                labelId="interviewed_classmate_id"
                label={intl.labels.interviewed_classmate_id}
                selected={fields.interviewed_classmate_id ? fields.interviewed_classmate_id : ''}
                setSelected={setFieldFunction('interviewed_classmate_id')}
                items={classmates}
                disabled={fields.loading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Selector
            labelId="user"
            label={intl.labels.user}
            selected={fields.user}
            setSelected={setFieldFunction('user')}
            items={users}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Selector
            labelId="campaign"
            label={intl.labels.campaign}
            selected={fields.campaign}
            setSelected={setFieldFunction('campaign')}
            items={campaigns}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Selector
            labelId="site"
            label="Etablissement"
            selected={fields.site}
            setSelected={setFieldFunction('site')}
            items={sites}
            disabled={true}
          />
        </Grid>
        {fields.type === 'SOCIALE' && (
          <Grid item xs={12} sm={12} md={12}>
            <Selector
              labelId="theme"
              label={intl.labels.theme}
              selected={fields.theme}
              setSelected={setFieldFunction('theme')}
              items={themes}
              disabled={fields.loading}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12}>
          <Selector
            labelId="school_subject"
            label={intl.labels.school_subject}
            selected={fields.school_subject}
            setSelected={setFieldFunction('school_subject')}
            items={subjects}
            disabled={fields.loading}
          />
        </Grid>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-around"
          className="full-width marginB20"
          flexWrap="wrap"
        >
          <CheckboxField
            label={intl.labels.student_stop}
            checked={fields.student_stop ? fields.student_stop : false}
            setField={setFieldFunction('student_stop')}
            disabled={fields.loading}
          />
          <CheckboxField
            label={intl.labels.volunteer_stop}
            checked={fields.volunteer_stop ? fields.volunteer_stop : false}
            setField={setFieldFunction('volunteer_stop')}
            disabled={fields.loading}
          />
          <CheckboxField
            label={intl.labels.lacle_stop}
            checked={fields.lacle_stop ? fields.lacle_stop : false}
            setField={setFieldFunction('lacle_stop')}
            disabled={fields.loading}
          />
        </Box>

        {(fields.volunteer_stop || fields.student_stop || fields.lacle_stop) && (
          <Grid item xs={12} sm={12} md={12}>
            <DatePicker
              renderInput={props => <TextField fullWidth style={{ marginTop: '3%' }} {...props} />}
              margin="normal"
              id="stop_date"
              label={intl.labels.stop_date}
              inputFormat="DD/MM/YYYY"
              name="stop_date"
              value={fields.stop_date}
              onChange={setFieldFunction('stop_date')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              inputVariant="outlined"
              fullWidth
            />
          </Grid>
        )}

        <Grid container item xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={12} md={12}>
            <TextInput
              name="details"
              label={intl.labels.details}
              value={fields.details}
              setField={setFieldFunction('details')}
              disabled={fields.loading}
              multiline={true}
            />
          </Grid>
        </Grid>

        <InterviewSubmitButton
          fields={fields}
          setFields={setFields}
          initialValues={initialValues}
          reload={reload}
          mode={mode}
        />
      </Grid>
    </Container>
  );
}

const mapStateToProps = state => ({
  userID: state.Authentication.userID,
});

const InterviewForm = connect(mapStateToProps)(injectIntl(InterviewFormComponent));

export { InterviewForm };
