import { useLocalStorage } from 'hooks/useLocalStorage';
import { useEffect } from 'react';
import { useTableContext } from '.';

export const useTableState = tableName => {
  const [state, setState] = useLocalStorage(tableName, {});
  const tableRef = useTableContext();

  useEffect(() => {
    setState(tableRef?.current?.getState());
  }, [setState, tableRef]);

  const onStateChange = updater => {
    setState(old => (updater instanceof Function ? updater(old) : updater));
  };

  const onColumnFiltersChange = updater => {
    setState(old => ({
      ...old,
      columnFilters: updater instanceof Function ? updater(old?.columnFilters) : updater,
    }));
    tableRef.current.resetPagination();
  };

  const onSortingChange = updater => {
    setState(old => ({
      ...old,
      sorting: updater instanceof Function ? updater(old?.sorting) : updater,
    }));
    tableRef.current.resetPagination();
  };

  const onGlobalFilterChange = updater => {
    if (updater !== undefined || state.globalFilter !== undefined) {
      tableRef.current.resetPagination();
    }
    setState(old => ({
      ...old,
      globalFilter: updater instanceof Function ? updater(old?.globalFilter) : updater,
    }));
  };

  const onShowFiltersChange = value => {
    setState(old => ({ ...old, showColumnFilters: value }));
  };

  const onIsFullScreenChange = value => {
    setState(old => ({ ...old, isFullScreen: value }));
  };

  return {
    onStateChange,
    onShowFiltersChange,
    onSortingChange,
    onColumnFiltersChange,
    onGlobalFilterChange,
    onIsFullScreenChange,
    state,
  };
};
