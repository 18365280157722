const TimelineEnums = {
  getVolunteerState: () => {
    const TypeState = [
      {
        label: 'Présent',
        value: 'P',
      },
      {
        label: 'Justifié Professeur',
        value: 'JP',
      },
      {
        label: 'Non Justifié Professeur',
        value: 'NJP',
      },
    ];
    return TypeState;
  },
  getStudentsState: () => {
    const TypeState = [
      {
        label: 'Présent',
        value: 'P',
      },
      {
        label: 'Justifié Apprenant',
        value: 'JA',
      },
      {
        label: 'Non Justifié Apprenant',
        value: 'NJA',
      },
    ];
    return TypeState;
  },
  getCourseState: () => {
    const TypeState = [
      {
        label: 'En cours',
        value: 'IN_PROGRESS',
      },
      {
        label: 'Suspendu',
        value: 'SUSPENDED',
      },
      {
        label: 'Exceptionnel',
        value: 'UNIQUE',
      },
    ];
    return TypeState;
  },
  storeColors: () => {
    const colors = {
      default: {
        background: '#2196f3',
        border: '#1976d2',
      },
      validated: {
        background: '#4caf50',
        border: '#388e3c',
      },
      error: {
        background: '#ff9800',
        border: '#f57c00',
      },
      incomplete: {
        background: '#9c27b0',
        border: '#7b1fa2',
      },
      suspended: {
        background: '#424242',
        border: '#212121',
      },
      visio: {
        background: '#f06292',
        border: '#e91e63',
      },
    };
    return colors;
  },
  getTypes: () => {
    return [
      {
        label: 'Presentiel',
        value: 'LACLE',
      },
      {
        label: 'Visio-conference',
        value: 'HOME',
      },
    ];
  },
};

export { TimelineEnums };
