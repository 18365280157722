import { AccountBox } from '@mui/icons-material';
import { IconButton, Paper, Typography } from '@mui/material';
import { Table } from 'components/Table';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { paths } from 'routes';
import { StudentEnums } from 'services/student';
import { ArrayToSelector } from 'tools';

function FamilyLinkProfilComponent({ data, students, disabled, ...props }) {
  const intl = props.intl.messages.scenes.student.family_link;
  const labels = intl.labels;
  const types = StudentEnums.getTypeFamilyLink();
  const history = useHistory();

  const columns = [
    {
      id: 'type',
      align: 'center',
      label: labels.type,
      render: (row, index) => (
        <Typography key={`${index}-type`}>{types.find(element => element.value === row.type).label}</Typography>
      ),
    },
    {
      id: 'student',
      align: 'center',
      label: labels.student,
      render: (row, index) => (
        <Typography key={`${index}-student`}>
          {
            ArrayToSelector.getGeneralInformationNamesArray(students).find(element => element.value === row.student)
              .label
          }
        </Typography>
      ),
    },
    {
      id: 'item',
      align: 'center',
      label: labels.item,
      render: (row, index) => (
        <div>
          <IconButton
            size="small"
            onClick={() => {
              history.push(paths.front.student.profil.replace(':id', row.student));
              history.go();
            }}
          >
            <AccountBox color="primary" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Paper className="padding-small full-width marginB20 info">
      <Typography variant="h4" gutterBottom className="info-title">
        {intl.title}
      </Typography>
      <Table columns={columns} rows={data} />
    </Paper>
  );
}

const FamilyLinkProfil = injectIntl(FamilyLinkProfilComponent);

export { FamilyLinkProfil };
