import { Paper } from '@mui/material';
import { injectIntl } from 'react-intl';
import { SiteForm } from './SiteForm';

function SiteCreateComponent({ intl }) {
  return (
    <Paper className="padding-small">
      <h2 className="text-centered">{intl.messages.scenes.site.create.title}</h2>
      <SiteForm mode={'create'} />
    </Paper>
  );
}

const SiteCreate = injectIntl(SiteCreateComponent);

export { SiteCreate };
