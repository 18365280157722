import { AddCircle, Close } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { CustomSelector } from 'components/CustomSelector';
import { Selector } from 'components/Selector';
import { TextInput } from 'components/TextInput';
import { TimeInput } from 'components/TimeInput';
import moment from 'moment';
import { useContext, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { ComonEnums } from 'services/comon';
import { TimelineActioner, TimelineContext, TimelineEnums } from 'services/timeline';
import { AvailabilitiesUtils, ValueUtils } from 'tools';
import { ItemMatching } from './component';

const vod = ValueUtils.valueOrDefault;

function TimelineCreateComponent(props) {
  const { setOpen, matchings, item } = props;
  const context = useContext(TimelineContext);
  const types = TimelineEnums.getTypes();

  let weekDay = ComonEnums.getDays();
  weekDay.pop();

  const emptyTimeline = {
    matching: '',
    type: 'LACLE',
    start_time: vod(moment(item.click_time).format('HH:mm'), null),
    end_time: null,
    volunteer_state: '',
    student_states: [],
    courses_state: 'IN_PROGRESS',
    note: '',
    room: vod(item.group, ''),
    weeks: 1,
    date: vod(moment(item.click_time), null),
    day: vod(weekDay[moment(item.click_time).day() - 1]?.value, ''),
    duration: undefined,
    errors: {
      matching: false,
    },
  };
  const [fields, setFields] = useState(emptyTimeline);
  const [lock, setLock] = useState(false);

  const intl = props.intl.messages.scenes.timeline;
  const labels = intl.labels;
  const common_ai =
    fields.matching !== ''
      ? AvailabilitiesUtils.groupCommonAvailabilities([
          ...(matchings.find(m => m._id === fields.matching)?.students ?? []),
          matchings.find(m => m._id === fields.matching)?.volunteers?.[0] ?? [],
        ])
      : [];

  function setFieldFunction(name) {
    return value => {
      setFields(a => ({ ...a, [name]: value }));
    };
  }

  function setNewTime(target_time, store_time) {
    return moment(target_time).set({
      hour: store_time.split(':')[0],
      minute: store_time.split(':')[1],
    });
  }

  function setFieldMatchingFunction(value) {
    if (value === undefined || value === '') {
      setFields(emptyTimeline);
      return;
    }
    const matching = matchings.find(e => e._id === value);
    if (matching) {
      const new_volunteer_state = { id: matching.volunteers[0]?._id, state: '' };
      const new_student_states = matching.students?.map(e => {
        const ne = { id: e?._id, state: '' };
        return ne;
      });
      const duration = matching.duration;
      const end_time = moment(fields.start_time, 'HH mm').add(duration, 'minutes').format('HH:mm');
      setFields(a => ({
        ...a,
        matching: value,
        student_states: new_student_states,
        volunteer_state: new_volunteer_state,
        duration: duration,
        end_time: end_time,
      }));
    }
  }

  function addNewItem() {
    if (fields.matching === '') {
      setFields({ ...fields, errors: { ...fields.errors, matching: true } });
      return 0;
    }
    setLock(true);
    const newFields = { ...fields };
    newFields.expired_date = setNewTime(newFields.date, newFields.end_time).add(fields.weeks - 1, 'weeks');
    newFields.start_time = setNewTime(newFields.date, newFields.start_time);
    newFields.end_time = setNewTime(newFields.date, newFields.end_time);
    const promises = [];
    for (var i = 0; i < fields.weeks; i++) {
      const newItem = { ...newFields };
      newItem.start_time = moment(newFields.start_time).add(i, 'weeks');
      newItem.end_time = moment(newFields.end_time).add(i, 'weeks');
      promises.push(TimelineActioner.create(newItem));
    }
    Promise.all(promises).then(docs => {
      if (!docs.some(e => e === null)) {
        context.addItem(docs);
        setLock(false);
        setOpen(false);
      }
    });
  }

  const updatedMatchings = useMemo(() => matchings.map(m => Object.assign(m, { value: m._id })), [matchings]);

  return (
    <Grid justifyContent="space-between" alignItems="center" container>
      <Grid className="marginB20 timeline-header" justifyContent="space-evenly" container>
        <Typography className="marginB20 marginT20" component="span" variant="h6">
          {intl.create.toUpperCase()}
        </Typography>
      </Grid>
      <Grid container item xs={12} sm={12} className="padding-small marginB20" spacing={1}>
        <Grid item xs={6} sm={6}>
          <CustomSelector
            className="come-in-top select-matching"
            label={labels.matching}
            labelId="matching"
            items={updatedMatchings}
            itemRender={<ItemMatching />}
            resetIcon={<Close color="secondary" />}
            selected={fields.matching}
            setSelected={setFieldMatchingFunction}
            error={fields.errors.matching}
            disabled={lock}
          />
        </Grid>
        <Grid container item xs={6} sm={6}>
          <TextInput
            className="come-in-top"
            name="weeks"
            type="number"
            label={labels.weeks}
            value={fields.weeks}
            setField={setFieldFunction('weeks')}
            disabled={lock}
          />
          <Selector
            className="come-in-top"
            labelId="type"
            label={labels.type}
            selected={fields.type}
            setSelected={setFieldFunction('type')}
            items={types}
            disabled={lock}
          />
          <Grid>
            <Grid className="marginB20 come-in-bottom" justifyContent="space-evenly" container>
              <span>
                {fields.date ? `Commence le : ${moment(fields.date).format('dddd DD / MM / YYYY').toUpperCase()}` : ''}
              </span>
            </Grid>
            <Grid
              className="marginB20 come-in-bottom"
              container
              spacing={1}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={4} sm={4}>
                <TimeInput
                  id="start_time"
                  label={labels.start_time}
                  name="start_time"
                  inputFormat="HH:mm"
                  value={fields.start_time}
                  setFieldFunction={value => {
                    setFieldFunction('start_time')(value);
                    setFieldFunction('end_time')(
                      moment(value, 'HH:mm').add(fields.duration, 'minutes').format('HH:mm'),
                    );
                  }}
                  disabled={!fields.start_time}
                />
              </Grid>
              {fields.duration && (
                <Grid className="text-centered come-in-bottom" item xs={4} sm={4}>
                  <h6>Durée :</h6>
                  <h4>{fields.duration ? fields.duration : ''}</h4>
                  <h6>minutes</h6>
                </Grid>
              )}
              <Grid item xs={4} sm={4}>
                <TimeInput
                  id="end_time"
                  label={labels.end_time}
                  name="end_time"
                  inputFormat="HH:mm"
                  value={fields.end_time}
                  setFieldFunction={setFieldFunction('end_time')}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {fields.matching !== '' && (
        <Grid className="marginB20" justifyContent="space-evenly" container>
          {common_ai
            ?.map(c =>
              AvailabilitiesUtils.IsInclude(
                Object.assign({}, { day: fields.day, start_hour: fields.start_time, end_hour: fields.end_time }),
                c,
              ),
            )
            .some(c => c === true) ? (
            <h5> </h5>
          ) : (
            <h5 className="color-red come-in-top">
              ATTENTION : L'horaire sélectionné ne correspond pas aux disponibilités des participants
            </h5>
          )}
        </Grid>
      )}
      <Grid className="marginB20" justifyContent="space-evenly" container>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircle />}
          onClick={() => addNewItem()}
          disabled={lock}
        >
          {intl.buttons.add}
        </Button>
      </Grid>
    </Grid>
  );
}

const TimelineCreate = injectIntl(TimelineCreateComponent);

export { TimelineCreate };
