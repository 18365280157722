import { BigWorkshopApi } from 'services/bigWorkshop';
import { CampaignApi } from 'services/campaign';
import { StudentApi } from 'services/student';
import { UserApi } from 'services/user';
import { VolunteerApi } from 'services/volunteer';
import { WorkshopApi } from 'services/workshop';

const PositionedWorkshopUtils = {
  getPositionedWorkshopTemplates: async (campaign, site) => ({
    workshops: await WorkshopApi.getList(),
    bigworkshops: await BigWorkshopApi.getList(),
    animators: await UserApi.getUserList(),
    campaigns: await CampaignApi.getList(),
    students: await StudentApi.getList(campaign, site),
    volunteers: await VolunteerApi.getList(campaign, site),
  }),

  getInteressedEntities: (id, entities) => {
    return entities.filter(elem => elem?.workshop?.workshops?.some(i => i === id));
  },
};

export { PositionedWorkshopUtils };
