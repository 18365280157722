import {
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import MaUTable from '@mui/material/Table';
import moment from 'moment';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useRowState,
  useSortBy,
  useTable,
} from 'react-table';
import { TableToolBar } from './components';
import TablePaginationActions from './components/TablePaginationActions/TablePaginationActions';

import { ArrayUtils, ObjectUtils } from 'tools';
import { JsonParam, NumberParam, StringParam, withQueryParams } from 'use-query-params';
import './EnhancedTable.css';

require('moment-timezone');

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, row, handleShiftPress, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolveRef = ref || defaultRef;

  useEffect(() => {
    resolveRef.current.indeterminate = indeterminate;
  }, [resolveRef, indeterminate]);

  return (
    <>
      <Checkbox
        ref={resolveRef}
        {...rest}
        onClick={e => {
          if (row?.id !== undefined && e.shiftKey) {
            handleShiftPress(row);
          }
        }}
      />
    </>
  );
});

function getRowBackgroundColor(row) {
  if (
    Object.keys(
      R.pick(
        [
          'last_received_kpa_date',
          'last_kpa_received',
          'last_received_value_date',
          'last_data_received',
          'last_detection_date',
        ],
        row.original,
      ),
    ).length <= 0
  ) {
    return 'white';
  }
  if (row.original.status === 'RUNNING') {
    return '#d1e8be';
  } else {
    return 'rgb(255 168 0 / 67%)';
  }
}

const defaultRowPropGetter = row => {
  return {
    style: {
      background: getRowBackgroundColor(row),
    },
  };
};

const defaultCellPropGetter = cell => {
  const style = {};
  if (cell.column.threshold !== undefined) {
    const timezone = cell.row.original.contract.timezone;
    if (
      moment.tz(timezone).diff(moment.tz(cell.value, timezone), 'minutes') > cell.row.original[cell.column.threshold]
    ) {
      style.color = 'red';
    }
  }
  return { style };
};

function EnhancedTablecomponent({
  columns,
  data,
  title,
  actions,
  skipPageReset,
  getRowProps = defaultRowPropGetter,
  getCellProps = defaultCellPropGetter,
  useMultipleSelect = true,
  useSearch = true,
  useFooter = true,
  useReducer = true,
  useServerPagination = false,
  useHeader = true,
  initialState = { pageSize: 25 },
  reducer,
  query,
  setQuery,
  itemCount = undefined,
}) {
  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    getTableBodyProps,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter },
    rows,
  } = useTable(
    {
      columns,
      data,
      autoResetExpanded: !skipPageReset,
      autoResetGroupBy: !skipPageReset,
      autoResetSelectedRows: !skipPageReset,
      autoResetSortBy: !skipPageReset,
      autoResetFilters: !skipPageReset,
      autoResetRowState: !skipPageReset,
      autoResetGlobalFilter: !skipPageReset,
      autoResetHiddenColumns: !skipPageReset,
      initialState,
      manualPagination: useServerPagination,
      stateReducer: (newState, action, prevState) => {
        if (useReducer) {
          switch (action.type) {
            case 'init':
              if (query.pageSize !== undefined && query.pageIndex !== undefined) {
                if (query.pageIndex !== newState.pageIndex) {
                  newState.pageIndex = query.pageIndex;
                }
                if (query.pageSize !== newState.pageSize) {
                  newState.pageSize = query.pageSize;
                }
                if (query.globalFilter !== newState.globalFilter) {
                  newState.globalFilter = query.globalFilter;
                }
                if (
                  query.sortBy !== undefined &&
                  query.sortBy.length > 0 &&
                  !ArrayUtils.compareArrays(query.sortBy, newState.sortBy, ObjectUtils.compareSimpleObjects)
                ) {
                  newState.sortBy = query.sortBy;
                }
              }
              break;
            case 'resetPage':
              setQuery({
                pageIndex: 0,
                sortBy: newState.sortBy,
                globalFilter: newState.globalFilter,
                pageSize: newState.pageSize,
              });
              break;
            default:
              const querytoUpdate = {};
              if (action.pageIndex !== undefined && query.pageIndex !== action.pageIndex) {
                querytoUpdate.pageIndex = action.pageIndex;
              }
              if (action.pageSize !== undefined && query.pageSize !== action.pageSize) {
                querytoUpdate.pageSize = action.pageSize;
              }
              if (query.globalFilter !== newState.globalFilter) {
                querytoUpdate.globalFilter = newState.globalFilter;
              }
              if (!ArrayUtils.compareArrays(query.sortBy, newState.sortBy, ObjectUtils.compareSimpleObjects)) {
                if (newState.sortBy.length === 0) {
                  querytoUpdate.sortBy = undefined;
                } else {
                  querytoUpdate.sortBy = newState.sortBy;
                }
              }
              if (Object.keys(querytoUpdate).length > 0) {
                setQuery(querytoUpdate);
              }
              break;
          }
        }
        if (reducer !== undefined) {
          reducer(newState, action, prevState);
        }
        return newState;
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useRowState,
    hook => {
      if (useMultipleSelect) {
        hook.allColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  row={row}
                  handleShiftPress={handleShiftPress}
                  index={row.index}
                />
              </div>
            ),
            disableSortBy: true,
          },
          ...columns,
        ]);
      }
    },
  );

  useEffect(() => {
    headers.forEach(header => {
      if (header.socket !== undefined && header.socket.addListener !== undefined) {
        header.socket.addListener(page);
      }
    });
    return () => {
      headers.forEach(header => {
        if (header.socket !== undefined && header.socket.removeListener !== undefined) {
          header.socket.removeListener();
        }
      });
    };
  }, [headers, page]);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value));
  };

  const handleShiftPress = row => {
    if (R.countBy(v => v.isSelected)(page)['true'] === 1) {
      const previsouSelectedIndex = R.findIndex(R.propEq('isSelected', true), page);
      const nextSelectedIndex = R.findIndex(R.propEq('id', row.id), page);
      for (let i = previsouSelectedIndex + 1; i < nextSelectedIndex; ++i) {
        page[i].toggleRowSelected(true);
      }
    }
  };

  return (
    <TableContainer>
      {useHeader && (
        <TableToolBar
          title={title}
          selectedRowIds={selectedFlatRows}
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
          actions={actions}
          filters={headers.filter(h => h.Filter !== undefined)}
          useSearch={useSearch}
        />
      )}
      <MaUTable {...getTableProps()} size="small">
        <TableHead>
          <TableRow>
            {headers.map(column => (
              <TableCell
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={column.hidden && 'column-hidden'}
              >
                {column.render('Header')}
                {column.isSorted ? (
                  <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'} />
                ) : null}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.length === 0 ? (
            <TableRow>
              <TableCell align="center" colSpan={headers.length}>
                No Data
              </TableCell>
            </TableRow>
          ) : useServerPagination ? (
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell {...cell.getCellProps()} className={cell.column.hidden && 'column-hidden'}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell
                        {...cell.getCellProps(getCellProps(cell))}
                        className={cell.column.hidden && 'column-hidden'}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          )}
        </TableBody>
        {useFooter && (
          <TableFooter>
            <TableRow>
              <TablePagination
                key={data.length}
                rowsPerPageOptions={
                  useServerPagination ? [25, 50, 100, 150] : [25, 50, 100, 150, { label: 'All', value: data.length }]
                }
                colSpan={12}
                count={itemCount || rows.length}
                rowsPerPage={pageSize}
                page={pageIndex > 0 && pageSize > (itemCount || rows.length) ? gotoPage(0) : pageIndex}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </MaUTable>
    </TableContainer>
  );
}

const EnhancedTable = withQueryParams(
  { pageSize: NumberParam, pageIndex: NumberParam, globalFilter: StringParam, sortBy: JsonParam },
  EnhancedTablecomponent,
);

export { EnhancedTable };
