import { AddCircle, Delete, Edit } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { toast } from 'components';
import { ConfirmDialog } from 'components/ConfirmDialog';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { paths } from 'routes';
import { PositionedWorkshopActioner } from 'services/positionedWorkshop';

function PositionedWorkshopButtonComponent({ fields, setFields, mode, initialValues, templates, reload, ...props }) {
  const intl = props.intl.messages.scenes.submitButtons;
  const buttonIntl = props.intl.messages.scenes.positionedWorkshop.form.button;
  const toastMessages = props.intl.messages.toast;
  const id = initialValues.id;
  const history = useHistory();

  function setLoading(loading = true) {
    setFields(f => ({ ...f, loading }));
  }

  function setError(name) {
    setFields(f => ({ ...f, errors: { ...f.errors, [name]: true } }));
  }

  function onEdit() {
    const fieldsToSend = {};

    if (fields.workshop !== initialValues.workshop) {
      if (fields.workshop === '') {
        return setError('workshop');
      }
      fieldsToSend.workshop = fields.workshop;
    }

    if (fields.animator !== initialValues.animator) {
      if (fields.animator === '') {
        return setError('animator');
      }
      fieldsToSend.animator = fields.animator;
    }

    fieldsToSend.campaign = fields.campaign;
    fieldsToSend.positioned_date = fields.positioned_date;
    fieldsToSend.participants = fields.participants;
    fieldsToSend.old_participants = fields.old_participants;
    fieldsToSend.title = fields.title;

    if (Object.entries(fieldsToSend).length === 0) {
      return toast.info(toastMessages.info.noFieldChanged);
    }
    setLoading(true);

    PositionedWorkshopActioner.update(id, fieldsToSend)
      .then(() => {
        setLoading(false);
        reload();
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function onCreate() {
    if (fields.workshop === '') {
      return setError('workshop');
    }
    if (fields.animator === '') {
      return setError('animator');
    }

    fields.old_participants = [];

    setLoading(true);
    PositionedWorkshopActioner.create(fields)
      .then(data => {
        PositionedWorkshopActioner.update(data._id, fields).then(() => {
          setLoading(false);
          history.push(paths.front.workshop.positionedWorkshop.home);
        });
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function onDelete() {
    setLoading(true);
    PositionedWorkshopActioner.delete(id)
      .then(() => {
        setLoading(false);
        history.push(paths.front.workshop.positionedWorkshop.home);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <Grid justifyContent="space-evenly" container>
      <Button
        variant="contained"
        color="primary"
        startIcon={mode === 'edit' ? <Edit /> : <AddCircle />}
        onClick={mode === 'edit' ? onEdit : onCreate}
        disabled={fields.loading}
      >
        {intl.save}
      </Button>
      {mode === 'edit' && (
        <ConfirmDialog
          onConfirm={onDelete}
          button={({ onClick }) => (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Delete />}
              onClick={onClick}
              disabled={fields.loading}
            >
              {buttonIntl.title}
            </Button>
          )}
        >
          {buttonIntl.modal
            .replace(':date', moment(fields.positioned_date).format('DD/MM/YYYY HH:mm'))
            .replace(':nom', templates.workshops.find(w => w._id === fields.workshop)?.name)}
        </ConfirmDialog>
      )}
    </Grid>
  );
}

const PositionedWorkshopButtons = injectIntl(PositionedWorkshopButtonComponent);

export { PositionedWorkshopButtons };
