import moment from 'moment';
import * as R from 'ramda';
import { ComonEnums } from 'services/comon';

const ALL_DAYS = 'all days';

const AvailabilitiesUtils = {
  isValid: elem => {
    if (elem === undefined) {
      return false;
    }
    if (elem.day === undefined || elem.start_hour === undefined || elem.end_hour === undefined) {
      return false;
    }
    return true;
  },

  hasSomeValid: tab => {
    if (!Array.isArray(tab)) {
      return false;
    }
    return AvailabilitiesUtils.allDaysTransform(tab.filter(t => AvailabilitiesUtils.isValid(t))).length > 0;
  },

  getAllValid: tab => {
    if (!Array.isArray(tab)) {
      return [];
    }
    return AvailabilitiesUtils.allDaysTransform(tab.filter(t => AvailabilitiesUtils.isValid(t)));
  },

  hasSameDay: (e1, e2) => {
    if (!AvailabilitiesUtils.isValid(e1) || !AvailabilitiesUtils.isValid(e2)) {
      return false;
    }
    return e1.day === e2.day || e2.day === ALL_DAYS || e1.day === ALL_DAYS;
  },

  getRightTimeHHmm: elem => {
    return moment(elem, 'HH:mm');
  },

  timeIsBetween: (value, value_start, value_end) => {
    const start = AvailabilitiesUtils.getRightTimeHHmm(value_start);
    const end = AvailabilitiesUtils.getRightTimeHHmm(value_end);
    const elem = AvailabilitiesUtils.getRightTimeHHmm(value);

    if (elem?.isBetween(start, end, 'hours', '[]')) {
      if (elem?.isSame(start, 'hours')) {
        return elem?.isAfter(start, 'minutes') || elem?.isSame(start, 'minutes');
      }
      if (elem?.isSame(end, 'hours')) {
        return elem?.isBefore(end, 'minutes') || elem?.isSame(end, 'minutes');
      }
      return true;
    }
    return false;
  },

  diffTime: (time_one, time_two) => {
    return moment(time_one, 'HH:mm').diff(moment(time_two, 'HH:mm'), 'minutes');
  },

  commonTime: (ai_one, ai_two) => {
    if (!AvailabilitiesUtils.isValid(ai_one) || !AvailabilitiesUtils.isValid(ai_two)) {
      return undefined;
    }
    if (!AvailabilitiesUtils.hasSameDay(ai_one, ai_two)) {
      return undefined;
    }
    if (
      !AvailabilitiesUtils.timeIsBetween(ai_one.start_hour, ai_two.start_hour, ai_two.end_hour) &&
      !AvailabilitiesUtils.timeIsBetween(ai_one.end_hour, ai_two.start_hour, ai_two.end_hour) &&
      !AvailabilitiesUtils.timeIsBetween(ai_two.start_hour, ai_one.start_hour, ai_one.end_hour) &&
      !AvailabilitiesUtils.timeIsBetween(ai_two.end_hour, ai_one.start_hour, ai_one.end_hour)
    ) {
      return undefined;
    }
    const day = ai_one.day === ALL_DAYS ? (ai_two.day === ALL_DAYS ? ALL_DAYS : ai_two.day) : ai_one.day;
    // diff < 0 , 1 < 2
    const interval = { day: day };
    if (AvailabilitiesUtils.diffTime(ai_one.start_hour, ai_two.start_hour) <= 0) {
      interval.start_hour = ai_two.start_hour;
    } else {
      interval.start_hour = ai_one.start_hour;
    }
    if (AvailabilitiesUtils.diffTime(ai_one.end_hour, ai_two.end_hour) <= 0) {
      interval.end_hour = ai_one.end_hour;
    } else {
      interval.end_hour = ai_two.end_hour;
    }
    if (AvailabilitiesUtils.diffTime(interval.start_hour, interval.end_hour) === 0) {
      return undefined;
    }
    return interval;
  },

  commonAvailabilities: (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
      return [];
    }
    const all_common = [];
    arr1.forEach(ai_one => {
      arr2.forEach(ai_two => {
        const commonTime = AvailabilitiesUtils.commonTime(ai_one, ai_two);
        if (commonTime !== undefined) {
          all_common.push(commonTime);
        }
      });
    });
    return all_common;
  },

  excludeTime: (ai_initial, ai_excluded) => {
    if (!AvailabilitiesUtils.isValid(ai_initial) || !AvailabilitiesUtils.isValid(ai_excluded)) {
      return [];
    }
    const common_ai = AvailabilitiesUtils.commonTime(ai_initial, ai_excluded);
    if (common_ai === undefined) {
      return ai_initial;
    }
    const new_ai = [];
    const before = {
      day: ai_initial.day,
      start_hour: ai_initial.start_hour,
      end_hour: common_ai.start_hour,
    };
    const after = {
      day: ai_initial.day,
      start_hour: common_ai.end_hour,
      end_hour: ai_initial.end_hour,
    };
    if (AvailabilitiesUtils.diffTime(before.start_hour, before.end_hour) !== 0) {
      new_ai.push(before);
    }
    if (AvailabilitiesUtils.diffTime(after.start_hour, after.end_hour) !== 0) {
      new_ai.push(after);
    }
    return new_ai;
  },

  excludeAvailabilities: (arr_init = [], arr_excluded = []) => {
    const ai_modified = [];
    if (AvailabilitiesUtils.commonAvailabilities(arr_init, arr_excluded).length === 0) {
      return arr_init;
    }
    arr_init.forEach(ai_init => {
      let modifiedTime = { ...ai_init };
      arr_excluded.forEach(ai_ex => {
        if (Array.isArray(modifiedTime)) {
          modifiedTime = R.flatten(modifiedTime.map(m => AvailabilitiesUtils.excludeTime(m, ai_ex)));
          return;
        }
        modifiedTime = AvailabilitiesUtils.excludeTime(modifiedTime, ai_ex);
      });
      ai_modified.push(modifiedTime);
    });
    return R.flatten(ai_modified);
  },

  sortAvailabilities: arr => {
    const days = ComonEnums.getDays();
    days.pop();
    const new_arr = arr.map(a => Object.assign({ ...a }, { place: days.findIndex(elem => a.day === elem.value) }));
    const sort_ai = R.sortBy(R.prop('place'));
    return sort_ai(new_arr);
  },

  allDaysTransform: arr => {
    const days = ComonEnums.getDays();
    days.pop();
    const new_arr = arr.map(a =>
      a.day === ALL_DAYS
        ? days.map(d => Object.assign({}, { day: d.value, start_hour: a.start_hour, end_hour: a.end_hour }))
        : a,
    );
    return R.flatten(new_arr);
  },

  IsEqual: (ai_one, ai_two) => {
    if (!AvailabilitiesUtils.isValid(ai_one) || !AvailabilitiesUtils.isValid(ai_two)) {
      return false;
    }
    return ai_one.day === ai_two.day && ai_one.start_hour === ai_two.start_hour && ai_one.end_hour === ai_two.end_hour;
  },

  IsInclude: (ai_one, ai_two) => {
    if (!AvailabilitiesUtils.isValid(ai_one) || !AvailabilitiesUtils.isValid(ai_two)) {
      return false;
    }
    const commonTime = AvailabilitiesUtils.commonTime(ai_one, ai_two);
    return AvailabilitiesUtils.IsEqual(commonTime, ai_one);
  },

  groupCommonAvailabilities: arr => {
    if (!Array.isArray(arr)) {
      return [];
    }
    arr = arr.filter(a => AvailabilitiesUtils.hasSomeValid(a.availabilities_information));
    if (arr.length === 0) {
      return [];
    }
    let common = AvailabilitiesUtils.getAllValid(arr.shift().availabilities_information);
    arr.forEach(a => {
      common = AvailabilitiesUtils.commonAvailabilities(
        common,
        AvailabilitiesUtils.getAllValid(a.availabilities_information),
      );
    });
    return common;
  },
};

export { AvailabilitiesUtils };
