import { Box, TextField } from '@mui/material';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { GroupListDrag, ItemDrag, ListDrag } from './';

function SelectDragComponent(props) {
  const { entity_type, fields, setFields, title, isShow, hasAvailabilities, disabled, errors, withFilter } = props;
  const [filter, setFilter] = useState('');

  function filterName(item) {
    return (
      item.general_information.first_name.toUpperCase().includes(filter.toUpperCase()) ||
      item.general_information.last_name.toUpperCase().includes(filter.toUpperCase())
    );
  }

  return (
    <Box
      className={`selectDrag full-width ${disabled ? 'disabled' : ''}`}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
    >
      <h2 className="text-centered">{title.toUpperCase()}</h2>
      <Box
        className="padding-small marginB20 group-list full-width"
        display="flex"
        justifyContent="space-around"
        flexWrap="wrap"
      >
        <GroupListDrag key={entity_type} tabs={fields} setTabs={setFields} disabled={disabled}>
          <Box className="width30" display="flex" flexDirection="column" justifyContent="end">
            <ListDrag idkey={`items1_${title}`} items={fields.selected} type={'selected'} error={errors.selected}>
              <ItemDrag
                entity_type={entity_type}
                isShow={isShow}
                isFilter={filterName}
                hasAvailabilities={hasAvailabilities}
                withFilter={withFilter}
              />
            </ListDrag>
          </Box>
          <Box className="width60" display="flex" flexDirection="column" justifyContent="end">
            <div className="padding-small-side">
              <TextField
                name="filtre"
                label="Filtre"
                variant="outlined"
                value={filter}
                fullWidth
                onChange={e => setFilter(e.target.value)}
              />
            </div>
            <ListDrag className="item-dropper" idkey={`items2_${title}`} items={fields.items} type={'items'}>
              <ItemDrag
                entity_type={entity_type}
                isShow={isShow}
                isFilter={filterName}
                hasAvailabilities={hasAvailabilities}
                withFilter={withFilter}
              />
            </ListDrag>
          </Box>
        </GroupListDrag>
      </Box>
    </Box>
  );
}

const SelectDrag = injectIntl(SelectDragComponent);

export { SelectDrag };
