import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import Timeline, { DateHeader, TimelineHeaders } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import { injectIntl } from 'react-intl';
import '../Timeline.css';
import { TimelineItem } from './TimelineItem';

function TimelineTableComponent(props) {
  const { groups, items, filter, setMode, setOpen, setItem, setFilter } = props;

  const updatedItems = useMemo(
    () =>
      items.map(e => {
        return Object.assign(e, {
          id: e._id,
          group: e.room._id,
          start_time: moment(e.start_time),
          end_time: moment(e.end_time),
        });
      }),
    [items],
  );
  const updatedGroups = useMemo(
    () =>
      groups.map(elem => {
        return Object.assign(
          {},
          {
            id: elem._id,
            title: elem.name,
            status: elem.status,
            stackItems: true,
          },
        );
      }),
    [groups],
  );
  const [zoom] = useState(10800000);
  const [startTime, setStartTime] = useState(moment().subtract(zoom, 'ms'));
  const [endTime, setEndTime] = useState(moment().add(zoom, 'ms'));

  useEffect(() => {
    if (filter !== undefined) {
      setStartTime(moment(filter).subtract(zoom, 'ms'));
      setEndTime(moment(filter).add(zoom, 'ms'));
    }
  }, [filter, zoom]);

  const onItemMove = (itemId, dragTime, newGroupOrder) => {
    const newGroup = groups[newGroupOrder];
    const newItem = items.find(e => e._id === itemId);
    if (newItem) {
      if (newGroup._id !== newItem.room) {
        newItem.room = newGroup._id;
        newItem.group = newGroup._id;
      }
      if (dragTime !== newItem.start_time) {
        var duration = moment(newItem.end_time).diff(newItem.start_time);
        newItem.start_time = moment(dragTime);
        newItem.end_time = moment(dragTime).add(duration, 'ms');
      }
      setItem(newItem);
      setMode('move');
      setOpen(true);
    }
  };

  const onItemDoubleClick = (itemId, e, time) => {
    const newItem = items?.find(e => e._id === itemId);
    if (newItem) {
      setItem(newItem);
      setMode('edit');
      setOpen(true);
    }
  };

  const onCanvasDoubleClick = (groupId, time) => {
    const newItem = {
      group: groupId,
      click_time: time,
    };
    setItem(newItem);
    setMode('create');
    setOpen(true);
  };

  function onTimeChange(start_time, end_time) {
    setStartTime(moment(start_time));
    setEndTime(moment(end_time));
    const middle_time = moment(end_time).diff(moment(start_time)) / 2;
    setFilter(moment(start_time).add(middle_time, 'ms'));
  }

  return (
    <div className="calendar-div">
      <Timeline
        groups={updatedGroups}
        items={updatedItems}
        defaultTimeStart={startTime}
        defaultTimeEnd={endTime}
        visibleTimeStart={startTime.valueOf()}
        visibleTimeEnd={endTime.valueOf()}
        onItemDoubleClick={onItemDoubleClick}
        onItemMove={onItemMove}
        itemRenderer={TimelineItem}
        onCanvasDoubleClick={onCanvasDoubleClick}
        canMove={true}
        canChangeGroup={true}
        lineHeight={60}
        onTimeChange={(visibleTimeStart, visibleTimeEnd) => {
          onTimeChange(visibleTimeStart, visibleTimeEnd);
        }}
        onZoom={timelineContext => {
          const newZoom = moment(timelineContext.canvasTimeEnd).diff(moment(timelineContext.canvasTimeStart)) / 6;
          const ratio = zoom / newZoom;
          if (ratio > 1.084 || ratio < 0.92) {
            // setZoom(newZoom);
          }
        }}
      >
        <TimelineHeaders className="sticky">
          <DateHeader key="top" unit="primaryHeader" />
          <DateHeader key="bot" labelFormat="HH:mm" />
        </TimelineHeaders>
      </Timeline>
    </div>
  );
}

const TimelineTable = injectIntl(TimelineTableComponent);

export { TimelineTable };
