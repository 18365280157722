import { AccountBox } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { Loader } from 'components';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { ComonUtils } from 'services/comon';
import { SubjectUtils } from 'services/subject';
import { VolunteerActioner } from 'services/volunteer';
import './style/VolunteerForm.css';
import { VolunteerForm } from './VolunteerForm';

function VolunteerEditComponent(props) {
  const [, reload] = useState();
  const id = props.match.params.id;
  const intl = props.intl.messages.scenes.volunteer.edit;

  function editForm(values, templates, subjects) {
    return (
      <VolunteerForm
        values={values}
        templates={templates}
        subjects={subjects}
        reload={() => reload({})}
        mode={'edit'}
      />
    );
  }

  function loadInfos() {
    return VolunteerActioner.get(id)
      .then(infos => {
        const values = { ...infos, id };
        return values;
      })
      .catch(err => {
        throw err;
      });
  }

  function renderEditForm(render) {
    Promise.all([loadInfos(), ComonUtils.getComonTemplates(), SubjectUtils.getSubjectTemplates()]).then(
      ([values, templates, subjects]) => {
        render(editForm(values, templates, subjects));
      },
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="full-width">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        className="full-width strongTitle"
      >
        <h2>{intl.title}</h2>
        <IconButton size="medium" component={Link} to={paths.front.volunteer.profil.replace(':id', id)}>
          <AccountBox color="primary" />
        </IconButton>
      </Box>
      <Loader render={renderEditForm} />
    </Box>
  );
}

const VolunteerEdit = injectIntl(VolunteerEditComponent);

export { VolunteerEdit };
