import { Delete, Edit, SaveAs } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { Availabilities } from 'components/ComonForm';
import { ProfilSection } from 'components/ComonProfil';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { useConfigContext } from 'hooks';
import html2pdf from 'html2pdf.js';
import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { paths } from 'routes';
import { ComonEnums } from 'services/comon';
import { StudentActioner } from 'services/student';
import { ThemeSubjectActioner } from 'services/themeSubject';
import { FamilyLinkForm } from './components';

function StudentProfilComponent(props) {
  const [fields, setFields] = useState();
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState();
  const [themes, setThemes] = useState();
  const { id } = props;
  const intl = props.intl.messages.scenes;
  const history = useHistory();
  const { campaign, site } = useConfigContext();
  const enums = {
    type: ComonEnums.getEmailTypes(),
    sexe: ComonEnums.getGenderArray(),
  };

  useEffect(() => {
    if (loading) {
      Promise.all([StudentActioner.get(id), StudentActioner.list(campaign, site), ThemeSubjectActioner.list()]).then(
        ([docs, list, themes]) => {
          setFields(docs);
          setStudents(list);
          setThemes(themes);
          setLoading(false);
        },
      );
    }
    return () => {};
  }, [loading, id, campaign, site]);

  function getThemes(list) {
    return list?.map(item => themes.find(t => t._id === item)?.name);
  }

  function onEdit() {
    history.push(paths.front.student.edit.replace(':id', id));
  }

  function onDelete() {
    setLoading(true);
    StudentActioner.delete(id)
      .then(() => {
        setLoading(false);
        history.push(paths.front.student.home);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const generatePdf = () => {
    const element = document.getElementById('student-profil');
    html2pdf(element);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      {loading ? (
        <Grid container item spacing={0} direction="column" alignItems="center" justifyContent="center">
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="width80">
          <h1>
            {intl.student.profil.title.toUpperCase()}{' '}
            <IconButton onClick={generatePdf}>
              <SaveAs />
            </IconButton>
          </h1>
          <Grid container item spacing={1} xs={12} sm={12} id="student-profil">
            <Grid item xs={12} sm={6}>
              <Box
                className="section full-width"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography className="title" variant="h6">
                  {fields.type}
                </Typography>
                <div className="info-img"></div>
                <div className="info-resume">
                  <Typography className="title" variant="h4">
                    {fields.general_information.last_name} {fields.general_information.first_name}
                  </Typography>
                  <Typography className="subtitle" variant="h4">
                    {fields.general_information.email?.email}
                    <br />
                    {fields.general_information.mobile}
                    <br />
                    <br />
                    {fields.other_intervention ? fields.other_intervention : ''}
                    <br />
                    <br />
                    {fields.general_information.address ? (
                      <div>
                        {fields.general_information.address.address_description}
                        <br />
                        {fields.general_information.address.city}
                        {'  '}
                        {fields.general_information.address.zip_code}
                      </div>
                    ) : null}
                  </Typography>
                  <div className="info-buttons">
                    <Button variant="contained" color="primary" startIcon={<Edit />} onClick={onEdit}>
                      {intl.submitButtons.edit}
                    </Button>{' '}
                    <ConfirmDialog
                      onConfirm={onDelete}
                      button={({ onClick }) => (
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<Delete />}
                          onClick={onClick}
                          disabled={fields.loading}
                        >
                          {intl.submitButtons.delete}
                        </Button>
                      )}
                    />
                  </div>
                </div>
              </Box>
              <FamilyLinkForm data={fields.family_links} students={students} disabled={fields.loading} />
              <ProfilSection
                items={Object.assign({}, { comment: fields.comment })}
                thisIntl={Object.assign({}, { labels: { comment: intl.student.comment } })}
              />
              <ProfilSection items={fields.level} thisIntl={intl.student.level} />
              <ProfilSection items={fields.family_situation} thisIntl={intl.student.family_situation} />
              <ProfilSection items={fields.registration_information} thisIntl={intl.common.registration_information} />
              <ProfilSection items={fields.life_state} thisIntl={intl.student.life_state} />
              <ProfilSection items={fields.family_ressources} thisIntl={intl.common.family_ressources} />
              <ProfilSection items={fields.social_mediation} thisIntl={intl.student.social_mediation} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Availabilities data={fields.availabilities_information} disabled={fields.loading} />
              {fields.type === 'AS' && <ProfilSection items={fields.school} thisIntl={intl.student.school} />}
              <ProfilSection items={getThemes(fields.wanted_subject)} thisIntl={intl.student.wanted_subject} />
              <ProfilSection items={fields.digital} thisIntl={intl.common.digital} />
              <ProfilSection
                items={fields.general_information}
                thisIntl={intl.common.general_information}
                enums={enums}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

const StudentProfil = injectIntl(StudentProfilComponent);

export { StudentProfil };
