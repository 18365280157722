import { paths } from 'routes';
import { AuthenticatedRoute } from 'routes/components';
import { StatsTimeline } from 'scenes';

const statsRoutes = [
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.stats.timeline,
      exact: true,
      component: StatsTimeline,
    },
  },
];

export { statsRoutes };
