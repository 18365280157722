import { Grid, Paper, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Selector } from 'components/Selector';
import { TextInput } from 'components/TextInput';
import { injectIntl } from 'react-intl';
import { ComonEnums } from 'services/comon';
import { ArrayUtils, ValueUtils } from 'tools';
import { AddressForm, EmailForm } from './components';

const vod = ValueUtils.valueOrDefault;

function GeneralFormComponent(props) {
  const { data, setData, mode, type, disabled } = props;
  const intl = props.intl.messages.scenes.common.general_information;
  const fields = {
    first_name: vod(data.first_name, ''),
    last_name: vod(data.last_name, ''),
    maiden_name: vod(data.maiden_name, ''),
    birth_date: vod(data.birth_date, null),
    birth_place: vod(data.birth_place, ''),
    origin: vod(data.origin, ''),
    nationality: vod(data.nationality, ''),
    native_language: vod(data.native_language, ''),
    arrival_date: vod(data.arrival_date, null),
    mobile: vod(data.mobile, ''),
    dad_mobile: vod(data.dad_mobile, ''),
    mum_mobile: vod(data.mum_mobile, ''),
    redList: vod(data.redList, false),
    address: vod(data.address, {}),
    email: vod(data.email, ''),
    other_email: vod(data.other_email, ''),
    sexe: vod(data.sexe, ''),
  };

  const genders = ComonEnums.getGenderArray();

  function setFieldFunction(field) {
    return value => {
      const newGeneralInformation = ArrayUtils.copyJsonObjectArray(fields);
      newGeneralInformation[field] = value;
      setData(newGeneralInformation);
    };
  }

  return (
    <Paper className="full-width marginB20 padding-small">
      <Typography variant="h5" gutterBottom>
        {intl.title}
      </Typography>
      <Grid container item xs={12} sm={12}>
        <Grid item xs={12} sm={12}>
          <TextInput
            name="last_name"
            label={intl.labels.last_name}
            value={fields.last_name}
            setField={setFieldFunction('last_name')}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            name="first_name"
            label={intl.labels.first_name}
            value={fields.first_name}
            setField={setFieldFunction('first_name')}
            disabled={disabled}
            required={false}
          />
        </Grid>
        {mode === 'student' && (
          <Grid item xs={12} sm={12}>
            <TextInput
              name="maiden_name"
              label={intl.labels.maiden_name}
              value={fields.maiden_name}
              setField={setFieldFunction('maiden_name')}
              disabled={disabled}
              required={false}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Selector
            labelId="sexe"
            label={intl.labels.sexe}
            selected={fields.sexe}
            setSelected={setFieldFunction('sexe')}
            items={genders}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DatePicker
            renderInput={props => <TextField fullWidth style={{ marginTop: '3%' }} {...props} />}
            margin="normal"
            id="birth_date"
            label={intl.labels.birth_date}
            inputFormat="DD/MM/YYYY"
            name="birth_date"
            value={fields.birth_date}
            onChange={setFieldFunction('birth_date')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            inputVariant="outlined"
            fullWidth
          />
        </Grid>
        {mode === 'student' && (
          <Grid item xs={12} sm={12}>
            <TextInput
              name="birth_place"
              label={intl.labels.birth_place}
              value={fields.birth_place}
              setField={setFieldFunction('birth_place')}
              disabled={disabled}
              required={false}
            />
          </Grid>
        )}
        {mode === 'student' && (
          <Grid item xs={12} sm={12}>
            <TextInput
              name="origin"
              label={intl.labels.origin}
              value={fields.origin}
              setField={setFieldFunction('origin')}
              disabled={disabled}
              required={false}
            />
          </Grid>
        )}
        {mode === 'student' && (
          <Grid item xs={12} sm={12}>
            <TextInput
              name="nationality"
              label={intl.labels.nationality}
              value={fields.nationality}
              setField={setFieldFunction('nationality')}
              disabled={disabled}
              required={false}
            />
          </Grid>
        )}
        {mode === 'student' && (
          <Grid item xs={12} sm={12}>
            <TextInput
              name="native_language"
              label={intl.labels.native_language}
              value={fields.native_language}
              setField={setFieldFunction('native_language')}
              disabled={disabled}
              required={false}
            />
          </Grid>
        )}
        {mode === 'student' && (
          <Grid item xs={12} sm={12}>
            <DatePicker
              renderInput={props => <TextField fullWidth style={{ marginTop: '3%' }} {...props} />}
              margin="normal"
              id="arrival_date"
              label={intl.labels.arrival_date}
              inputFormat="DD/MM/YYYY"
              name="arrival_date"
              value={fields.arrival_date}
              onChange={setFieldFunction('arrival_date')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              inputVariant="outlined"
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <TextInput
            name="mobile"
            label={intl.labels.mobile}
            value={fields.mobile}
            setField={setFieldFunction('mobile')}
            disabled={disabled}
            required={false}
          />
        </Grid>
        {type === 'AS' && (
          <Grid item xs={12} sm={12}>
            <TextInput
              name="dad_mobile"
              label={intl.labels.dad_mobile}
              value={fields.dad_mobile}
              setField={setFieldFunction('dad_mobile')}
              disabled={disabled}
              required={false}
            />
          </Grid>
        )}
        {type === 'AS' && (
          <Grid item xs={12} sm={12}>
            <TextInput
              name="mum_mobile"
              label={intl.labels.mum_mobile}
              value={fields.mum_mobile}
              setField={setFieldFunction('mum_mobile')}
              disabled={disabled}
              required={false}
            />
          </Grid>
        )}
        <EmailForm data={fields.email} setData={setFieldFunction('email')} disabled={disabled} />
        <EmailForm data={fields.other_email} setData={setFieldFunction('other_email')} disabled={disabled} />
        <AddressForm data={fields.address} setData={setFieldFunction('address')} disabled={disabled} />
      </Grid>
    </Paper>
  );
}

const GeneralForm = injectIntl(GeneralFormComponent);

export { GeneralForm };
