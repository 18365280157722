import { BarChart } from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton, Paper, useTheme } from '@mui/material';
import { DateInput } from 'components/DateInput';
import { useConfigContext } from 'hooks';
import moment from 'moment';
import 'moment/locale/fr';
import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { TimelineActioner, TimelineContext } from 'services/timeline';
import { tokens } from 'themes';
import './Timeline.css';
import { TimelineDialog, TimelineTable } from './component';

moment.locale('fr');

const empty_item = {
  id: '',
  group: '',
  title: '',
  start_time: null,
  end_time: null,
};

function TimelineComponent(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filter, setFilter] = useState(moment());
  const [initDate, setInitDate] = useState(moment());
  const [rooms, setRooms] = useState([]);
  const [items, setItems] = useState([]);
  const [mode, setMode] = useState('');
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(empty_item);
  const [openForm, setOpenForm] = useState(false);
  const { campaign, site, config } = useConfigContext();

  const intl = props.intl.messages.scenes.timeline;

  function setFilterValue(value) {
    setFilter(value);
  }

  function getDialogBox(mode) {
    return <TimelineDialog item={item} mode={mode} openForm={openForm} setOpenForm={setOpenForm} />;
  }

  function editItem(newItem) {
    const newItems = [...items];
    const index = items.findIndex(e => e._id === newItem._id);
    newItems[index] = newItem;
    setItems(newItems);
  }

  function addItem(item) {
    const newItems = [...items];
    item.forEach(element => {
      newItems.push(element);
    });
    setItems(newItems);
  }

  function deleteItem(id) {
    const newItems = [...items];
    const index = newItems.findIndex(e => e._id === id);
    if (index !== -1) {
      newItems.splice(index, 1);
      setItems(newItems);
    }
  }

  useEffect(() => {
    const startDate = moment(initDate).clone().subtract(7, 'days');
    const endDate = moment(initDate).clone().add(7, 'days');
    if (!moment(filter).isBetween(startDate, endDate)) {
      setLoading(true);
      // TimelineActioner.list(moment(filter).toISOString()).then(items => {
      //   setItems(items);
      //   setInitDate(moment(filter));
      // });
    }
    // if (filter > endDate) {
    //   TimelineActioner.list(endDate.toISOString()).then(items => {
    //     setItems(items);
    //     setInitDate(endDate);
    //   });
    // }
    // if (filter < startDate) {
    //   TimelineActioner.list(startDate.toISOString()).then(items => {
    //     setItems(items);
    //     setInitDate(startDate);
    //   });
    // }
  }, [filter, initDate]);

  useEffect(() => {
    setLoading(true);
  }, [config]);

  useEffect(() => {
    if (loading) {
      Promise.all([
        TimelineActioner.getRooms(site),
        TimelineActioner.list(moment(filter).toISOString(), campaign, site),
      ]).then(([rooms, items]) => {
        setRooms(rooms);
        setItems(items);
        setLoading(false);
        setInitDate(moment(filter).toISOString());
      });
    }
    return () => {};
  }, [filter, loading, campaign, site]);

  const context = {
    room_30: rooms?.find(r => r.name === 'Salle 30')?._id,
    editItem: editItem,
    addItem: addItem,
    deleteItem: deleteItem,
  };

  return (
    <>
      {loading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="height-circular"
        >
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <div>
          <Paper className="baner" sx={{ backgroundColor: colors.orangeAccent[600] }}>
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <h1>{moment(filter).format('DD MMMM YYYY, HH:mm').toUpperCase()}</h1>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  sx={{ color: colors.primary[100] }}
                  size="large"
                  component={Link}
                  to={paths.front.timeline.stats}
                >
                  <BarChart />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
          <div>
            <DateInput id="filter" label="Filtre" name="filter" value={filter} setFieldFunction={setFilterValue} />
          </div>
          <Button
            sx={{ marginTop: '14px' }}
            variant="contained"
            color="primary"
            onClick={() => setFilterValue(moment())}
          >
            {intl.reinit}
          </Button>
          <TimelineTable
            items={items}
            groups={rooms}
            filter={filter}
            setFilter={setFilter}
            setMode={setMode}
            setOpen={setOpenForm}
            setItem={setItem}
          />
          <TimelineContext.Provider value={context}>{openForm && getDialogBox(mode)}</TimelineContext.Provider>
        </div>
      )}
    </>
  );
}

const Timeline = injectIntl(TimelineComponent);

export { Timeline };
