import { ThemeSubjectActioner } from 'services/themeSubject';
import { SubjectActioner } from './actioner';

const SubjectUtils = {
  getSubjectTemplates: async () => {
    return {
      themeSubjects: await ThemeSubjectActioner.list(),
      subjects: await SubjectActioner.list(),
    };
  },
  getBuitifullData: (data, subjects, themeSubjects) => {
    return data.map(s => {
      let newS = {};
      newS = { ...subjects.find(ns => ns._id === s) };
      if (newS.name) {
        const newName = { ...themeSubjects.find(t => t._id === newS.name) };
        if (newName) {
          newS.name = newName.name;
        }
      }
      return newS;
    });
  },
  getBuitifullSubject: (subjects, themeSubjects) => {
    return subjects.map(s => {
      const newS = { ...s };
      const newName = { ...themeSubjects.find(t => t._id === s.name) };
      newS.name = newName.name;
      return newS;
    });
  },
};

export { SubjectUtils };
