import { Tooltip } from '@mui/material';
import { useMemo } from 'react';
import 'react-calendar-timeline/lib/Timeline.css';
import { injectIntl } from 'react-intl';
import { TimelineEnums } from 'services/timeline';
import { ValueUtils } from 'tools';
import '../Timeline.css';

const vod = ValueUtils.valueOrDefault;

function TimelineItemComponent(props) {
  const { item, itemContext, getItemProps } = props;

  const Celltitle = useMemo(
    () =>
      `${item.matching?.students
        ?.map(e => `${e?.general_information?.first_name} ${e?.general_information?.last_name}`)
        .join('-')} / ${vod(item.matching?.volunteers[0]?.general_information?.first_name, '')} ${vod(
        item.matching?.volunteers[0]?.general_information?.last_name,
        '',
      )}`,
    [item],
  );

  function getColor(e) {
    const colors = TimelineEnums.storeColors();
    if (e.courses_state === 'SUSPENDED') {
      return colors.suspended;
    }
    if (e.type === 'HOME') {
      return colors.visio;
    }
    if (
      e.student_states.some(st => st.state === 'JA' || st.state === 'NJA') ||
      e.volunteer_state.state === 'JP' ||
      e.volunteer_state.state === 'NJP'
    ) {
      return colors.error;
    }
    if (
      (e.student_states.some(st => st.state === 'P') && e.volunteer_state.state === '') ||
      (!e.student_states.every(st => st.state === 'P') && e.volunteer_state.state === 'P')
    ) {
      return colors.incomplete;
    }
    if (
      e.student_states.every(st => st.state === 'P') &&
      e.volunteer_state.state === 'P' &&
      (e.courses_state === 'IN_PROGRESS' || e.courses_state === 'UNIQUE')
    ) {
      return colors.validated;
    }
    return colors.default;
  }

  const theme = getColor(item);
  const border = item.courses_state === 'UNIQUE' ? '1px solid red' : `1px solid ${theme.border}`;

  const itemProps = {
    'data-custom-attribute': 'Random content',
    'aria-hidden': true,
    className: item.className,
    style: {
      background: theme.background,
      border: border,
      opacity: `${itemContext.selected ? '0.8' : '1'}`,
      boxShadow: `${itemContext.dragging ? '5px 3px 8px #212121' : ''}`,
      borderRightWidth: 0,
    },
  };

  return (
    <div className="timeline-item" key={item._id} {...getItemProps(itemProps)}>
      {item.note !== '' && (
        <Tooltip title={item.note}>
          <div className="timeline-item-note"></div>
        </Tooltip>
      )}
      <div key={item._id} className="rct-item-content" style={{ maxHeight: `${itemContext.dimensions.height}` }}>
        <Tooltip title={Celltitle}>
          <div>{Celltitle}</div>
        </Tooltip>
      </div>
    </div>
  );
}

const TimelineItem = injectIntl(TimelineItemComponent);

export { TimelineItem };
