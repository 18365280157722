import { TableProvider } from 'hooks';
import { paths } from 'routes';
import { AuthenticatedRoute } from 'routes/components';
import { StudentCreate, StudentEdit, StudentList, StudentProfilHome } from 'scenes';

const studentRoutes = [
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.student.home,
      exact: true,
      component: () => (
        <TableProvider>
          <StudentList />
        </TableProvider>
      ),
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.student.create,
      exact: true,
      component: StudentCreate,
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.student.edit,
      exact: true,
      component: StudentEdit,
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.student.profil,
      exact: true,
      component: StudentProfilHome,
    },
  },
];

export { studentRoutes };
