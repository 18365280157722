import { Paper } from '@mui/material';
import { Loader } from 'components';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { BigWorkshopActioner } from 'services/bigWorkshop';
import { WorkshopActioner } from 'services/workshop';
import { BigWorkshopForm } from './BigWorkshopForm';

function BigWorkshopCreateComponent(props) {
  const [, reload] = useState();
  const intl = props.intl.messages.scenes.workshop.edit;

  function editForm(values, bigWorkshops) {
    return <BigWorkshopForm bigWorkshops={bigWorkshops} workshops={values} reload={() => reload({})} mode={'create'} />;
  }

  function loadInfos() {
    return WorkshopActioner.list()
      .then(infos => {
        const values = [...infos];
        return values;
      })
      .catch(err => {
        throw err;
      });
  }

  function renderEditForm(render) {
    Promise.all([loadInfos(), BigWorkshopActioner.list()]).then(([values, bigWorkshops]) => {
      render(editForm(values, bigWorkshops));
    });
  }

  return (
    <Paper className="padding-small">
      <h2 className="text-centered">{intl.title}</h2>
      <Loader render={renderEditForm} />
    </Paper>
  );
}

const BigWorkshopCreate = injectIntl(BigWorkshopCreateComponent);

export { BigWorkshopCreate };
