import { AddCircle, Edit } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { Selector } from 'components/Selector';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { StudentEnums } from 'services/student';
import { ArrayToSelector } from 'tools';

function AddFamilyLinksComponent({
  setOpenCreate,
  addFamilyLinks,
  disabled,
  editFamilyLinks,
  editItem,
  editItemIndex,
  ...props
}) {
  const emptyFamilyLink = { type: '', student: '' };
  const { students } = props;
  const [newFamilyLink, setNewFamilyLink] = useState(editItem ?? emptyFamilyLink);

  const types = StudentEnums.getTypeFamilyLink();
  const intl = props.intl.messages.scenes.student.family_link;
  const labels = intl.labels;

  function setFieldFunction(name) {
    return value => {
      setNewFamilyLink(a => ({ ...a, [name]: value }));
    };
  }

  function addNewFamilyLinks() {
    addFamilyLinks({ ...newFamilyLink });
  }

  return (
    <Grid justifyContent="space-evenly" container>
      <Grid item xs={8} sm={8} className="padding-small">
        <Grid justifyContent="space-evenly" container>
          <Typography className="marginB20" component="span" variant="h6">
            {intl.table.dialogTitle.toUpperCase()}
          </Typography>
        </Grid>
        <Selector
          labelId="type"
          label={labels.type}
          selected={newFamilyLink.type}
          setSelected={setFieldFunction('type')}
          items={types}
          disabled={disabled}
        />
        <Selector
          labelId="student"
          label={labels.student}
          selected={newFamilyLink.student}
          setSelected={setFieldFunction('student')}
          items={ArrayToSelector.getGeneralInformationNamesArray(students)}
          disabled={disabled}
        />
        <Grid justifyContent="space-evenly" container>
          <Button
            variant="contained"
            color="primary"
            startIcon={editItem !== undefined ? <Edit /> : <AddCircle />}
            onClick={() => {
              editItem !== undefined ? editFamilyLinks(newFamilyLink, editItemIndex) : addNewFamilyLinks();
              setNewFamilyLink(emptyFamilyLink);
              setOpenCreate(false);
            }}
          >
            {editItem !== undefined ? intl.table.edit : intl.table.add}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

const AddFamilyLinks = injectIntl(AddFamilyLinksComponent);

export { AddFamilyLinks };
