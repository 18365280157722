import { Box } from '@mui/material';
import { WaveAnimation } from 'components/WaveAnimation';
import { injectIntl } from 'react-intl';

function IconSelectorComponent(props) {
  const { className, items, setItems, Output, title, multiple } = props;
  function clickHandler(item) {
    const newItems = items.map(i =>
      i.label === item.label
        ? Object.assign({ ...i }, { select: !item.select })
        : multiple
        ? i
        : Object.assign({ ...i }, { select: false }),
    );
    setItems(newItems);
  }

  return (
    <div className={`marginB20 marginT20 ${className ?? ''}`}>
      <h4>{title}</h4>
      <Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
        <WaveAnimation delay={200} time={200}>
          {items.map(item => (
            <div
              key={item.label}
              onClick={() => clickHandler(item)}
              className={`come-in-bottom-1s margin10 icon-selector-item ${item?.select ? 'icon-selected' : ''}`}
            >
              <Output item={item} />
            </div>
          ))}
        </WaveAnimation>
      </Box>
    </div>
  );
}

const IconSelector = injectIntl(IconSelectorComponent);

export { IconSelector };
