import { AddCircle, Delete, Edit } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { toast } from 'components';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { paths } from 'routes';
import { SiteActioner } from 'services';

function SiteSubmitButtonComponent({ fields, setFields, mode, initialValues, reload, ...props }) {
  const intl = props.intl.messages.scenes.submitButtons;
  const buttonIntl = props.intl.messages.scenes.site.button;
  const toastMessages = props.intl.messages.toast;
  const id = initialValues.id;
  const history = useHistory();

  function setLoading(loading = true) {
    setFields(f => ({ ...f, loading }));
  }

  function setError(name) {
    setFields(f => ({ ...f, errors: { ...f.errors, [name]: true } }));
  }

  function onEdit() {
    const fieldsToSend = {};

    if (fields.name !== initialValues.name) {
      if (fields.name === '') {
        return setError('name');
      }
      fieldsToSend.name = fields.name;
    }

    if (fields.description !== initialValues.description) {
      fieldsToSend.description = fields.description;
    }

    if (Object.entries(fieldsToSend).length === 0) {
      return toast.info(toastMessages.info.noFieldChanged);
    }
    setLoading(true);

    SiteActioner.update(id, fieldsToSend)
      .then(() => {
        setLoading(false);
        reload();
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function onCreate() {
    if (fields.name === '') {
      return setError('name');
    }

    setLoading(true);
    SiteActioner.create(fields)
      .then(() => {
        setLoading(false);
        history.push(paths.front.site.home);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function onDelete() {
    setLoading(true);
    SiteActioner.delete(id)
      .then(() => {
        setLoading(false);
        history.push(paths.front.site.home);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <Grid justifyContent="space-evenly" container>
      <Button
        variant="contained"
        color="primary"
        startIcon={mode === 'edit' ? <Edit /> : <AddCircle />}
        onClick={mode === 'edit' ? onEdit : onCreate}
        disabled={fields.loading}
      >
        {intl.save}
      </Button>
      {mode === 'edit' && (
        <ConfirmDialog
          onConfirm={onDelete}
          button={({ onClick }) => (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Delete />}
              onClick={onClick}
              disabled={fields.loading}
            >
              {buttonIntl.title}
            </Button>
          )}
        >
          {buttonIntl.modal.replace(':nom', fields.name)}
        </ConfirmDialog>
      )}
    </Grid>
  );
}

const SiteSubmitButton = injectIntl(SiteSubmitButtonComponent);

export { SiteSubmitButton };
