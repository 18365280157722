import { Paper } from '@mui/material';
import { Loader } from 'components';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { SubjectActioner } from 'services/subject';
import { ThemeSubjectActioner } from 'services/themeSubject';
import { SubjectForm } from './SubjectForm';

function SubjectEditComponent(props) {
  const [, reload] = useState();
  const id = props.match.params.id;
  const intl = props.intl.messages.scenes.subject.edit;

  function editForm(themes, values) {
    return <SubjectForm values={values} themes={themes} reload={() => reload({})} mode={'edit'} />;
  }

  function loadInfos() {
    return SubjectActioner.get(id)
      .then(infos => {
        const values = { ...infos, id };
        return values;
      })
      .catch(err => {
        throw err;
      });
  }

  function renderEditForm(render) {
    Promise.all([ThemeSubjectActioner.list(), loadInfos()]).then(([themes, values]) => {
      render(editForm(themes, values));
    });
  }

  return (
    <Paper className="padding-small">
      <h2 className="text-centered">{intl.title}</h2>
      <Loader render={renderEditForm} />
    </Paper>
  );
}

const SubjectEdit = injectIntl(SubjectEditComponent);

export { SubjectEdit };
