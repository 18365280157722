import { WorkshopManagment } from 'components/ComonProfil';
import { TabAppBar } from 'components/TabAppBar';
import { injectIntl } from 'react-intl';
import { InterviewList } from 'scenes';
import { VolunteerActioner } from 'services/volunteer';
import './style/VolunteerProfilHome.css';
import { VolunteerProfil } from './VolunteerProfil';
import { VolunteerProfilMatching } from './VolunteerProfilMatching';

function VolunteerProfilHomeComponent(props) {
  const id = props.match.params.id;

  return (
    <TabAppBar>
      <VolunteerProfil tabLabel="Profil" id={id} />
      <InterviewList tabLabel="Entretien" interviewedId={id} type="volunteer" />
      <WorkshopManagment
        tabLabel="Atelier"
        entityId={id}
        getActioner={VolunteerActioner.get}
        updateActioner={VolunteerActioner.update}
      />
      <VolunteerProfilMatching tabLabel="Matchings" id={id} />
    </TabAppBar>
  );
}

const VolunteerProfilHome = injectIntl(VolunteerProfilHomeComponent);

export { VolunteerProfilHome };
