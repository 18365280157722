import { AddCircle, Delete, Edit } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, Paper, Typography } from '@mui/material';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { Table } from 'components/Table';
import React, { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';

const TableFormAndDisplayComponent = props => {
  const { columns, rows, table, title, children, mode, useEdit, onDelete, disabled } = props;
  const [openCreate, setOpenCreate] = useState(false);
  const [editItem, setEditItem] = useState(undefined);
  const [editItemIndex, setEditItemIndex] = useState(-1);
  const tableColumns = useMemo(() => {
    if (mode === 'form') {
      return [
        ...columns,
        {
          id: 'action',
          label: '',
          align: 'right',
          render: (row, index) => (
            <>
              {useEdit && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setOpenCreate(true);
                    setEditItem(row);
                    setEditItemIndex(index);
                  }}
                >
                  <Edit color="primary" />
                </IconButton>
              )}
              <ConfirmDialog
                onConfirm={onDelete(index)}
                button={({ onClick }) => (
                  <IconButton disabled={disabled} onClick={onClick} size="small">
                    <Delete color={disabled ? 'disabled' : 'error'} />
                  </IconButton>
                )}
              ></ConfirmDialog>
            </>
          ),
        },
      ];
    }
    return columns;
  }, [columns, onDelete, disabled, mode, useEdit]);

  function onClose() {
    setOpenCreate(false);
    setEditItem(undefined);
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setOpenCreate, openCreate, editItem, editItemIndex, disabled, data: rows });
    }
    return child;
  });

  return (
    <Paper className="padding-small full-width marginB20">
      <Box
        className={mode === 'form' ? 'marginB20' : ''}
        display="flex"
        justifyContent={mode === 'form' ? 'space-between' : 'center'}
        alignItems="center"
      >
        <Typography component="span" variant="h5" className={mode === 'form' ? '' : 'info-title'}>
          {title}
        </Typography>
        {mode === 'form' && (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddCircle />}
            onClick={() => {
              setOpenCreate(true);
            }}
          >
            {table.add}
          </Button>
        )}
      </Box>
      <Table columns={tableColumns} rows={rows} />
      {rows.length === 0 && (
        <Box className="marginBottom" display="flex" justifyContent="center" alignItems="center">
          <Typography component="span" variant="subtitle1">
            {table.no_data}
          </Typography>
        </Box>
      )}
      <>
        <Dialog open={openCreate} onClose={onClose} fullWidth maxWidth="md">
          {childrenWithProps}
        </Dialog>
      </>
    </Paper>
  );
};

TableFormAndDisplayComponent.defaultProps = {
  columns: [],
  rows: [],
  table: {
    add: 'Ajouter',
    noData: 'Aucune Données',
  },
  title: 'Titre',
  mode: '',
  useEdit: false,
  onDelete: () => {},
};

const TableFormAndDisplay = injectIntl(TableFormAndDisplayComponent);

export { TableFormAndDisplay };
