import { Paper } from '@mui/material';
import { Loader } from 'components';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { ThemeSubjectActioner } from 'services/themeSubject';
import { SubjectForm } from './SubjectForm';

function SubjectCreateComponent(props) {
  const [, reload] = useState();
  const intl = props.intl.messages.scenes.subject.create;

  function editForm(themes) {
    return <SubjectForm themes={themes} reload={() => reload({})} mode={'create'} />;
  }

  function renderEditForm(render) {
    ThemeSubjectActioner.list().then(themes => {
      render(editForm(themes));
    });
  }

  return (
    <Paper className="padding-small">
      <h2 className="text-centered">{intl.title}</h2>
      <Loader render={renderEditForm} />
    </Paper>
  );
}

const SubjectCreate = injectIntl(SubjectCreateComponent);

export { SubjectCreate };
