import { WorkshopManagment } from 'components/ComonProfil';
import { TabAppBar } from 'components/TabAppBar';
import { injectIntl } from 'react-intl';
import { InterviewList } from 'scenes';
import { StudentActioner } from 'services/student';
import { StudentProfil } from './StudentProfil';
import { StudentProfilMatching } from './StudentProfilMatching';
import './style/StudentProfilHome.css';

function StudentProfilHomeComponent(props) {
  const id = props.match.params.id;
  return (
    <TabAppBar>
      <StudentProfil tabLabel="Profil" id={id} />
      <InterviewList tabLabel="Entretien" interviewedId={id} type="student" />
      <WorkshopManagment
        tabLabel="Atelier"
        entityId={id}
        getActioner={StudentActioner.get}
        updateActioner={StudentActioner.update}
      />
      <StudentProfilMatching tabLabel="Matching" id={id} />
    </TabAppBar>
  );
}

const StudentProfilHome = injectIntl(StudentProfilHomeComponent);

export { StudentProfilHome };
