import { paths } from 'routes';
import { AuthenticatedRoute } from 'routes/components';
import { SubjectCreate, SubjectEdit, SubjectList, ThemeSubjectCreate, ThemeSubjectEdit } from 'scenes';

const subjectRoutes = [
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.subject.home,
      exact: true,
      component: SubjectList,
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.subject.create,
      exact: true,
      component: SubjectCreate,
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.subject.edit,
      exact: true,
      component: SubjectEdit,
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.subject.theme.create,
      exact: true,
      component: ThemeSubjectCreate,
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.subject.theme.edit,
      exact: true,
      component: ThemeSubjectEdit,
    },
  },
];

export { subjectRoutes };
