import { fr } from 'services/i18n';
import { ArrayToSelector } from 'tools/arrayToSelector';

const intl = fr.messages.scenes.common.reminder;

const ComonEnums = {
  getKnowLacleArray: () => {
    const kwonLacleList = [
      'BAO (pour bouche à oreille)',
      'établissement scolaire',
      'mairie',
      'référent RSA',
      'internet',
      'AS',
      'DRE',
      'éducateur',
      'CCAS',
      'Pôle emploi',
      'Cimade',
      'Presse',
      'France Bénévolat',
      'Partenaire',
      'Employeur',
      'autre',
      '',
    ];
    return ArrayToSelector.getEnums(kwonLacleList);
  },
  getSubjectArray: () => {
    const matiereList = [
      'MSB',
      'Alphabétisation',
      'FLE',
      'Français',
      'Français Langue de Scolarisation',
      'Mathématiques',
      'Anglais',
      'SVT',
      'Sciences Physiques',
      'Philosophie',
      'S.E.S',
      'lecture-écriture-calcul',
      'Allemand',
      'Espagnol',
      'Histoire',
      'Mathématique',
      'Histoire Géographie',
      'Alpha',
    ];
    return ArrayToSelector.getEnums(matiereList);
  },
  getDistrictArray: () => {
    let districtList = [
      'Bois Blancs',
      'Centre',
      'Faubourg de Béthune',
      'Fives',
      'Hellemmes',
      'Lille Sud',
      'Lomme',
      'Moulins',
      'Saint Maurice-Pellevoisin',
      'Vauban Esquermes',
      'Vieux Lille',
      'Wazemmes',
      'Autre',
      '',
    ];
    return ArrayToSelector.getEnums(districtList);
  },
  getGenderArray: () => {
    const genders = [
      { value: 'F', label: 'Femme' },
      { value: 'M', label: 'Homme' },
    ];
    return genders;
  },
  getSchoolPathArray: () => {
    const schoolPathList = ['Brevet', 'Bac', 'Bac+1', 'Bac+2', 'Bac+3', 'Bac+4', 'Bac+5', 'Bac+8', ''];
    return ArrayToSelector.getEnums(schoolPathList);
  },
  getReminderStatus: () => {
    const status = [
      { value: 'WAITING', label: intl.status.waiting },
      { value: 'ACCEPTED', label: intl.status.accepted },
      { value: 'REJECTED', label: intl.status.rejected },
    ];
    return status;
  },
  getGeneralStatus: () => {
    const status = [
      { value: 'WAITING', label: intl.status.waiting },
      { value: 'ACCEPTED', label: intl.status.accepted },
      { value: 'REJECTED', label: intl.status.rejected },
      { value: 'HERE', label: intl.status.here },
      { value: 'ABSENT', label: intl.status.absent },
      { value: 'EXCUSED', label: intl.status.excused },
    ];
    return status;
  },
  getDefaultStatus: () => {
    return 'WAITING';
  },
  getDays: () => {
    const days = [
      { label: 'Lundi', value: 'lundi' },
      { label: 'Mardi', value: 'mardi' },
      { label: 'Mercredi', value: 'mercredi' },
      { label: 'Jeudi', value: 'jeudi' },
      { label: 'Vendredi', value: 'vendredi' },
      { label: 'Samedi', value: 'samedi' },
      { label: 'Tout le temps', value: 'ALL_DAYS' },
    ];
    return days;
  },
  getEmailTypes: () => {
    const types = [
      { label: 'Personel', value: 'OWN' },
      { label: 'Père', value: 'DAD' },
      { label: 'Mère', value: 'MUM' },
      { label: 'Famille', value: 'FAMILY' },
    ];
    return types;
  },
  getTypeArray: () => {
    const type = [
      {
        key: 'Enfant',
        label: 'Enfant',
        value: 'Enfant',
      },
      {
        key: 'Ado',
        label: 'Ado',
        value: 'Ado',
      },
      {
        key: 'Adulte',
        label: 'Adulte',
        value: 'Adulte',
      },
    ];
    return type;
  },
  getLevelArray: () => {
    const level = [
      {
        key: 'Primaire',
        label: 'Primaire',
        value: 'Primaire',
      },
      {
        key: 'Collège',
        label: 'Collège',
        value: 'Collège',
      },
      {
        key: 'Lycée',
        label: 'Lycée',
        value: 'Lycée',
      },
    ];
    return level;
  },
  getAdultLevelArray: () => {
    const adultLevel = [
      {
        key: 'Débutant',
        label: 'Débutant',
        value: 'Débutant',
      },
      {
        key: 'Faux Débutant',
        label: 'Faux Débutant',
        value: 'Faux Débutant',
      },
      {
        key: 'Intermédiaire',
        label: 'Intermédiaire',
        value: 'Intermédiaire',
      },
      {
        key: 'Perfectionnement',
        label: 'Perfectionnement',
        value: 'Perfectionnement',
      },
    ];
    return adultLevel;
  },

  getOtherInterventionList: () => {
    return ['LACLE', 'DRE', 'ROUBAIX', 'LILLE SUD', 'TOURCOING FLS', 'TOURCOING - THEATRE', 'ROUBAIX FLS', 'AUTRE'];
  },

  getOtherInterventionArray: () => {
    const otherInterventionList = ComonEnums.getOtherInterventionList();
    return ArrayToSelector.getEnums(otherInterventionList);
  },
};

export { ComonEnums };
