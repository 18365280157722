import { useCallback, useMemo } from 'react';
import { useLocalStorage } from './useLocalStorage';

export const useConfig = () => {
  const [config, setConfig] = useLocalStorage('config', {});

  const setConfigfield = useCallback(
    (name, value) => {
      setConfig(old => ({ ...old, [name]: value }));
    },
    [setConfig],
  );

  const setCampaign = useCallback(
    value => {
      setConfigfield('campaign', value);
    },
    [setConfigfield],
  );

  const campaign = useMemo(() => config?.campaign, [config?.campaign]);

  const setSite = useCallback(
    value => {
      setConfigfield('site', value);
    },
    [setConfigfield],
  );

  const site = useMemo(() => config?.site, [config?.site]);

  return { config, setConfigfield, setCampaign, campaign, site, setSite };
};
