import { AddCircle, Edit, FileCopy, ListAlt } from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton, Paper, useTheme } from '@mui/material';
import { TabAppBar } from 'components/TabAppBar';
import { TableProvider, useConfigContext, useTableContext, useTableState } from 'hooks';
import MaterialReactTable from 'material-react-table';
import moment from 'moment';
import * as R from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { PositionedWorkshopActioner, PositionedWorkshopUtils } from 'services/positionedWorkshop';
import { tokens } from 'themes';

const PlannedWorkshop = ({ data, columns }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const history = useHistory();
  const tableRef = useTableContext();
  const tableState = useTableState('planned_workshop');
  const {
    messages: {
      scenes: {
        positionedWorkshop: { list: intl },
      },
    },
  } = useIntl();
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableDensityToggle={false}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 25, 50, 100, 150, { label: 'All', value: data?.docs?.current?.length ?? 0 }],
      }}
      positionActionsColumn="last"
      positionGlobalFilter="left" //show the global filter on the left side of the top toolbar
      initialState={{
        showGlobalFilter: true, //show the global filter by default
        density: 'compact',
        pagination: {
          pageSize: 25,
        },
      }}
      muiTableBodyCellProps={{
        //simple styling with the `sx` prop, works just like a style prop in this example
        sx: {
          fontWeight: 'normal',
          fontSize: '110%',
        },
      }}
      enableRowActions={true}
      renderRowActions={({ row }, index) => {
        return (
          <div key={index}>
            <IconButton
              size="small"
              component={Link}
              to={paths.front.workshop.positionedWorkshop.edit.replace(':id', row.original._id)}
            >
              <Edit sx={{ color: colors.primary[600] }} />
            </IconButton>
            <IconButton
              size="small"
              component={Link}
              to={`${paths.front.workshop.callList.home.replace(':id', row.original._id)}?workshop_name=${
                row.original.title
              }`}
            >
              <ListAlt sx={{ color: colors.primary[600] }} />
            </IconButton>
            <IconButton
              size="small"
              component={Link}
              to={{
                pathname: paths.front.workshop.positionedWorkshop.create,
                state: { duplicate: true, id: row.original._id },
              }}
            >
              <FileCopy sx={{ color: colors.redAccent[500] }} />
            </IconButton>
          </div>
        );
      }}
      enableToolbarInternalActions={true}
      renderTopToolbarCustomActions={() => {
        return (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={() => history.push(paths.front.workshop.positionedWorkshop.create)}
          >
            {intl.button.add}
          </Button>
        );
      }}
      enableColumnResizing
      defaultColumn={{
        minSize: 20, //allow columns to get smaller than default
        maxSize: 1000, //allow columns to get larger than default
        size: 400, //make columns wider by default
      }}
      tableInstanceRef={tableRef}
      autoResetPageIndex={false}
      {...tableState}
    />
  );
};

const PassedWorkShop = ({ data, columns }) => {
  const tableRef = useTableContext();
  const tableState = useTableState('passed_workshop');
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableDensityToggle={false}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 25, 50, 100, 150, { label: 'All', value: data?.docs?.previous?.length ?? 0 }],
      }}
      positionActionsColumn="last"
      positionGlobalFilter="left" //show the global filter on the left side of the top toolbar
      initialState={{
        showGlobalFilter: true, //show the global filter by default
        density: 'compact',
        pagination: {
          pageSize: 25,
        },
      }}
      muiTableBodyCellProps={{
        //simple styling with the `sx` prop, works just like a style prop in this example
        sx: {
          fontWeight: 'normal',
          fontSize: '110%',
        },
      }}
      enableRowActions={true}
      renderRowActions={({ row }, index) => {
        return (
          <div key={index}>
            <IconButton
              size="small"
              component={Link}
              to={paths.front.workshop.positionedWorkshop.edit.replace(':id', row.original._id)}
            >
              <Edit />
            </IconButton>
            <IconButton
              size="small"
              component={Link}
              to={paths.front.workshop.callList.home.replace(':id', row.original._id)}
            >
              <ListAlt />
            </IconButton>
          </div>
        );
      }}
      enableColumnResizing
      defaultColumn={{
        minSize: 20, //allow columns to get smaller than default
        maxSize: 1000, //allow columns to get larger than default
        size: 400, //make columns wider by default
      }}
      tableInstanceRef={tableRef}
      autoResetPageIndex={false}
      {...tableState}
    />
  );
};

function PositionedWorkshopComponent(props) {
  const [data, setData] = useState({ loading: false, docs: {}, templates: {} });
  const { campaign, site, config } = useConfigContext();
  useEffect(() => {
    setData(f => ({ ...f, loading: true }));
  }, [config]);

  useEffect(() => {
    function sortArrayDesc(tab) {
      return R.sort((a, b) => moment(b?.positioned_date).diff(a?.positioned_date), tab);
    }

    function sortArrayAsc(tab) {
      return R.sort((a, b) => moment(a?.positioned_date).diff(b?.positioned_date), tab);
    }

    function splitArray(tab) {
      return {
        previous: sortArrayDesc(tab.filter(t => moment(t.positioned_date).isBefore(moment().startOf('day')))),
        current: sortArrayAsc(tab.filter(t => moment(t.positioned_date).isSameOrAfter(moment().startOf('day')))),
      };
    }
    if (data.loading) {
      Promise.all([
        PositionedWorkshopActioner.list(campaign, site),
        PositionedWorkshopUtils.getPositionedWorkshopTemplates(campaign, site),
      ]).then(([positionedWorkshopsFound, templatesFound]) => {
        setData(f => ({ ...f, loading: false, docs: splitArray(positionedWorkshopsFound), templates: templatesFound }));
      });
    }
    return () => {};
  }, [data.loading, campaign, site]);

  const {
    messages: {
      scenes: {
        positionedWorkshop: { list: intl },
      },
    },
  } = useIntl();
  const columnTitles = intl.columnTitles;

  const columns = useMemo(
    () => [
      {
        header: columnTitles.workshopName,
        accessorKey: 'workshop',
        Cell: ({ cell }) => {
          const w = data.templates.workshops.find(w => w._id === cell.getValue());
          const bw = data.templates.bigworkshops.find(bw => bw.workshop.includes(w?._id));
          return `[${bw?.name ?? ''}] ${w?.name ?? 'Inconnu'}`;
        },
        enableColumnFilter: false,
      },
      {
        header: columnTitles.title,
        accessorKey: 'title',
        enableColumnFilter: false,
      },
      {
        header: columnTitles.date,
        accessorKey: 'positioned_date',
        enableColumnFilter: false,
        size: 80,
        Cell: ({ cell }) => (cell.getValue() ? moment(cell.getValue()).format('DD/MM/YYYY HH:mm') : 'Inconnue'),
      },
      {
        header: columnTitles.animator,
        accessorKey: 'animator',
        size: 100,
        Cell: ({ cell }) => data.templates.animators.find(w => w._id === cell.getValue())?.firstname ?? 'Inconnu',
        filterVariant: 'select',
        filterSelectOptions: data.templates?.animators?.map(a => ({ text: a.firstname, value: a._id })) ?? [],
      },
    ],
    [
      columnTitles.animator,
      columnTitles.date,
      columnTitles.title,
      columnTitles.workshopName,
      data.templates.animators,
      data.templates.workshops,
      data.templates.bigworkshops,
    ],
  );

  if (data.loading) {
    return (
      <Paper className="padding-small">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="height-circular"
        >
          <CircularProgress color="primary" />
        </Grid>
      </Paper>
    );
  }

  return (
    <TabAppBar stateName="workshop_tab">
      <TableProvider tabLabel={'Ateliers prévus'}>
        <PlannedWorkshop data={data.docs?.current ?? []} columns={columns} />
      </TableProvider>
      <TableProvider tabLabel={'Ateliers passés'}>
        <PassedWorkShop data={data.docs?.previous ?? []} columns={columns} />
      </TableProvider>
    </TabAppBar>
  );
}

const PositionedWorkshopList = PositionedWorkshopComponent;

export { PositionedWorkshopList };
