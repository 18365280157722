import { AddCircle, Edit } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { Selector } from 'components/Selector';
import { useState } from 'react';
import { injectIntl } from 'react-intl';

function AddSubjectsComponent({
  setOpenCreate,
  subjects,
  addSubjects,
  editSubjects,
  disabled,
  editItem,
  editItemIndex,
  ...props
}) {
  const emptySubject = {
    name: '',
    type: '',
    level: '',
  };
  const [newSubjects, setNewSubjects] = useState(editItem ?? emptySubject);

  const intl = props.intl.messages.components.subjects;
  const labels = intl.labels;

  function setFieldFunction(name) {
    return value => {
      setNewSubjects(a => ({ ...a, [name]: value }));
    };
  }

  function findName() {
    const subjectsTheme = subjects
      .map(s => s.name)
      .filter(function (ele, pos) {
        return subjects.map(s => s.name).indexOf(ele) === pos;
      });
    return subjectsTheme.map(s => {
      return { label: s, value: s };
    });
  }

  function findType(name) {
    const filtredType = subjects.filter(s => s.name === name).map(s => s.type);
    const filtered = filtredType.filter(function (ele, pos) {
      return filtredType.indexOf(ele) === pos;
    });
    return filtered.map(s => {
      return { label: s, value: s };
    });
  }

  function findLevel(name, type) {
    const filtredType = subjects.filter(s => s.name === name && s.type === type).map(s => s.level);
    const filtered = filtredType.filter(function (ele, pos) {
      return filtredType.indexOf(ele) === pos;
    });
    return filtered.map(s => {
      return { label: s, value: s };
    });
  }

  function editNewSubject() {
    if (newSubjects.name !== '' && newSubjects.type !== '' && newSubjects.level !== '') {
      const subjectToSend = subjects.find(
        s => s.name === newSubjects.name && s.type === newSubjects.type && s.level === newSubjects.level,
      );
      if (subjectToSend !== undefined) {
        editSubjects(subjectToSend._id, editItemIndex);
      }
    }
  }

  function addNewSubjects() {
    if (newSubjects.name !== '' && newSubjects.type !== '' && newSubjects.level !== '') {
      const subjectToSend = subjects.find(
        s => s.name === newSubjects.name && s.type === newSubjects.type && s.level === newSubjects.level,
      );
      if (subjectToSend !== undefined) {
        addSubjects(subjectToSend._id);
      }
    }
  }

  return (
    <Grid justifyContent="space-evenly" container>
      <Grid item xs={8} sm={8} className="padding-small">
        <Grid justifyContent="space-evenly" container>
          <Typography component="span" variant="h6">
            {intl.table.dialogTitle.toUpperCase()}
          </Typography>
        </Grid>
        <Selector
          labelId="name"
          label={labels.name}
          selected={newSubjects.name}
          setSelected={setFieldFunction('name')}
          items={findName()}
          disabled={disabled}
        />
        <Selector
          labelId="type"
          label={labels.type}
          selected={newSubjects.type}
          setSelected={setFieldFunction('type')}
          items={findType(newSubjects.name)}
          disabled={disabled || newSubjects.name === ''}
        />
        <Selector
          labelId="level"
          label={labels.level}
          selected={newSubjects.level}
          setSelected={setFieldFunction('level')}
          items={findLevel(newSubjects.name, newSubjects.type)}
          disabled={disabled || newSubjects.type === ''}
        />
        <Grid justifyContent="space-evenly" container>
          <Button
            variant="contained"
            color="primary"
            startIcon={editItem !== undefined ? <Edit /> : <AddCircle />}
            onClick={() => {
              editItem !== undefined ? editNewSubject() : addNewSubjects();
              setOpenCreate(false);
            }}
          >
            {editItem !== undefined ? intl.table.edit : intl.table.add}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

const AddSubjects = injectIntl(AddSubjectsComponent);

export { AddSubjects };
