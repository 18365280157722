import { AddCircle, Edit } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { SubjectActioner } from 'services/subject';
import { ThemeSubjectActioner } from 'services/themeSubject';
import { ThemeSubjectTable } from './Theme';

function SubjectListComponent(props) {
  const [data, setData] = useState({ loading: true, docs: [], themeDocs: [] });

  const history = useHistory();
  const {
    messages: {
      scenes: {
        subject: { list: intl },
      },
    },
  } = useIntl();
  const columnTitles = intl.columnTitles;

  useEffect(() => {
    if (data.loading) {
      Promise.all([ThemeSubjectActioner.list(), SubjectActioner.list()]).then(([themedocs, docs]) => {
        setData(f => ({ ...f, loading: false, docs, themeDocs: themedocs }));
      });
    }
    return () => {};
  }, [data.loading]);

  const columns = useMemo(
    () => [
      {
        header: columnTitles.name,
        accessorKey: 'name',
        Cell: ({ cell }) => {
          return data.themeDocs.find(t => t._id === cell.getValue())?.name ?? 'Inconnu';
        },
      },
      {
        header: columnTitles.type,
        accessorKey: 'type',
      },
      {
        header: columnTitles.level,
        accessorKey: 'level',
      },
    ],
    [columnTitles.type, columnTitles.level, columnTitles.name, data.themeDocs],
  );

  if (data.loading) {
    return (
      <Paper className="padding-small">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="height-circular"
        >
          <CircularProgress color="primary" />
        </Grid>
      </Paper>
    );
  }

  return (
    <>
      <Box sx={{ p: 2 }}>
        <ThemeSubjectTable subjectFound={data.themeDocs} />
      </Box>
      <Box sx={{ p: 2 }}>
        <Paper>
          <Box p={2}>
            <Typography variant="h3">{intl.title}</Typography>
          </Box>
          <MaterialReactTable
            muiTablePaperProps={{
              elevation: 0, //change the mui box shadow
            }}
            columns={columns}
            data={data.docs}
            enableDensityToggle={false}
            enableColumnActions={false}
            muiTablePaginationProps={{
              rowsPerPageOptions: [10, 25, 50, 100, 150, { label: 'All', value: data?.docs.length ?? 0 }],
            }}
            muiTableBodyCellProps={{
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                fontWeight: 'normal',
                fontSize: '110%',
              },
            }}
            positionActionsColumn="last"
            positionGlobalFilter="left" //show the global filter on the left side of the top toolbar
            initialState={{
              showGlobalFilter: true, //show the global filter by default
            }}
            enableRowActions={true}
            renderRowActions={({ row }, index) => {
              return (
                <IconButton
                  key={index}
                  size="small"
                  component={Link}
                  to={paths.front.subject.edit.replace(':id', row.original._id)}
                >
                  <Edit />
                </IconButton>
              );
            }}
            enableToolbarInternalActions={true}
            renderTopToolbarCustomActions={() => {
              return (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircle />}
                  onClick={() => history.push(paths.front.subject.create)}
                >
                  {intl.add}
                </Button>
              );
            }}
          />
        </Paper>
      </Box>
    </>
  );
}

const SubjectList = SubjectListComponent;

export { SubjectList };
