import { Update } from '@mui/icons-material';
import { Button, Dialog, Grid } from '@mui/material';
import { DateInput } from 'components/DateInput';
import moment from 'moment';
import { useContext, useState } from 'react';
import { injectIntl } from 'react-intl';
import { TimelineActioner, TimelineContext } from 'services/timeline';

function ItemAdjournComponent(props) {
  const { openForm, setOpenForm, item } = props;

  const context = useContext(TimelineContext);

  const [lock, setLock] = useState(false);
  const [itemAdjourn, setItemAdjourn] = useState(item);

  const duration = moment(itemAdjourn.end_time).diff(moment(itemAdjourn.start_time));

  const intl = props.intl.messages.scenes.timeline;
  const labels = intl.labels;

  function setFieldFunction(name) {
    return value => {
      setItemAdjourn(a => ({ ...a, [name]: value }));
    };
  }

  function adjournItem() {
    setLock(true);
    const new_AdjItem = { ...itemAdjourn };
    new_AdjItem._id = undefined;
    new_AdjItem.room = context.room_30;
    new_AdjItem.group = context.room_30;
    new_AdjItem.courses_state = 'UNIQUE';
    new_AdjItem.student_states = new_AdjItem.student_states.map(st => Object.assign({}, { ...st, state: '' }));
    new_AdjItem.volunteer_state = { ...new_AdjItem.volunteer_state, state: '' };
    TimelineActioner.create(new_AdjItem).then(docs => {
      if (docs !== null) {
        setLock(false);
        context.addItem([docs]);
        setOpenForm(false);
      }
    });
  }

  return (
    <>
      <Dialog open={openForm} onClose={() => setOpenForm(false)} fullWidth maxWidth="sm">
        <div className="padding-normal">
          <h2 className="marginB20">Ajourner le cours</h2>
          <Grid container item xs={12} sm={12} className="marginB20 come-in-top full-width" spacing={1}>
            <Grid item xs={6} sm={6}>
              <DateInput
                id="start_time"
                label={labels.start_time}
                name="start_time"
                inputFormat="dd MMMM : HH:mm"
                value={itemAdjourn.start_time}
                setFieldFunction={value => {
                  setFieldFunction('start_time')(value);
                  setFieldFunction('end_time')(moment(value).add(duration, 'ms'));
                }}
                disabled={lock}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <DateInput
                id="end_time"
                label={labels.end_time}
                name="end_time"
                inputFormat="dd MMMM : HH:mm"
                value={itemAdjourn.end_time}
                setFieldFunction={setFieldFunction('end_time')}
                disabled={lock}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} className="marginB20" justifyContent="space-evenly">
            <Button
              variant="contained"
              color="primary"
              startIcon={<Update />}
              onClick={() => {
                adjournItem();
              }}
              disabled={lock}
            >
              {intl.buttons.date_change}
            </Button>
          </Grid>
        </div>
      </Dialog>
    </>
  );
}

const ItemAdjourn = injectIntl(ItemAdjournComponent);

export { ItemAdjourn };
