import { TableFormAndDisplay } from 'components/TableFormAndDisplay';
import React from 'react';
import { injectIntl } from 'react-intl';
import { StudentEnums } from 'services/student';
import { AddFamilyLinks } from './components';

function FamilyLinksComponent({ data, setData, disabled, mode, ...props }) {
  const intl = props.intl.messages.scenes.student.family_link;
  const labels = intl.labels;
  const { students } = props;
  const types = StudentEnums.getTypeFamilyLink();

  function deleteFamilyLinks(index) {
    return () => {
      const newFamilyLinks = [...data];
      newFamilyLinks.splice(index, 1);
      setData(newFamilyLinks);
    };
  }

  function addFamilyLinks(newData) {
    let newFamilyLinks = [...data];
    newFamilyLinks = newFamilyLinks.concat(newData);
    setData(newFamilyLinks);
  }

  function editFamilyLinks(newData, index) {
    let newFamilyLinks = [...data];
    newFamilyLinks[index] = newData;
    setData(newFamilyLinks);
  }

  function getColumns(mode) {
    const columns = [
      {
        id: 'type',
        align: 'center',
        label: labels.type,
        render: (row, index) => types.find(t => t.value === row.type)?.label,
      },
      {
        id: 'student',
        align: 'center',
        label: labels.student,
        render: (row, index) => {
          const student = students.find(s => s._id === row.student);
          return student ? student.general_information?.last_name + ' ' + student.general_information?.first_name : '';
        },
      },
    ];
    return columns;
  }

  return (
    <TableFormAndDisplay
      columns={getColumns(mode)}
      rows={data}
      title={intl.title}
      table={intl.table}
      mode={mode}
      useEdit={true}
      onDelete={deleteFamilyLinks}
    >
      <AddFamilyLinks
        addFamilyLinks={addFamilyLinks}
        editFamilyLinks={editFamilyLinks}
        students={students}
        disabled={disabled}
      />
    </TableFormAndDisplay>
  );
}

const FamilyLinkForm = injectIntl(FamilyLinksComponent);

export { FamilyLinkForm };
