import { Delete, Edit, SaveAs } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { Availabilities } from 'components/ComonForm';
import { ProfilSection } from 'components/ComonProfil';
import { ConfirmDialog } from 'components/ConfirmDialog';
import html2pdf from 'html2pdf.js';
import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { paths } from 'routes';
import { ComonEnums } from 'services/comon';
import { SubjectUtils } from 'services/subject';
import { VolunteerActioner } from 'services/volunteer';
import { SubjectForm } from './components/SubjectForm';
import './style/VolunteerProfil.css';

function VolunteerProfilComponent(props) {
  const [fields, setFields] = useState();
  const [subjects, setSubjects] = useState();
  const [loading, setLoading] = useState(true);
  const { id } = props;
  const intl = props.intl.messages.scenes;
  const history = useHistory();
  const enums = {
    type: ComonEnums.getEmailTypes(),
    sexe: ComonEnums.getGenderArray(),
  };

  useEffect(() => {
    if (loading) {
      Promise.all([VolunteerActioner.get(id), SubjectUtils.getSubjectTemplates()]).then(([docs, subjects]) => {
        setFields(docs);
        setSubjects(subjects);
        setLoading(false);
      });
    }
    return () => {};
  }, [loading, id]);

  function onEdit() {
    history.push(paths.front.volunteer.edit.replace(':id', id));
  }

  function onDelete() {
    setLoading(true);
    VolunteerActioner.delete(id)
      .then(() => {
        setLoading(false);
        history.push(paths.front.volunteer.home);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const generatePdf = () => {
    const element = document.getElementById('volunteer-profil');
    const options = {
      filename: `${fields.general_information.last_name} ${fields.general_information.first_name}.pdf`,
    };
    html2pdf(element, options);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      {loading ? (
        <Grid container item spacing={0} direction="column" alignItems="center" justifyContent="center">
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="width80">
          <>
            <h1>
              {intl.volunteer.profil.title.toUpperCase()}{' '}
              <IconButton onClick={generatePdf}>
                <SaveAs />
              </IconButton>
            </h1>
          </>
          <Grid container item spacing={1} xs={12} sm={12} id="volunteer-profil">
            <Grid item xs={12} sm={6}>
              <Box
                className="section full-width"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <div className="info-img"></div>
                <div className="info-resume">
                  <Typography className="title" variant="h4">
                    {fields.general_information.last_name} {fields.general_information.first_name}
                  </Typography>
                  <Typography className="subtitle" variant="h4">
                    {fields.general_information?.email?.email}
                    <br />
                    {fields.general_information.mobile}
                    <br />
                    <br />
                    {fields.other_intervention ? fields.other_intervention : ''}
                    <br />
                    <br />
                    {fields.general_information.address ? (
                      <div>
                        {fields.general_information.address.address_description}
                        <br />
                        {fields.general_information.address.city}
                        {'  '}
                        {fields.general_information.address.zip_code}
                      </div>
                    ) : null}
                  </Typography>
                  <div className="info-buttons">
                    <Button variant="contained" color="primary" startIcon={<Edit />} onClick={onEdit}>
                      {intl.submitButtons.edit}
                    </Button>{' '}
                    <ConfirmDialog
                      onConfirm={onDelete}
                      button={({ onClick }) => (
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<Delete />}
                          onClick={onClick}
                          disabled={fields.loading}
                        >
                          {intl.submitButtons.delete}
                        </Button>
                      )}
                    />
                  </div>
                </div>
              </Box>
              <ProfilSection items={fields.registration_information} thisIntl={intl.common.registration_information} />
              <ProfilSection
                items={Object.assign({}, { comment: fields.comment })}
                thisIntl={Object.assign({}, { labels: { comment: intl.volunteer.form.comment } })}
              />
              <ProfilSection items={fields.family_ressources} thisIntl={intl.common.family_ressources} />
              <ProfilSection items={fields.digital} thisIntl={intl.common.digital} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Availabilities data={fields.availabilities_information} disabled={fields.loading} />
              <SubjectForm subjects={subjects} data={fields.proposed_subject} disabled={fields.loading} />
              <ProfilSection
                items={fields.general_information}
                thisIntl={intl.common.general_information}
                enums={enums}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

const VolunteerProfil = injectIntl(VolunteerProfilComponent);

export { VolunteerProfil };
