import { AddCircle, Edit } from '@mui/icons-material';
import { Button, Dialog, IconButton } from '@mui/material';
import { useState } from 'react';
import { WorkshopManagmentForm } from './WorkshopManagmentForm';

const WorkshopManagementFormModal = ({
  index = undefined,
  entity,
  workshops,
  bigWorkshops,
  positionedWorkshops,
  updateActioner,
  mode = 'create',
  reload,
}) => {
  const [modalInformations, setModalInformations] = useState({ open: false, index: undefined });

  return (
    <>
      {mode === 'edit' ? (
        <IconButton
          onClick={() => {
            setModalInformations({ open: true, index });
          }}
        >
          <Edit size="small" />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircle />}
          onClick={() => {
            setModalInformations({ open: true, index: undefined });
          }}
        >
          Inscrire à un atelier
        </Button>
      )}
      <Dialog
        open={modalInformations.open}
        onClose={() => setModalInformations({ open: false, index: undefined })}
        fullWidth
        maxWidth="lg"
      >
        <WorkshopManagmentForm
          entity={entity}
          workshops={workshops}
          bigWorkshops={bigWorkshops}
          positionedWorkshops={positionedWorkshops}
          updateActioner={updateActioner}
          indexToEdit={modalInformations.index ? parseInt(modalInformations.index, 10) : undefined}
          onClose={() => {
            setModalInformations({ open: false, index: undefined });
            reload();
          }}
        />
      </Dialog>
    </>
  );
};

export { WorkshopManagementFormModal };
