import { Close, Delete, Edit, Update } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { CustomSelector } from 'components/CustomSelector';
import { DateInput } from 'components/DateInput';
import { Selector } from 'components/Selector';
import { TextInput } from 'components/TextInput';
import moment from 'moment';
import { useContext, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { TimelineActioner, TimelineContext, TimelineEnums } from 'services/timeline';
import { ValueUtils } from 'tools';
import { ItemAdjourn } from './component';

const vod = ValueUtils.valueOrDefault;

function StateSelector(props) {
  const { state, infos, setFieldFunction, types, disabled } = props;
  function OnChange(value) {
    setFieldFunction(Object.assign({}, { id: state?.id, state: value }));
  }
  return (
    <>
      <h4 className="marginT20">{`${infos?.general_information?.last_name} ${infos?.general_information?.first_name}`}</h4>
      <CustomSelector
        key={infos._id}
        labelId="type"
        label={`Status de ${infos?.general_information?.first_name}`}
        selected={state?.state}
        setSelected={value => OnChange(value)}
        resetIcon={<Close color="secondary" />}
        items={types}
        disabled={disabled}
      />
    </>
  );
}

function TimelineEditComponent(props) {
  const { setOpen, item } = props;
  const context = useContext(TimelineContext);

  const [fields, setFields] = useState(item);
  const [lock, setLock] = useState(false);
  const [adjourn, setAdjourn] = useState(false);

  const intl = props.intl.messages.scenes.timeline;
  const labels = intl.labels;

  const Celltitle = useMemo(
    () =>
      `${vod(item.matching?.volunteers[0]?.general_information?.last_name, '')} - ${fields.matching.students.map(
        e => ` ${e.general_information.last_name}`,
      )}`,
    [fields, item.matching?.volunteers],
  );

  const title = useMemo(
    () =>
      `${labels.course} : ${fields?.room?.name} le ${moment(fields.start_time)
        .format('dddd DD/MM/YYYY')
        .toUpperCase()}`,
    [fields, labels],
  );

  const volunteer_types = TimelineEnums.getVolunteerState();
  const students_types = TimelineEnums.getStudentsState();
  const types = TimelineEnums.getTypes();

  function editBddItem() {
    setLock(true);
    TimelineActioner.update(fields._id, fields).then(docs => {
      if (docs !== null) {
        context.editItem(fields);
        setLock(false);
        setOpen(false);
      }
    });
  }

  function onDelete() {
    setLock(true);
    TimelineActioner.delete(fields._id).then(docs => {
      if (docs !== null) {
        context.deleteItem(fields._id);
        setLock(false);
        setOpen(false);
      }
    });
  }

  function onDeleteAll() {
    setLock(true);
    TimelineActioner.deleteAll(fields.matching._id, moment(fields.start_time).toISOString()).then(docs => {
      if (!docs.some(d => d === null)) {
        docs.forEach(item => context.deleteItem(item));
        setLock(false);
        setOpen(false);
      }
    });
  }

  function setFieldFunction(name) {
    return value => {
      setFields(a => ({ ...a, [name]: value }));
    };
  }

  function setFieldWithIndexFunction(name, index) {
    return value => {
      if (Array.isArray(fields[name])) {
        const newArray = [...fields[name]];
        newArray[index] = value;
        setFields(a => ({ ...a, [name]: newArray }));
      }
    };
  }

  return (
    <Grid justifyContent="center" container>
      <Grid className="timeline-header marginB20" container>
        <Typography className="marginB20 marginT20" component="span" variant="h6">
          {intl.edit.toUpperCase()}
        </Typography>
        <Typography className="marginB20 come-in-top" component="span" variant="h3">
          {title}
        </Typography>
      </Grid>
      <Grid container item xs={6} sm={6} className="marginB20 come-in-top" spacing={1}>
        <Grid item xs={6} sm={6}>
          <DateInput
            id="start_time"
            label={labels.start_time}
            name="start_time"
            inputFormat="HH:mm"
            value={fields.start_time}
            setFieldFunction={setFieldFunction('start_time')}
            disabled={!fields.start_time}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <DateInput
            id="end_time"
            label={labels.end_time}
            name="end_time"
            inputFormat="HH:mm"
            value={fields.end_time}
            setFieldFunction={setFieldFunction('end_time')}
            disabled={!fields.end_time}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={12} className="padding-small marginB20 come-in-top" spacing={1}>
        <Grid item xs={6} sm={6}>
          <Typography component="span" variant="subtitle2">
            {labels.volunteer_state}
          </Typography>
          {fields.matching.volunteers[0] && (
            <StateSelector
              state={fields.volunteer_state}
              infos={fields.matching.volunteers[0]}
              setFieldFunction={setFieldFunction('volunteer_state')}
              types={volunteer_types}
              disabled={lock}
            />
          )}
        </Grid>
        <Grid item xs={6} sm={6}>
          <Typography component="span" variant="subtitle2">
            {labels.student_states}
          </Typography>
          {fields.student_states.map((elem, index) => (
            <StateSelector
              key={index}
              state={elem}
              infos={fields.matching.students[index]}
              setFieldFunction={setFieldWithIndexFunction('student_states', index)}
              types={students_types}
              disabled={lock}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container item xs={6} sm={6} className="padding-small marginB20 come-in-bottom">
        <Selector
          labelId="type"
          label={labels.type}
          selected={fields.type}
          setSelected={setFieldFunction('type')}
          items={types}
          disabled={lock}
        />
        <TextInput
          name="note"
          label={labels.note}
          value={fields.note}
          setField={setFieldFunction('note')}
          disabled={lock}
        />
      </Grid>
      <Grid className="marginB20" justifyContent="space-evenly" container>
        <Grid container item xs={6} sm={6} className="marginB20" justifyContent="space-evenly">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            onClick={() => {
              editBddItem();
            }}
            disabled={lock}
          >
            {intl.buttons.edit}
          </Button>
          {fields.courses_state !== 'UNIQUE' && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<Update />}
              onClick={() => {
                setAdjourn(true);
              }}
              disabled={lock}
            >
              {intl.buttons.date_change}
            </Button>
          )}
        </Grid>
        <Grid container item xs={6} sm={6} className="marginB20" justifyContent="space-around">
          <ConfirmDialog
            onConfirm={onDelete}
            button={({ onClick }) => (
              <Button variant="contained" color="secondary" startIcon={<Delete />} onClick={onClick} disabled={lock}>
                {intl.buttons.delete}
              </Button>
            )}
          >
            {intl.buttons.confirm_delete.replace(':titre', Celltitle)}
          </ConfirmDialog>
          <ConfirmDialog
            onConfirm={onDeleteAll}
            button={({ onClick }) => (
              <Button variant="contained" color="secondary" startIcon={<Delete />} onClick={onClick} disabled={lock}>
                {intl.buttons.delete_all}
              </Button>
            )}
          >
            {intl.buttons.confirm_delete_all.replace(':titre', Celltitle)}
          </ConfirmDialog>
        </Grid>
      </Grid>
      <ItemAdjourn openForm={adjourn} setOpenForm={setAdjourn} item={{ ...fields }} />
    </Grid>
  );
}

const TimelineEdit = injectIntl(TimelineEditComponent);

export { TimelineEdit };
