import { paths } from 'routes';
import { XHTTP } from 'services';

const END_POINTS = {
  ...paths.api.matching,
};

const MatchingApi = {
  getList: (campaign, site) => XHTTP(`${END_POINTS.list}?campaign=${campaign}&site=${site}`),

  getDoneList: (campaign, site) => XHTTP(`${END_POINTS.doneList}?campaign=${campaign}&site=${site}`),

  getStudentList: (campaign, site, id) => XHTTP(`${END_POINTS.studentList}${id}?campaign=${campaign}&site=${site}`),

  getVolunteerList: (campaign, site, id) => XHTTP(`${END_POINTS.volunteerList}${id}?campaign=${campaign}&site=${site}`),

  getSpecific: id => XHTTP(`${END_POINTS.get}${id}`),

  create: fields => XHTTP(END_POINTS.create, { method: 'post', body: fields }),

  update: (id, fields) => XHTTP(`${END_POINTS.edit}${id}`, { method: 'put', body: fields }),

  delete: id => XHTTP(`${END_POINTS.delete}${id}`, { method: 'delete' }),
};

export { MatchingApi };
