import { paths } from 'routes';
import { XHTTP } from 'services';

const END_POINTS = {
  ...paths.api.volunteer,
};

const VolunteerApi = {
  getList: (campaign, site) => {
    let query = `?`;
    const params = new URLSearchParams({ campaign, site });
    const keyToDelete = [];
    params.forEach((value, key) => {
      if (value === undefined || value === null || value === 'undefined' || value === 'null' || value === '') {
        keyToDelete.push(key);
      }
    });
    keyToDelete.forEach(key => params.delete(key));
    query = `${query}${params.toString()}`;
    return XHTTP(`${END_POINTS.list}${query}`);
  },

  getSpecific: id => XHTTP(`${END_POINTS.get}${id}`),

  create: fields => XHTTP(END_POINTS.create, { method: 'post', body: fields }),

  update: (id, fields) => XHTTP(`${END_POINTS.edit}${id}`, { method: 'put', body: fields }),

  delete: id => XHTTP(`${END_POINTS.delete}${id}`, { method: 'delete' }),
};

export { VolunteerApi };
