import { Grid } from '@mui/material';
import { Selector } from 'components/Selector';
import { TextInput } from 'components/TextInput';
import { injectIntl } from 'react-intl';
import { ComonEnums } from 'services/comon';
import { ValueUtils } from 'tools';
import './EmailForm.css';

const vod = ValueUtils.valueOrDefault;

function EmailFormComponent(props) {
  const { data, setData } = props;
  const intl = props.intl.messages.scenes.common.general_information;
  const fields = {
    type: vod(data.type, ''),
    email: vod(data.email, ''),
  };

  // General
  const types = ComonEnums.getEmailTypes();

  function setFieldFunction(field) {
    return value => {
      const newEmail = { ...fields };
      newEmail[field] = value;
      setData(newEmail);
    };
  }

  return (
    <Grid container item xs={12} sm={12}>
      <Grid item xs={3} sm={3}>
        <Selector
          labelId="type"
          label={intl.labels.type}
          selected={fields.type}
          setSelected={setFieldFunction('type')}
          items={types}
          disabled={fields.loading}
        />
      </Grid>
      <Grid item xs={9} sm={9}>
        <TextInput
          className="margin10"
          name="email"
          label={intl.labels.email}
          value={fields.email}
          setField={setFieldFunction('email')}
          disabled={fields.loading}
          required={false}
        />
      </Grid>
    </Grid>
  );
}

const EmailForm = injectIntl(EmailFormComponent);

export { EmailForm };
