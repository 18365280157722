import { useCallback } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { injectIntl } from 'react-intl';

function GroupListDragComponent(props) {
  const { key, children, tabs, setTabs, disabled } = props;

  const onDragEnd = useCallback(
    result => {
      if (result.reason === 'DROP') {
        if (!result.destination) {
          return;
        }
        if (result.source.droppableId !== result.destination.droppableId) {
          let tabFrom = [...tabs[result.source.droppableId]];
          let tabTo = [...tabs[result.destination.droppableId]];
          const [removed] = [...tabFrom.splice(result.source.index, 1)];
          tabTo.splice(result.destination.index, 0, removed);
          setTabs({ ...tabs, [result.source.droppableId]: tabFrom, [result.destination.droppableId]: tabTo });
        } else {
          let tabTo = [...tabs[result.destination.droppableId]];
          const [removed] = [...tabTo.splice(result.source.index, 1)];
          tabTo.splice(result.destination.index, 0, removed);
          setTabs({ ...tabs, [result.destination.droppableId]: tabTo });
        }
      }
    },
    [tabs, setTabs],
  );

  return (
    <DragDropContext key={key} onDragEnd={!disabled ? onDragEnd : () => {}}>
      {children}
    </DragDropContext>
  );
}

const GroupListDrag = injectIntl(GroupListDragComponent);

export { GroupListDrag };
