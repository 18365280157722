import { Box } from '@mui/material';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { TimelineEnums } from 'services/timeline';

function TimelineProfilCardComponent(props) {
  const { item } = props;
  function isPast(date) {
    return moment(date).diff(moment()) < 0;
  }

  const volunteer_state = TimelineEnums.getVolunteerState();
  const student_state = TimelineEnums.getStudentsState();
  const types = TimelineEnums.getTypes();

  return (
    <div key={item?._id} className="margin20 course-bg">
      <Box
        className={`padding-normal fullwidth course-bg-header ${
          isPast(item.start_time)
            ? isPast(item.end_time)
              ? 'bg-grey-color'
              : 'bg-primary-color'
            : 'bg-secondary-color'
        }`}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <h4>{types.find(type => type.value === item?.type)?.label ?? ''}</h4>
        <h4>
          {item.courses_state === 'UNIQUE' ? '[0]  ' : ''}
          {isPast(item.start_time) ? (isPast(item.end_time) ? 'FINI' : 'EN COURS..') : 'PREVU'}
        </h4>
        <h4>{item?.room?.name}</h4>
      </Box>
      <div className="padding-normal">
        <h3>
          {moment(item.start_time).format('dddd DD / MM / YYYY')?.toUpperCase()}:{' '}
          {moment(item.start_time).format('HH:mm')} - {moment(item.end_time).format('HH:mm')}
        </h3>
        <Box className="marginT20 padding-small fullwidth" display="flex" justifyContent="space-around">
          <span className="text-centered">
            <h4>Bénévole</h4>
            <h6 className="margin10">
              {volunteer_state.find(vs => vs.value === item?.volunteer_state?.state)?.label ?? 'non renseigné'}
            </h6>
          </span>
          <span className="text-centered">
            <h4>Apprenants</h4>
            {item?.student_states.map(st => (
              <h6 key={st._id} className="margin10">
                {student_state.find(ss => ss.value === st.state)?.label ?? 'non renseigné'}
              </h6>
            ))}
          </span>
        </Box>
      </div>
    </div>
  );
}

const TimelineProfilCard = injectIntl(TimelineProfilCardComponent);

export { TimelineProfilCard };
