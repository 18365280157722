import { Grid, Paper, Typography } from '@mui/material';
import { CheckboxField } from 'components/CheckboxField';
import { TextInput } from 'components/TextInput';
import { injectIntl } from 'react-intl';
import { ValueUtils } from 'tools';

const vod = ValueUtils.valueOrDefault;

function DigitalFormComponent(props) {
  const { data, setData } = props;
  const fields = {
    as_equipment: vod(data.as_equipment, false),
    as_skills: vod(data.as_skills, false),
    comment: vod(data.comment, ''),
  };

  const intl = props.intl.messages.scenes.common.digital;

  function setFieldFunction(field) {
    return value => {
      const newDigital = { ...fields };
      newDigital[field] = value;
      setData(newDigital);
    };
  }

  return (
    <Paper className="padding-small full-width marginB20">
      <Typography variant="h5" gutterBottom>
        {intl.title}
      </Typography>
      <Grid container item xs={12} sm={12}>
        <Grid container item xs={6} sm={6}>
          <CheckboxField
            label={intl.labels.as_equipment}
            checked={fields.as_equipment ?? false}
            setField={setFieldFunction('as_equipment')}
          />
        </Grid>
        <Grid container item xs={6} sm={6}>
          <CheckboxField
            label={intl.labels.as_skills}
            checked={fields.as_skills ?? false}
            setField={setFieldFunction('as_skills')}
          />
        </Grid>
        <Grid container item xs={12} sm={12}>
          <TextInput
            name="comment"
            label={intl.labels.comment}
            value={fields.comment}
            setField={setFieldFunction('comment')}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

const DigitalForm = injectIntl(DigitalFormComponent);

export { DigitalForm };
