import { paths } from 'routes';
import { AuthenticatedRoute } from 'routes/components';
import { SiteCreate, SiteEdit, SiteList } from 'scenes';

const siteRoutes = [
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.site.home,
      exact: true,
      component: SiteList,
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.site.create,
      exact: true,
      component: SiteCreate,
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.site.edit,
      exact: true,
      component: SiteEdit,
    },
  },
];

export { siteRoutes };
