const MatchingEnums = {
  getStatus: isLocked => {
    let arr;
    if (!isLocked) {
      arr = [
        {
          label: 'Brouillon',
          value: 'DRAFT',
          color: 'red',
        },
        {
          label: 'Validé',
          value: 'VALIDED',
          color: 'orange',
        },
      ];
    } else if (isLocked) {
      arr = [
        {
          label: 'Positioné',
          value: 'POSITIONED',
          color: 'blue',
        },
        {
          label: 'Verrouillé',
          value: 'LOCK',
          color: 'green',
        },
        {
          label: 'Arrêté',
          value: 'CLOSED',
          color: 'red',
        },
      ];
    }
    return arr;
  },
  getStatusColor: () => [
    {
      label: 'Brouillon',
      value: 'DRAFT',
      color: 'darkgrey',
    },
    {
      label: 'Validé',
      value: 'VALIDED',
      color: 'orange',
    },
    {
      label: 'Positioné',
      value: 'POSITIONED',
      color: 'blue',
    },
    {
      label: 'Verrouillé',
      value: 'LOCK',
      color: 'green',
    },
    {
      label: 'Arrêté',
      value: 'CLOSED',
      color: 'red',
    },
  ],
  getTypes: () => {
    return { VOLUNTEER: 'Volunteer', STUDENT: 'Student' };
  },
};

export { MatchingEnums };
