import { Grid, Paper, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { CheckboxField } from 'components/CheckboxField';
import { Selector } from 'components/Selector';
import { TextInput } from 'components/TextInput';
import { injectIntl } from 'react-intl';
import { ComonEnums } from 'services/comon';
import { ArrayUtils, ValueUtils } from 'tools';

const vod = ValueUtils.valueOrDefault;

function RegisterFormComponent(props) {
  const { data, setData, campaigns, disabled, errors, root, setRoot, sites } = props;
  const fields = {
    date: vod(data.date, null),
    number: vod(data.number, 0),
    fresh: vod(data.fresh, false),
    first_date: vod(data.first_date, null),
    know_lacle: vod(data.know_lacle, ''),
    other_known: vod(data.other_known, ''),
  };

  function setFieldFunction(field) {
    return value => {
      const newGeneralInformation = ArrayUtils.copyJsonObjectArray(fields);
      newGeneralInformation[field] = value;
      setData(newGeneralInformation);
    };
  }

  const intl = props.intl.messages.scenes.common.registration_information;

  // General
  const how_knows = ComonEnums.getKnowLacleArray();

  return (
    <Paper className="padding-small full-width marginB20">
      <Typography variant="h5" gutterBottom>
        {intl.title}
      </Typography>
      <Grid container item xs={12} sm={12}>
        <Grid item xs={12} sm={12}>
          <Selector
            labelId="campaign"
            label={intl.labels.campaign}
            selected={root.campaign}
            setSelected={setRoot('campaign')}
            items={campaigns}
            error={errors.campaign}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Selector
            labelId="site"
            label="Etablissement"
            selected={root.site}
            setSelected={setRoot('site')}
            items={sites}
            error={errors.site}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CheckboxField
            label={intl.labels.fresh}
            checked={fields.fresh ? fields.fresh : false}
            setField={setFieldFunction('fresh')}
            disabled={disabled}
          />
          <CheckboxField label="Brouillon" checked={root.draft} setField={setRoot('draft')} disabled={disabled} />
          <CheckboxField label="Externe" checked={root.external} setField={setRoot('external')} disabled={disabled} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DatePicker
            renderInput={props => <TextField fullWidth style={{ marginTop: '3%' }} {...props} />}
            margin="normal"
            id="date"
            label={intl.labels.date}
            inputFormat="DD/MM/YYYY"
            name="date"
            value={fields.date}
            onChange={setFieldFunction('date')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            inputVariant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DatePicker
            renderInput={props => <TextField fullWidth style={{ marginTop: '3%' }} {...props} />}
            margin="normal"
            id="first_date"
            label={intl.labels.first_date}
            inputFormat="DD/MM/YYYY"
            name="first_date"
            value={fields.first_date}
            onChange={setFieldFunction('first_date')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            inputVariant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Selector
            labelId="know_lacle"
            label={intl.labels.know_lacle}
            selected={fields.know_lacle}
            setSelected={setFieldFunction('know_lacle')}
            items={how_knows}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            name="other_known"
            label={intl.labels.other_known}
            value={fields.other_known}
            setField={setFieldFunction('other_known')}
            disabled={disabled}
            required={false}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

const RegisterForm = injectIntl(RegisterFormComponent);

export { RegisterForm };
