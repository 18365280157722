import { createContext, useContext } from 'react';
import { useConfig } from './useConfig';

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const configState = useConfig();

  return <ConfigContext.Provider value={configState}>{children}</ConfigContext.Provider>;
};

export const useConfigContext = () => useContext(ConfigContext);
