import { Box, Button, Card, Typography } from '@mui/material';
import { CheckboxField } from 'components/CheckboxField';
import { Selector } from 'components/Selector';
import { useConfigContext } from 'hooks';
import { useCallback, useState } from 'react';
import { StatsTimelineActioner } from 'services';
import './GeneralStats.css';

const DisplaySection = ({ data, title }) => {
  return (
    <>
      <Card className="stats-general-card">
        <Typography className="stats-general-title" variant="h2">
          {' '}
          {title}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          <section>
            <Typography className="stats-general-title" variant="h3">
              Statistique
            </Typography>
            {Object.keys(data.result).map(type => {
              return typeof data.result[type] !== 'object' ? (
                <Typography key={type}>
                  {' '}
                  {type} : {data.result[type]}{' '}
                </Typography>
              ) : (
                <>
                  <Typography key={type}> {type} :</Typography>
                  {Object.keys(data.result[type]).map((subresult, i) => {
                    return (
                      <Typography className="stats-general-subtitle" key={`${type}_${i}`}>
                        {' '}
                        {subresult} : {data.result[type][subresult]}
                      </Typography>
                    );
                  })}
                </>
              );
            })}
          </section>
          <section>
            <Typography className="stats-general-title" variant="h3">
              Cibles
            </Typography>
            <Box className="stats-general-target">
              {data?.target.map((st, i) => {
                if (typeof st === 'object') {
                  return (
                    <Typography key={i}>
                      {' '}
                      {st?.general_information?.last_name} {st?.general_information?.first_name}{' '}
                    </Typography>
                  );
                }
                return <Typography key={st}>{st}</Typography>;
              })}
            </Box>
          </section>
        </Box>
      </Card>
    </>
  );
};

const StatsGeneral = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [target, setTarget] = useState('asl');
  const today = new Date().getFullYear();
  const [annee, setAnnee] = useState(today);
  const [targetYear, setTargetYear] = useState(false);
  const { campaign, site } = useConfigContext();
  const allYear = [
    { label: today - 3, value: today - 3 },
    { label: today - 2, value: today - 2 },
    { label: today - 1, value: today - 1 },
    { label: today, value: today },
    { label: today + 1, value: today + 1 },
  ];
  const search = useCallback(async () => {
    setLoading(true);
    const receviedData = await StatsTimelineActioner.getGeneral({
      dataType: target,
      annee: annee,
      campagne: campaign,
      site: site,
      onlyYear: targetYear,
    });
    setData(receviedData);
    setLoading(false);
  }, [target, campaign, annee, targetYear, site]);

  const handleSubmit = () => {
    search();
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Selector
          className="stats-general-selector"
          label="Choix"
          labelId="target"
          selected={target}
          setSelected={setTarget}
          items={[
            { label: 'Ville Senior', value: 'villeLilleSenior' },
            { label: 'Ville Handicap', value: 'villeLilleHardi' },
            { label: 'MSB', value: 'msb' },
            { label: 'ASL', value: 'asl' },
            { label: 'Médiation', value: 'mediation' },
            { label: 'Parents', value: 'parent' },
          ]}
        />
        <Selector
          className="stats-general-selector"
          label="Annee"
          selected={annee}
          setSelected={setAnnee}
          items={allYear}
        />
        <Button className="stats-general-button" variant="contained" onClick={handleSubmit}>
          Load
        </Button>
        <CheckboxField label="Utiliser uniquement l'année" checked={targetYear} setField={setTargetYear} />
      </Box>
      {loading ? (
        'loading...'
      ) : data?.result ? (
        <DisplaySection data={data}></DisplaySection>
      ) : (
        Object.keys(data).map(file => {
          return <DisplaySection title={file} data={data[file]}></DisplaySection>;
        })
      )}
    </>
  );
};

export { StatsGeneral };
