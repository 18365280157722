import { AddCircle, Edit } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Selector } from 'components/Selector';
import moment from 'moment';
import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { ComonEnums } from 'services/comon';
import { ValueUtils } from 'tools';
import { ReminderForm } from './components';
import './WorkshopManagment.css';

const vod = ValueUtils.valueOrDefault;

function getInitialValues(values = {}) {
  return {
    positioned_workshop: vod(values.positioned_workshop, ''),
    status: vod(values.status, ''),
    date: vod(values.date, ''),
    reminders: vod(values.reminders, []),
  };
}

function WorkshopManagmentFormComponent({
  entity,
  indexToEdit = undefined,
  positionedWorkshops,
  workshops,
  bigWorkshops,
  updateActioner,
  onClose,
  ...props
}) {
  const [entityFields] = useState({ ...entity });
  const initialValues = getInitialValues(entityFields?.workshop?.workshop_managments[indexToEdit] || {});
  const [fields, setFields] = useState({
    ...initialValues,
    errors: {
      positioned_workshop: false,
    },
  });
  const intl = props.intl.messages.scenes.positionedWorkshop.managment_form;

  function setFieldFunction(name) {
    return value => {
      setFields(f => ({ ...f, [name]: value, errors: { ...f.errors, [name]: false } }));
    };
  }

  function setError(name) {
    setFields(f => ({ ...f, errors: { ...f.errors, [name]: true } }));
  }
  function addNewPositionedWorkshop() {
    let newWorkshopManagments = [...entity.workshop.workshop_managments];
    if (newWorkshopManagments === undefined) {
      newWorkshopManagments = [];
    }
    if (fields.positioned_workshop === '') {
      setError('positioned_workshop');
      return;
    }
    const newreminders = [];
    const newDate = new Date();
    newreminders.push({ date: newDate, status: ComonEnums.getDefaultStatus() });
    newWorkshopManagments.push({
      positioned_workshop: fields.positioned_workshop,
      status: ComonEnums.getDefaultStatus(),
      reminders: newreminders,
    });
    const newEndtity = {
      ...entityFields,
      workshop: { ...entityFields.workshop, workshop_managments: [...newWorkshopManagments] },
    };
    updateActioner(newEndtity._id, newEndtity).then(() => onClose());
  }

  function editPositionedWorkshop() {
    let newWorkshopManagments = [...entity.workshop.workshop_managments];
    if (fields.status !== initialValues.status) {
      newWorkshopManagments[indexToEdit].status = fields.status;
    }
    if (fields.reminders !== initialValues.reminders) {
      newWorkshopManagments[indexToEdit].reminders = fields.reminders;
    }
    if (fields.date !== initialValues.date) {
      newWorkshopManagments[indexToEdit].date = fields.date;
    }
    const newEndtity = {
      ...entityFields,
      workshop: { ...entityFields.workshop, workshop_managments: [...newWorkshopManagments] },
    };
    updateActioner(newEndtity._id, newEndtity).then(() => onClose());
  }

  const allowedWorkshops = useMemo(
    () =>
      R.flatten(
        bigWorkshops?.filter(b => entityFields.workshop.workshops.includes(b._id))?.map(bw => bw.workshop) ?? [],
      ),
    [entityFields.workshop.workshops, bigWorkshops],
  );

  const allowedPositionedWorkshops = useMemo(
    () =>
      positionedWorkshops.filter(
        p =>
          allowedWorkshops.includes(p.workshop) &&
          entityFields.workshop.workshop_managments.find(wm => wm.positioned_workshop === p._id) === undefined,
      ),
    [entityFields.workshop.workshop_managments, allowedWorkshops, positionedWorkshops],
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="full-width">
      <Grid container className="padding-small">
        {indexToEdit === undefined && (
          <>
            <Grid item xs={12} sm={12}>
              <Selector
                labelId="positioned_workshops"
                label={'Sur quel atelier'}
                selected={fields.positioned_workshop}
                setSelected={setFieldFunction('positioned_workshop')}
                items={allowedPositionedWorkshops.map(p => ({
                  label: `[${bigWorkshops.find(bw => bw.workshop.includes(p.workshop))?.name ?? 'Inconnu'}] ${
                    workshops.find(w => w._id === p.workshop).name
                  } le ${moment(p.positioned_date).format('DD/MM/YYYY HH:mm')}`,
                  value: p._id,
                }))}
                error={fields.errors.positioned_workshop}
              />
            </Grid>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircle />}
                onClick={() => addNewPositionedWorkshop()}
              >
                {intl.save}
              </Button>
            </Grid>
          </>
        )}
        {indexToEdit !== undefined && (
          <>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-around"
              className="full-width popup"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                className="full-width"
              >
                <Typography variant="h5">
                  {entity.general_information.last_name} {entity.general_information.first_name}
                </Typography>
                <Typography variant="h6">{fields.status}</Typography>
              </Box>
              <ReminderForm
                setData={setFieldFunction('reminders')}
                data={fields.reminders}
                setGeneralStatus={setFieldFunction('status')}
                generalStatus={fields.status}
                disabled={fields.loading}
              />
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Edit />}
                  onClick={() => editPositionedWorkshop()}
                >
                  {intl.save}
                </Button>
              </Grid>
            </Box>
          </>
        )}
      </Grid>
    </Box>
  );
}

const WorkshopManagmentForm = injectIntl(WorkshopManagmentFormComponent);

export { WorkshopManagmentForm };
