import { FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import './CustomSelector.css';

function CustomSelector(props) {
  const {
    label,
    labelId,
    selected,
    setSelected,
    items,
    itemRender,
    error,
    disabled,
    variant,
    className,
    labelClassName,
    inputClassName,
    resetIcon,
    resetValue,
  } = props;

  function onStatusChange(event) {
    const value = event.target.value;
    if (selected !== value) {
      setSelected(value);
    }
  }

  const itemsWithProps = items.map((item, index) => {
    if (itemRender !== undefined && React.isValidElement(itemRender)) {
      return (
        <MenuItem value={item?.value} key={index}>
          {React.cloneElement(itemRender, { item: item, index: index })}
        </MenuItem>
      );
    }
    return (
      <MenuItem value={item?.value} key={index}>
        <span className="selector-item-label">{item?.label}</span>
      </MenuItem>
    );
  });

  return (
    <FormControl className={`selector-container position-relative ${className}`}>
      {labelId && label && (
        <InputLabel error={error} id={labelId} className={`selector-input-label ${labelClassName}`} variant={variant}>
          {label}
        </InputLabel>
      )}
      {resetIcon && selected !== resetValue && (
        <IconButton size="small" className="custom-selector-reset-button" onClick={() => setSelected(resetValue)}>
          {resetIcon}
        </IconButton>
      )}
      <Select
        variant={variant}
        labelId={labelId}
        value={selected}
        onChange={onStatusChange}
        disabled={disabled}
        className={inputClassName}
      >
        {itemsWithProps}
      </Select>
    </FormControl>
  );
}

CustomSelector.propTypes = {
  labelId: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  items: PropTypes.array,
  itemRender: PropTypes.element,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};

CustomSelector.defaultProps = {
  labelId: '',
  label: '',
  selected: '',
  setSelected: () => {},
  items: [],
  itemRender: undefined,
  resetIcon: undefined,
  resetValue: '',
  error: false,
  disabled: false,
  variant: 'outlined',
  className: '',
  labelClassName: '',
  inputClassName: '',
};

export { CustomSelector };
