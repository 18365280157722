import { CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { EnhancedTable } from 'components/EnhancedTable';
import { useConfigContext } from 'hooks';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { UserActioner } from 'services';
import { BigWorkshopActioner } from 'services/bigWorkshop';
import { ComonEnums } from 'services/comon';
import { PositionedWorkshopActioner } from 'services/positionedWorkshop';
import { WorkshopActioner } from 'services/workshop';
import { WorkshopManagementFormModal } from './WorkshopManagementFormModal';
import './WorkshopManagment.css';

function WorkshopManagmentComponent({ entityId, getActioner, updateActioner, ...props }) {
  const [entity, setEntity] = useState({});
  const [workshops, setWorkshops] = useState([]);
  const [positionedWorkshop, setPositionedWorkshop] = useState([]);
  const [bigWorkshops, setBigWorkshops] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { campaign, site, config } = useConfigContext();

  const status = ComonEnums.getGeneralStatus();

  useEffect(() => {
    if (loading) {
      Promise.all([
        getActioner(entityId),
        WorkshopActioner.list(),
        PositionedWorkshopActioner.list(campaign, site),
        UserActioner.getUserList(),
        BigWorkshopActioner.list(),
      ]).then(([e, w, p, u, b]) => {
        setEntity(e);
        setWorkshops(w);
        setPositionedWorkshop(p);
        setUsers(u);
        setBigWorkshops(b);
        setLoading(false);
      });
    }
    return () => {};
  }, [loading, entityId, getActioner, campaign, site]);

  useEffect(() => {
    if (config) {
      setLoading(true);
    }
  }, [config]);

  const columns = useMemo(
    () => [
      {
        Header: 'Atelier',
        accessor: 'positioned_workshop',
        Cell: ({ value }) =>
          positionedWorkshop.length > 0
            ? workshops?.find(w => positionedWorkshop?.find(p => p._id === value)?.workshop === w._id)?.name ??
              'Inconnu'
            : '',
      },
      {
        Header: 'Date',
        accessor: 'positioned_date',
        Cell: ({ cell }) =>
          positionedWorkshop.length > 0
            ? moment(
                positionedWorkshop.find(p => p._id === cell.row.original.positioned_workshop)?.positioned_date,
              ).format('DD/MM/YYYY HH:mm')
            : '',
      },
      {
        Header: 'Animateur',
        accessor: 'animator',
        Cell: ({ cell }) =>
          positionedWorkshop.length > 0 && users.length > 0
            ? users.find(
                u => positionedWorkshop.find(p => p._id === cell.row.original.positioned_workshop)?.animator === u._id,
              )?.firstname ?? 'Inconnu'
            : '',
      },
      {
        Header: 'Statut',
        accessor: 'status',
        Cell: ({ cell, value }) => (value ? status.find(s => s.value === value)?.label ?? 'Not Found' : 'Not Found'),
      },
      {
        Header: 'Actions',
        accessor: '_id',
        Cell: ({ cell }) => {
          return (
            <WorkshopManagementFormModal
              mode="edit"
              entity={entity}
              workshops={workshops}
              bigWorkshops={bigWorkshops}
              positionedWorkshops={positionedWorkshop}
              updateActioner={updateActioner}
              index={cell.row.id}
              reload={() => setLoading(true)}
            />
          );
        },
      },
    ],
    [positionedWorkshop, workshops, users, status, entity, bigWorkshops, updateActioner],
  );

  const actions = [
    {
      render: () => {
        return (
          <WorkshopManagementFormModal
            mode="create"
            entity={entity}
            workshops={workshops}
            bigWorkshops={bigWorkshops}
            positionedWorkshops={positionedWorkshop}
            updateActioner={updateActioner}
            reload={() => setLoading(true)}
          />
        );
      },
      isFreeAction: true,
    },
  ];

  const data = useMemo(() => {
    return (
      entity.workshop?.workshop_managments?.filter(ep => {
        return (
          workshops?.find(w => positionedWorkshop?.find(p => p._id === ep.positioned_workshop)?.workshop === w._id) !==
          undefined
        );
      }) ?? []
    );
  }, [entity.workshop?.workshop_managments, positionedWorkshop, workshops]);

  return (
    <>
      {loading ? (
        <Grid container item spacing={0} direction="column" alignItems="center" justifyContent="center">
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <>
          <Paper className="workshop-available">
            <Typography variant="h6">Thèmes d'Ateliers Demandés</Typography>
            <div className="workshop-available-content">
              {entity?.workshop?.workshops?.map((w, index) => {
                const wk = bigWorkshops.find(wf => wf._id === w);
                return wk ? (
                  <span className="workshop-available-bubble" key={index}>
                    {wk.name}
                  </span>
                ) : (
                  <></>
                );
              })}
              {entity?.workshop?.workshops?.length === 0 ? 'Aucun' : ''}
            </div>
          </Paper>
          <Paper>
            <EnhancedTable
              title={'Ateliers Incris'}
              columns={columns}
              data={data}
              actions={actions}
              useMultipleSelect={false}
            />
            {/* <Dialog
              open={modalInformations.open}
              onClose={() => setModalInformations({ open: false, index: undefined })}
              fullWidth
              maxWidth="lg"
            >
              <WorkshopManagmentForm
                entity={entity}
                workshops={workshops}
                bigWorkshops={bigWorkshops}
                positionedWorkshops={positionedWorkshop}
                updateActioner={updateActioner}
                indexToEdit={modalInformations.index ? parseInt(modalInformations.index, 10) : undefined}
                onClose={() => {
                  setModalInformations({ open: false, index: undefined });
                  setLoading(true);
                }}
              />
            </Dialog> */}
          </Paper>
        </>
      )}
    </>
  );
}

const WorkshopManagment = injectIntl(WorkshopManagmentComponent);

export { WorkshopManagment };
