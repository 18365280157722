import { Paper } from '@mui/material';
import { Loader } from 'components';
import { useConfigContext } from 'hooks';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { MatchingActioner, MatchingUtils } from 'services/matching';
import { MatchingForm } from './MatchingForm';

function MatchingEditComponent(props) {
  const [, reload] = useState();
  const { campaign, site } = useConfigContext();
  const id = props.match.params.id;
  const intl = props.intl.messages.scenes.matching.edit;

  function editForm(templates, match) {
    return (
      <MatchingForm
        intlData={props.intl}
        values={match}
        templates={templates}
        reload={() => reload({})}
        mode={'edit'}
      />
    );
  }

  function loadInfos() {
    return MatchingActioner.get(id)
      .then(infos => {
        const values = { ...infos, id };
        return values;
      })
      .catch(err => {
        throw err;
      });
  }

  function renderEditForm(render) {
    Promise.all([MatchingUtils.getMatchingTemplates(campaign, site), loadInfos()]).then(([templates, match]) => {
      render(editForm(templates, match));
    });
  }

  return (
    <Paper className="padding-small">
      <h2 className="text-centered">{intl.title}</h2>
      <Loader render={renderEditForm} />
    </Paper>
  );
}

const MatchingEdit = injectIntl(MatchingEditComponent);

export { MatchingEdit };
