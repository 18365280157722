import { Loader } from 'components';
import { MatchingComonProfil } from 'components/ComonProfil';
import { useConfigContext } from 'hooks';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { MatchingActioner } from 'services/matching';
import { TimelineActioner } from 'services/timeline';

function StudentProfilMatchingComponent(props) {
  const [, reload] = useState();
  const { campaign, site } = useConfigContext();
  const { id } = props;

  function renderPage(matchs, timelines) {
    return <MatchingComonProfil matchs={matchs} timelines={timelines} reload={() => reload({})} />;
  }

  function render(render) {
    Promise.all([MatchingActioner.studentList(campaign, site, id), TimelineActioner.studentList(id)]).then(
      ([matchs, timelines]) => {
        render(renderPage(matchs, timelines));
      },
    );
  }

  return <Loader render={render} />;
}

const StudentProfilMatching = injectIntl(StudentProfilMatchingComponent);

export { StudentProfilMatching };
