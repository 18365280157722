import { Box, Grid } from '@mui/material';
import moment from 'moment';
import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { MatchingEnums } from 'services/matching';
import { ItemMatchingMenu } from './ItemMatchingMenu';
import './MatchingComonProfil.css';
import { TimelineProfilCard } from './TimelineProfilCard';

function MatchingMenu(props) {
  const { match, onClick, activeMatch } = props;
  return (
    <div
      className={`profil-matching-menu ${match._id === activeMatch?._id ? 'profil-matching-menu-active' : ''}`}
      onClick={onClick}
    >
      <ItemMatchingMenu item={match} />
    </div>
  );
}

function MatchingComonProfilComponent(props) {
  const { matchs, timelines } = props;
  const [activeMatch, setActiveMatch] = useState(matchs[0]);

  const all_status = MatchingEnums.getStatus(true);

  const updatedTimelines = useMemo(() => {
    const filtered_timeline = timelines.filter(t => t.matching?._id.toString() === activeMatch?._id?.toString());
    return R.sort((a, b) => moment(b.start_time).diff(a.start_time), filtered_timeline);
  }, [activeMatch, timelines]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Grid container item xs={12} sm={12} className="padding-small">
        <Grid className="marginT20" item xs={6} sm={6}>
          {matchs.map(m => (
            <MatchingMenu match={m} activeMatch={activeMatch} onClick={() => setActiveMatch(m)} />
          ))}
        </Grid>
        <Grid className="profil-matching-items" item xs={6} sm={6}>
          {updatedTimelines.length < 1 ? (
            <div className="text-centered padding-normal">
              <h2>Ce match est au statut : {all_status.find(s => s.value === activeMatch?.status)?.label}</h2>
              <h4>Il n'a donc pas de cours pour le moment</h4>
            </div>
          ) : (
            ''
          )}
          {updatedTimelines.map(t => (
            <TimelineProfilCard item={t} />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

const MatchingComonProfil = injectIntl(MatchingComonProfilComponent);

export { MatchingComonProfil };
