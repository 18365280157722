import { Paper } from '@mui/material';
import { injectIntl } from 'react-intl';
import { ThemeSubjectForm } from './ThemeSubjectForm';

function ThemeSubjectCreateComponent({ intl }) {
  return (
    <Paper className="padding-small">
      <h2 className="text-centered">{intl.messages.scenes.subject.theme.create.title}</h2>
      <ThemeSubjectForm mode={'create'} />
    </Paper>
  );
}

const ThemeSubjectCreate = injectIntl(ThemeSubjectCreateComponent);

export { ThemeSubjectCreate };
