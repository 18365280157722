import { CheckBox } from '@mui/icons-material';
import { Typography } from '@mui/material';
import moment from 'moment';
import './style/Profil.css';

function ProfilSection(props) {
  const { items, enums, thisIntl } = props;
  const intl = thisIntl;

  function checkSectionPrint(items) {
    if (items !== undefined && items !== null) {
      const allSet = Object.keys(items).map(item => {
        if (items[item] !== undefined && items[item] !== null) {
          return typeof items[item] === 'boolean'
            ? items[item]
            : typeof items[item] === 'string'
            ? items[item] && items[item] !== ''
            : typeof items[item] === 'object'
            ? Object.keys(items[item]).length > 0
            : false;
        }
        return false;
      });
      return Object.keys(items).length > 0 && items && allSet.includes(true);
    }
    return false;
  }

  return checkSectionPrint(items) ? (
    <Typography component={'div'} variant="h5" className="section">
      <div className="title-div">
        <Typography className="title-info" variant="h5" color="primary">
          {intl.title}
        </Typography>
      </div>
      <ul>{Object.keys(items).map(item => getSection(item, items[item], intl, enums))}</ul>
    </Typography>
  ) : (
    <div></div>
  );
}

function getSection(name, item, intl, enums) {
  let type = typeof item;
  if (enums) {
    if (enums[name]) {
      const newItem = enums[name].find(e => e.value === item);
      item = newItem ? newItem.label : '';
    }
  }
  if (Array.isArray(item)) {
    type = 'array';
  }
  if (name.includes('date') || name.includes('data')) {
    type = 'date';
  }
  switch (type) {
    case 'number':
      return <TextSection key={name} name={intl.labels[name]} value={item} />;
    case 'string':
      return <TextSection key={name} name={intl.labels[name]} value={item} />;
    case 'date':
      return <TextSection key={name} name={intl.labels[name]} value={moment(item).format('DD-MM-YYYY')} />;
    case 'boolean':
      return <BooleanSection key={name} name={intl.labels[name]} value={item} />;
    case 'array':
      return <ArraySection key={name} name={intl.labels[name]} value={item} />;
    case 'object':
      return <ObjectSection key={name} name={intl.labels[name]} intl={intl} value={item} enums={enums} />;
    default:
      return <div></div>;
  }
}

function TextSection(props) {
  const { name, value } = props;
  return value !== '' && value !== ' ' && value && value !== 'Invalid date' ? (
    <li key={name} className="line">
      <span className="label">{name}</span>
      <span className="value">{value}</span>
    </li>
  ) : (
    <></>
  );
}

function BooleanSection(props) {
  const { name, value } = props;
  return value ? (
    <li key={name} className="booline">
      <span className="boolean">
        <CheckBox color="primary" />
        <span>{name}</span>
      </span>
    </li>
  ) : (
    <></>
  );
}

function ArraySection(props) {
  const { name, value } = props;
  return value.length > 0 ? (
    <li className="arrayline" key={name}>
      <span className="label">{name}</span>
      <div className="value">
        {value.map(item => (
          <span key={item} className="item">
            {item}
          </span>
        ))}
      </div>
    </li>
  ) : (
    <></>
  );
}

function ObjectSection(props) {
  const { name, intl, value, enums } = props;
  function getReturn(intl, value) {
    return Object.keys(value).map(item => getSection(item, value[item], intl, enums));
  }
  return (
    <li key={name} className="objectLine">
      <div className="title">{name}</div>
      <ul>{getReturn(intl, value)}</ul>
    </li>
  );
}

export { ProfilSection };
