import { ThemeProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { ProSidebarProvider } from 'react-pro-sidebar';
import './App.css';
import { IntlWrapper, ToastContainer } from './components';
import { Router, routes } from './routes';
import { lacleStore, persistor } from './store';
import { useMode } from './themes';

function App() {
  const [theme] = useMode();
  return (
    <Provider store={lacleStore}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <IntlWrapper>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
              <ProSidebarProvider>
                <Router routes={routes} />
              </ProSidebarProvider>
              <ToastContainer />
            </LocalizationProvider>
          </IntlWrapper>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
