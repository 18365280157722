const scenes = {
  login: {
    copyright: `Lacle © ${new Date().getFullYear()}`,
    forgotPasswordLink: 'Mot de passe oublié ?',
    loginButtonLabel: 'Se connecter',
    rememberMeLabel: 'Rester connecté',
    emailLabel: 'Adresse email',
    passwordLabel: 'Mot de passe',
    welcomeMessage: 'Connectez-vous à FRom3.0',
  },
  setPassword: {
    passwordLabel: 'Mot de passe',
    changePasswordButtonLabel: 'Confirmer',
    enterPassword: 'Veuillez renseigner votre nouveau mot de passe.',
  },
  forgotPassword: {
    title: 'Réinitialisation du mot de passe',
    emailLabel: 'Email',
    sendLinkButtonLabel: 'Envoyer le lien de réinitialisation',
    successMessage: 'Le lien de réinitialisation du mot de passe a été envoyé avec succès.',
  },
  Table: {
    actions: 'Actions',
  },
  home: {
    buttons: {
      student: 'Ajouter un apprenant',
      volunteer: 'Ajouter un bénévole',
      user: 'Ajouter un utilisateur',
      workshop: 'Ajouter un atelier',
      campaign: 'Ajouter une campagne',
      interview: 'Créer un entretien',
    },
    InterviewDialog: {
      title: 'Créer une interview avec ?',
      type: 'Type',
      name: 'Nom Prénom',
      error: 'Tout les champs doivent être remplis',
    },
  },
  campaign: {
    list: {
      title: 'Campagne',
      columnTitles: {
        name: 'Nom',
        description: 'Description',
      },
      button: {
        add: 'Ajouter une Campagne',
      },
    },
    create: {
      title: 'Créer une campagne',
    },
    edit: {
      title: 'Modifier une campagne',
      labels: {
        name: 'Nom',
        description: 'Description',
      },
    },
    button: {
      title: 'Supprimer Campagne',
      modal: 'Supprimer la campagne :nom',
    },
  },
  site: {
    list: {
      title: 'Etablissement',
      columnTitles: {
        name: 'Nom',
        description: 'Description',
      },
      button: {
        add: 'Ajouter un établissement',
      },
    },
    create: {
      title: 'Créer un établissement',
    },
    edit: {
      title: 'Modifier un établissement',
      labels: {
        name: 'Nom',
        description: 'Description',
      },
    },
    button: {
      title: "Supprimer l'établissement",
      modal: "Supprimer l'établissement :nom",
    },
    changes: {
      title: 'Choissisez un site pour la migration',
      label: 'Nouveau site',
      continue: 'Continuer',
      cancel: 'Annuler',
    },
  },
  subject: {
    list: {
      title: 'Sujets de cours',
      add: 'Nouveau Sujet',
      columnTitles: {
        name: 'Thème du cours',
        level: 'Niveau',
        type: 'Type',
      },
    },
    create: {
      title: 'Créer un sujet de cours',
    },
    form: {
      name: 'Nom du cours',
      type: "Type d'étudiant",
      level: "Niveau de l'étudiant",
    },
    edit: {
      title: 'Modifier un sujet de cours',
    },
    theme: {
      list: {
        title: 'Thèmes de cours',
        add: 'Nouveau Thème',
        columnTitles: {
          name: 'Thème du cours',
        },
      },
      create: {
        title: 'Créer un thème de cours',
      },
      form: {
        name: 'Thème du cours',
      },
      edit: {
        title: 'Modifier un thème de cours',
      },
    },
  },
  submitButtons: {
    save: 'Sauvegarder',
    delete: 'Supprimer',
    edit: 'Modifier',
  },
  user: {
    form: {
      labels: {
        firstname: 'Prénom',
        lastname: 'Nom',
        email: 'Email',
        roles: {
          title: 'Roles',
          admin: 'Admin',
          team: 'Team',
          user: 'User',
        },
      },
      button: {
        title: 'Supprimer Utilisatteur',
        modal: "Supprimer l'utilisateur :nom :prenom",
      },
    },
    list: {
      title: 'Utilisateurs',
      columnTitles: {
        firstname: 'Prénom',
        lastname: 'Nom',
        email: 'Email',
        roles: 'Roles',
      },
      button: {
        add: 'Ajouter un Utilisateur',
      },
    },
    create: {
      title: 'Créer un utilisateur',
    },
    edit: {
      title: 'Modifier un utilisateur',
      resetButton: {
        title: 'Réinitialisation du mot de passe',
        modal: 'Réinitialiser le mot de passe de',
      },
    },
  },
  workshop: {
    form: {
      labels: {
        name: "Nom de l'atelier",
        bigName: 'Nom du theme',
      },
      button: {
        title: 'Supprimer Atelier',
        bigTitle: 'Supprimer le theme',
        modal: "Supprimer l'atelier :nom",
      },
    },
    list: {
      title: 'Ateliers',
      columnTitles: {
        name: "Nom de l'atelier",
      },
      button: {
        add: 'Ajouter un Atelier',
      },
    },
    create: {
      title: 'Créer un atelier',
    },
    edit: {
      title: 'Modifier un atelier',
    },
  },
  bigWorkshop: {
    form: {
      labels: {
        name: "Nom de l'atelier",
      },
      button: {
        title: 'Supprimer Atelier',
        modal: "Supprimer l'atelier :nom",
      },
    },
    list: {
      title: 'Ateliers',
      columnTitles: {
        name: "Nom du thème de l'atelier",
        workshop: 'Ateliers',
      },
      button: {
        add: 'Ajouter un Atelier',
      },
    },
    create: {
      title: "Créer un thème d'atelier",
    },
    edit: {
      title: "Modifier un thème d'atelier",
    },
  },
  common: {
    general_information: {
      title: 'Informations Générales',
      labels: {
        last_name: 'Nom',
        first_name: 'Prénom',
        maiden_name: 'Nom de jeune fille',
        sexe: 'Sexe',
        birth_date: 'Date de naissance',
        birth_place: 'Lieu de naissance',
        origin: 'Origine',
        nationality: 'Nationalité',
        native_language: 'Langue première',
        arrival_date: "Date d'entrée",
        mobile: 'Numéro de téléphone',
        dad_mobile: 'Numéro de téléphone du Père',
        mum_mobile: 'Numéro de téléphone de la Mère',
        type: 'Type',
        email: 'Email',
        other_email: 'Autre Email',
        social_number: 'Numéro de sécurité sociale',
        address: 'Adresse',
        address_description: 'Adresse',
        city: 'Ville',
        zip_code: 'Code postal',
        district: 'Quartier',
        district_priority: 'Quartier prioritaire',
        redList: 'Liste rouge',
      },
    },
    digital: {
      title: 'Numérique',
      labels: {
        as_equipment: "Possede de l'équipement",
        as_skills: 'Ne sait pas utiliser son équipement',
        comment: "Commentaire sur l'équipement",
      },
    },
    registration_information: {
      title: "Information d'inscription",
      labels: {
        campaign: 'Campagne',
        date: "Date d'inscription",
        number: 'Nombre',
        fresh: 'Nouveau membre',
        first_date: 'Date de première inscription',
        know_lacle: 'Comment avez-vous connu LACLE ?',
        other_known: 'Autres',
      },
    },
    family_ressources: {
      title: 'Ressources Familiales',
      titleVolunteer: 'Situation',
      labels: {
        salary: 'Salarié',
        plain_time: 'Temps Plein',
        middle_time: 'Temps partiel',
        CDD: 'CDD',
        CDI: 'CDI',
        INTERIM: 'INTERIM',
        help: 'Aidé',
        AAH: 'AAH',
        RSA: 'RSA',
        student: 'Etudiant',
        ASSEDIC: 'ASSEDIC',
        ADA: 'ADA',
        AMASE: 'AMASE',
        without_ressources: 'Sans ressources',
        pension: 'Pension',
        other: 'Autre',
        CAFNumber: 'N° CAF',
        instructing_body: 'Orga. instructeur',
        obtention_data: "Date d'obtention",
        other_details: 'Autres détails',
        referent: 'Référent',
        internship: 'Stagiaire',
        parent_work: 'Travail des parents',
        pre_retirement: 'Pré retraite',
        retirement: 'Retraité',
        has_children: 'Avec enfants',
        work_name: "Description de l'emploi",
        school: 'Ecole',
        certification: 'Diplôme obtenue',
        certification_futur: 'Diplôme envisagé',
        school_path: "Niveau d'étude",
        retirement_number: 'N° de carte senior',
        health_number: 'N° de sécurité sociale',
      },
    },
    availabilities_information: {
      title: 'Disponibilités',
      labels: {
        day: 'Jour de la semaine',
        start_hour: 'Heure de début',
        end_hour: 'Heure de fin',
      },
    },
    reminder: {
      title: 'Propositions',
      labels: {
        date: 'Date',
        comment: 'Commentaire',
        status: 'Statut',
      },
      status: {
        accepted: 'Accepté',
        rejected: 'Refusé',
        waiting: 'En Attente',
        here: 'Présent',
        absent: 'Absent',
        excused: 'Excusé',
      },
    },
  },
  student: {
    type: "Type d'apprenant",
    comment: "Commentaire sur l'apprenant",
    other_intervention: 'Autres intervention',
    family_situation: {
      title: 'Situation Familiale',
      labels: {
        alone: 'Seul',
        couple: 'En couple',
        children: 'Avec enfants',
      },
    },
    life_state: {
      title: "Status de l'apprenant",
      labels: {
        salary: 'Salarié',
        plain_time: 'Temps Plein',
        middle_time: 'Temps partiel',
        CDD: 'CDD',
        CDI: 'CDI',
        INTERIM: 'INTERIM',
        help: 'Aidé',
        employment_asker: "Demandeur d'emploi",
        home_children: 'Enfant au foyer',
        country_asker: "Demandeur d'asile",
        home: 'Au foyer',
        AAH: 'AAH',
        ESAT: 'ESAT',
        PPAI: 'PPAI',
        RSA: 'RSA',
        other: 'Autres',
        ESAT_details: 'ESAT détails',
        employment_asker_date: "Date d'inscription à pole emploi",
        other_details: 'Autres détails',
        comment: 'Commentaire',
      },
    },
    social_mediation: {
      title: 'Médiation sociale',
      labels: {
        active: 'Activé',
        details: 'Détails',
      },
    },
    wanted_subject: {
      title: 'Matières ( La clé )',
      labels: {
        wanted_subject: 'Matières',
      },
    },
    level: {
      title: "Niveau de l'apprenant",
      labels: {
        initial_level: 'Niveau initial',
        final_level: 'Niveau final',
        certification: 'Certification envisagé',
        certification_final: 'Certification Obtenue',
        MIFE: 'MIFE',
        level_comment: 'Commentaire',
        school_path: 'Parcours scolaire',
        name: 'Formations',
        comment: 'Commentaire',
      },
    },
    school: {
      title: 'Scolaire',
      labels: {
        level: 'Niveau',
        class_room: 'Classes',
        school_name: "Nom de l'établissement",
        school_comment: "Commentaire sur l'établissement",
        school_path: 'Parcours scolaire',
        subjet: 'Matières',
        name: 'Formations',
        comment: "Commentaire sur l'apprenant",
        option1: "Option à l'école",
        option2: "Option à l'école #2",
        option3: "Option à l'école #3",
      },
    },
    workshop: {
      title: 'Atelier',
      labels: {
        workshops: "Thèmes d'Ateliers",
        workshopsComment: 'Commentaire sur les Ateliers',
      },
    },
    list: {
      title: 'Apprenants',
      columnTitles: {
        type: 'Type',
        last_name: 'Nom',
        first_name: 'Prénom',
        mobile: 'Téléphone',
        email: 'Email',
        nbInterviews: "Nombre d'entretien",
        other_intervention: 'Intervention',
      },
      button: {
        add: 'Ajouter un apprenant',
      },
    },
    family_link: {
      title: 'Famille',
      labels: {
        type: 'Type',
        student: 'Avec',
        item: 'Profil',
      },
      table: {
        add: 'Ajouter Lien',
        edit: 'Modifier Lien',
        no_data: 'Pas de liens familiaux',
        dialogTitle: 'Ajouter un lien familial',
      },
    },
    create: {
      title: 'Ajouter un apprenant',
    },
    edit: {
      title: 'Modifier un apprenant',
    },
    profil: {
      title: "Profil d'un apprenant",
    },
    button: {
      title: 'Supprimer Apprenant',
      modal: "Supprimer l'Apprenant :nom :prenom",
    },
  },
  volunteer: {
    list: {
      title: 'Bénévoles',
      columnTitles: {
        last_name: 'Nom',
        first_name: 'Prénom',
        mobile: 'Téléphone',
        email: 'Email',
        nbInterviews: "Nombre d'entretien",
        other_intervention: 'Intervention',
      },
      button: {
        add: 'Ajouter un bénévole',
      },
    },
    create: {
      title: 'Ajouter un bénévole',
    },
    edit: {
      title: 'Modifier un bénévole',
    },
    profil: {
      title: "Profil d'un bénévole",
    },
    button: {
      title: 'Supprimer Bénévole',
      modal: 'Supprimer le Bénévole :nom :prenom',
    },
    form: {
      other_intervention: 'Autres intervention',
      comment: 'Commentaire sur le bénévole',
    },
  },
  interview: {
    form: {
      title: 'Entretien pour :name',
      labels: {
        type: 'Type',
        date: 'Date',
        theme: 'Thématique',
        title: 'Titre',
        volunteer: 'Bénévole',
        student: 'Apprenant',
        interviewed_classmate_id: 'avec',
        user: 'par',
        campaign: 'Campagne',
        school_subject: 'Nom du cours',
        school_subject_hours: 'Horaires du cours',
        stop_date: "Date d'arrêt",
        volunteer_stop: 'Arrêt du bénévole',
        student_stop: "Arrêt de l'apprenants",
        lacle_stop: 'Arrêt de La Clé',
        details: 'Détails',
      },
    },
    create: {
      title: 'Créer un entretien',
    },
    edit: {
      title: 'Modifier un entretien',
    },
    button: {
      title: 'Supprimer Entretien',
      modal: "Supprimer l'entretien de :nom :prenom",
    },
    list: {
      title: 'Entretiens de :id',
      columnTitles: {
        title: 'Titre',
        type: 'Type',
        interviewed_id: 'Nom',
        volunteer: 'Bénévole',
        student: 'Apprenant',
        interviewed_classmate_id: 'Avec (Nom)',
        created_at: 'Création',
        updated_at: 'Mise à jour',
        user: 'Par',
        campaign: 'Campagne',
        school_subject: 'Nom du cours',
        school_subject_hours: 'Horaires du cours',
        stop_date: "Date d'arrêt",
        volunteer_stop: 'Bénévoles',
        student_stop: 'Apprenants',
        details: 'Détails',
      },
      button: {
        add: 'Créer un Entretien',
      },
    },
  },
  matching: {
    list: {
      title: 'Matchings',
      favorite: 'Mes Matchings',
      classified: 'Classifiés',
      columnTitles: {
        course: 'Cours',
        level: 'Niveau',
        students: 'Apprenants',
        volunteers: 'Bénévoles',
      },
      button: {
        add: 'Ajouter un matching',
      },
    },
    profil: {
      title: "Suivi d'un Matching",
    },
    create: {
      title: 'Créer un Matching',
    },
    edit: {
      title: 'Modifier un Matching',
    },
    form: {
      labels: {
        subject: 'Cours',
        level: 'Niveau',
        day: 'Jour',
        start_hour: 'Heure de Début',
        end_hour: 'Heure de Fin',
        draft: 'Brouillon',
        filter: 'Filtre',
      },
      section: {
        course: 'Cours',
        availabilities: 'Horaires',
        volunteers: 'Bénévoles',
        students: 'Apprenants',
      },
    },
  },
  positionedWorkshop: {
    list: {
      title: 'Ateliers positionnés',
      columnTitles: {
        workshopName: "Nom de l'atelier",
        title: 'Titre',
        date: 'Date',
        animator: 'Animateur',
      },
      button: {
        add: 'Ajouter un atelier prévu',
      },
    },
    form: {
      workshop: 'Atelier',
      title: 'Titre',
      positionedDate: 'Date',
      animator: 'Animateur',
      button: {
        title: 'Supprimer atelier prévu',
        modal: "Supprimer l'atelier prévu :nom du :date",
      },
    },
    managment_form: {
      save: 'Save',
    },
    create: {
      title: "Ajouter un positionnement d'atelier",
    },
    edit: {
      title: "Editer le positionnement d'un atelier",
      titletab: 'Gestion des participants',
      labels: {
        name: 'Nom et Prénom',
      },
      table: {
        add: 'Ajouter un participant',
        edit: 'Modifier un participant',
        no_data: 'Aucune Participant',
        dialogTitle: 'Ajouter un participant',
      },
    },
    callList: {
      title: "LISTE D'APPEL",
      labels: {
        name: 'NOM et Prénom',
        mobile: 'Téléphone',
        status: 'Statut',
      },
      buttons: {
        setPresent: 'Mettre Présent',
        setAbsent: 'Mettre Absent',
      },
    },
  },
  timeline: {
    create: 'Ajouter un cours',
    edit: 'Modifier un cours',
    move: 'Voulez-vous vraiment modifier le cours :name ?',
    reinit: 'réinitialiser la date',
    labels: {
      matching: 'Matching',
      type: 'Type',
      weeks: 'Nombre de semaines',
      start_time: 'Heure de Début',
      end_time: 'Heure de Fin',
      volunteer_state: 'Bénévole :',
      student_states: 'Apprenants :',
      course: 'Cours',
      courses_state: 'Statut du Cours',
      note: 'Note',
      room: 'Salle',
    },
    buttons: {
      add: 'Ajouter',
      edit: 'Modifier',
      edit_all: 'Modifier Tout',
      date_change: 'Ajourner',
      cancel: 'Annuler',
      delete: 'Supprimer',
      delete_all: 'Supprimer Tout',
      confirm_delete: 'Supprimer le cours : :titre',
      confirm_delete_all: 'Supprimer tous les éléments à venir du cours : :titre',
    },
  },
};

export { scenes };
