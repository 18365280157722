import { AddCircle, Edit } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { MultipleSelector } from 'components/MultipleSelector';
import { Selector } from 'components/Selector';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { ArrayToSelector } from 'tools/arrayToSelector';

function AddEntitiesListComponent({
  setOpenCreate,
  openCreate,
  entities,
  addEntities,
  editEntities,
  disabled,
  editItem,
  editItemIndex,
  ...props
}) {
  const emptyEntitie = [];
  const [newEntities, setNewEntities] = useState(editItem ?? {});
  const [newEntitiesArray, setNewEntitiesArray] = useState([]);

  const intl = props.intl.messages.scenes.positionedWorkshop.edit;
  const labels = intl.labels;

  function setFieldFunction(name) {
    return value => {
      setNewEntities(entities.find(elem => elem._id === value));
    };
  }

  function addNewEntities() {
    const newEntitiesWellMade = [...newEntitiesArray].map(e => entities.find(elem => elem._id === e));
    addEntities([...newEntitiesWellMade]);
  }

  return (
    <>
      <Grid justifyContent="space-evenly" container>
        <Grid item xs={8} sm={8} className="padding-small">
          {editItem !== undefined ? (
            <Selector
              labelId="name"
              label={labels.name}
              selected={newEntities._id}
              setSelected={setFieldFunction('_id')}
              items={ArrayToSelector.getGeneralInformationNamesArray([...entities, editItem])}
              disabled={disabled}
            />
          ) : (
            <MultipleSelector
              labelId="name"
              label={labels.name}
              selected={newEntitiesArray}
              setSelected={setNewEntitiesArray}
              items={ArrayToSelector.getGeneralInformationNamesArray(entities)}
              disabled={disabled}
              className="full-width"
            />
          )}
        </Grid>
        <Grid justifyContent="space-evenly" container>
          <Button
            variant="contained"
            color="primary"
            startIcon={editItem !== undefined ? <Edit /> : <AddCircle />}
            onClick={() => {
              editItem !== undefined ? editEntities(newEntities, editItemIndex) : addNewEntities();
              setNewEntities(emptyEntitie);
              setOpenCreate(false);
            }}
          >
            {editItem !== undefined ? intl.table.edit : intl.table.add}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

const AddEntitiesList = injectIntl(AddEntitiesListComponent);

export { AddEntitiesList };
