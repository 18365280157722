import { AddCircle, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, Paper, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { paths } from 'routes';

function ThemeSubjectTableComponent({ subjectFound }) {
  const [data] = useState(useMemo(() => subjectFound ?? [], [subjectFound]));
  const history = useHistory();

  const {
    messages: {
      scenes: {
        subject: {
          theme: { list: intl },
        },
      },
    },
  } = useIntl();
  const columnTitles = intl.columnTitles;

  const columns = useMemo(
    () => [
      {
        header: columnTitles.name,
        accessorKey: 'name',
      },
    ],
    [columnTitles.name],
  );

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h3">{intl.title}</Typography>
      </Box>
      <MaterialReactTable
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
        }}
        columns={columns}
        data={data ?? []}
        enableDensityToggle={false}
        enableColumnActions={false}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 25, 50, 100, 150, { label: 'All', value: data?.length ?? 0 }],
        }}
        muiTableBodyCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            fontWeight: 'normal',
            fontSize: '110%',
          },
        }}
        positionActionsColumn="last"
        positionGlobalFilter="left" //show the global filter on the left side of the top toolbar
        initialState={{
          showGlobalFilter: true, //show the global filter by default
          density: 'compact',
          pagination: {
            pageSize: 25,
          },
        }}
        enableRowActions={true}
        renderRowActions={({ row }, index) => {
          return (
            <IconButton
              key={index}
              size="small"
              component={Link}
              to={paths.front.subject.theme.edit.replace(':id', row.original._id)}
            >
              <Edit />
            </IconButton>
          );
        }}
        enableToolbarInternalActions={true}
        renderTopToolbarCustomActions={() => {
          return (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircle />}
              onClick={() => history.push(paths.front.subject.theme.create)}
            >
              {intl.add}
            </Button>
          );
        }}
      />
    </Paper>
  );
}

const ThemeSubjectTable = ThemeSubjectTableComponent;

export { ThemeSubjectTable };
