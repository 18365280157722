import { Container, Grid } from '@mui/material';
import { MultipleSelector } from 'components/MultipleSelector';
import { TextInput } from 'components/TextInput';
import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { ArrayToSelector, ValueUtils } from 'tools';
import { BigWorkshopSubmitButton } from './component';

const vod = ValueUtils.valueOrDefault;

function getInitialValues(values = {}) {
  return {
    name: vod(values.name, ''),
    workshop: vod(values.workshop, []),
    id: vod(values._id, undefined),
  };
}

function BigWorkshopFormComponent(props) {
  const { reload, mode, values, workshops, bigWorkshops } = props;
  const initialValues = getInitialValues(values);
  const [fields, setFields] = useState({
    ...initialValues,
    errors: {
      name: false,
    },
    loading: false,
  });

  const intl = props.intl.messages.scenes.workshop.form;

  function setFieldWithErrorFunction(name) {
    return value => {
      setFields(f => ({
        ...f,
        [name]: value,
        errors: { ...f.errors, [name]: f.errors[name] && value !== '' ? false : f.errors[name] },
      }));
    };
  }

  function setFieldFunction(field) {
    return value => {
      const newFamilyRessources = { ...fields };
      newFamilyRessources[field] = value;
      setFields(newFamilyRessources);
    };
  }

  const allowedWorkshops = useMemo(() => {
    const allbigworkshops = bigWorkshops.filter(bw => bw._id.toString() !== fields?.id?.toString());
    return workshops.filter(w => !R.flatten(allbigworkshops.map(bw => bw.workshop)).includes(w._id));
  }, [bigWorkshops, workshops, fields.id]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <TextInput
            name="name"
            label={intl.labels.bigName}
            value={fields.name}
            setField={setFieldWithErrorFunction('name')}
            error={fields.errors.name}
            disabled={fields.loading}
          />
        </Grid>
        <Grid container item xs={12} sm={12}>
          <MultipleSelector
            labelId="workshop"
            label={intl.labels.name}
            items={ArrayToSelector.getArray(allowedWorkshops)}
            selected={fields.workshop}
            setSelected={setFieldFunction('workshop')}
            className="full-width"
          />
        </Grid>
        <BigWorkshopSubmitButton
          fields={fields}
          setFields={setFields}
          initialValues={initialValues}
          reload={reload}
          mode={mode}
        />
      </Grid>
    </Container>
  );
}

const BigWorkshopForm = injectIntl(BigWorkshopFormComponent);

export { BigWorkshopForm };
