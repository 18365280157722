const paths = {
  front: {
    default: '/home',
    login: '/login',
    forgotPassword: '/forgot_password',
    setPassword: '/set_password',
    campaign: {
      home: '/campaign',
      create: '/campaign/create',
      edit: '/campaign/edit/:id',
    },
    site: {
      home: '/site',
      create: '/site/create',
      edit: '/site/edit/:id',
    },
    user: {
      home: '/user',
      create: '/user/create',
      edit: '/user/edit/:id',
    },
    workshop: {
      home: '/workshop',
      create: '/workshop/create',
      edit: '/workshop/edit/:id',
      positionedWorkshop: {
        home: '/positioned-workshop',
        create: '/positioned-workshop/create',
        edit: '/positioned-workshop/edit/:id',
      },
      callList: {
        home: '/callList/:id',
      },
    },
    bigWorkshop: {
      create: '/bigWorkshop/create',
      edit: '/bigWorkshop/edit/:id',
    },
    subject: {
      home: '/subject',
      create: '/subject/create',
      edit: '/subject/edit/:id',
      theme: {
        create: '/subject/theme/create',
        edit: '/subject/theme/edit/:id',
      },
    },
    student: {
      home: '/student',
      create: '/student/create',
      edit: '/student/edit/:id',
      profil: '/student/profil/:id',
    },
    volunteer: {
      home: '/volunteer',
      create: '/volunteer/create',
      edit: '/volunteer/edit/:id',
      profil: '/volunteer/profil/:id',
    },
    interview: {
      create: '/interview/create',
      edit: '/interview/edit/:id',
    },
    matching: {
      home: '/matching',
      create: '/matching/create',
      edit: '/matching/edit/:id',
      profil: '/matching/profil/:id',
    },
    timeline: {
      home: '/timeline',
      stats: '/timeline/stats',
    },
    stats: {
      timeline: '/stats/timeline',
    },
  },
  api: {
    auth: {
      login: '/auth/login',
      refresh: '/auth/request_access_token',
    },
    user: {
      infos: '/users/me',
      setPassword: '/users/password',
      forgotPassword: '/users/forgot_password',
      factoriot: {
        list: '/users',
        create: '/users',
        edit: '/users/',
        get: '/users/',
        delete: '/users/',
      },
    },
    campaign: {
      list: '/campaigns',
      get: '/campaign/',
      create: '/campaign',
      edit: '/campaign/',
      delete: '/campaign/',
    },
    site: {
      list: '/sites',
      get: '/site/',
      create: '/site',
      edit: '/site/',
      delete: '/site/',
      change: '/changeSite',
    },
    subject: {
      list: '/subjects',
      get: '/subject/',
      create: '/subject',
      edit: '/subject/',
      delete: '/subject/',
      theme: {
        list: '/subjects/themes',
        get: '/subject/theme/',
        create: '/subject/theme',
        edit: '/subject/theme/',
        delete: '/subject/theme/',
      },
    },
    workshop: {
      list: '/workshops',
      get: '/workshop/',
      create: '/workshop',
      edit: '/workshop/',
      delete: '/workshop/',
    },
    bigWorkshop: {
      list: '/bigWorkshops',
      get: '/bigWorkshop/',
      create: '/bigWorkshop',
      edit: '/bigWorkshop/',
      delete: '/bigWorkshop/',
    },
    student: {
      list: '/students',
      get: '/student/',
      create: '/student',
      edit: '/student/',
      delete: '/student/',
    },
    volunteer: {
      list: '/volunteers',
      get: '/volunteer/',
      create: '/volunteer',
      edit: '/volunteer/',
      delete: '/volunteer/',
    },
    interview: {
      list: '/interviews',
      listByInterviewed: '/interviews/interviewed',
      get: '/interview/',
      create: '/interview',
      edit: '/interview/',
      delete: '/interview/',
    },
    positionedWorkshop: {
      list: '/positioned-workshops',
      get: '/positioned-workshop/',
      create: '/positioned-workshop',
      edit: '/positioned-workshop/',
      delete: '/positioned-workshop/',
      getEntitiesList: '/positioned-workshop/list/',
      editEntitiesList: '/positioned-workshop/list/',
    },
    matching: {
      list: '/matchings',
      doneList: '/matchings/done',
      get: '/matching/',
      studentList: '/matching/student/',
      volunteerList: '/matching/volunteer/',
      create: '/matching',
      edit: '/matching/',
      delete: '/matching/',
    },
    timeline: {
      rooms: '/timeline/rooms',
      list: '/timelines',
      matchList: '/timelines/match/',
      studentList: '/timelines/student/',
      volunteerList: '/timelines/volunteer/',
      create: '/timeline',
      edit: '/timeline/',
      editAll: '/timeline/all/',
      delete: '/timeline/',
      deleteAll: '/timeline/all/',
      stats: '/timeline/stats',
    },
    stats: {
      timeline: '/stats/timeline',
      general: '/stats/general',
    },
  },
};

export { paths };
