/* eslint-disable prettier/prettier */
import { Box, CircularProgress, Container, Grid, Paper } from '@mui/material';
import {
  Availabilities,
  DigitalForm,
  FamilyRessourcesForm,
  GeneralForm,
  RegisterForm,
  WorkshopFormComon,
} from 'components/ComonForm';
import { MultipleSelector } from 'components/MultipleSelector';
import { Selector } from 'components/Selector';
import { TextInput } from 'components/TextInput';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { ComonEnums } from 'services/comon';
import { StudentEnums } from 'services/student';
import { ArrayToSelector, ValueUtils } from 'tools';
import {
  FamilyLinkForm,
  FamilySituationForm,
  LevelForm,
  LifeStateForm,
  SchoolForm,
  SocialMediationForm,
  StudentSubmitButton,
  WantedSubjectForm,
} from './components';
import './style/StudentForm.css';

const vod = ValueUtils.valueOrDefault;

function getInitialValues(values = {}) {
  return {
    type: vod(values.type, ''),
    draft: vod(values.draft, false),
    external: vod(values.external, false),
    general_information: vod(values.general_information, {}),
    registration_information: vod(values.registration_information, {}),
    availabilities_information: vod(values.availabilities_information, []),
    family_links: vod(values.family_links, []),
    family_situation: vod(values.family_situation, {}),
    family_ressources: vod(values.family_ressources, {}),
    life_state: vod(values.life_state, {}),
    social_mediation: vod(values.social_mediation, {}),
    digital: vod(values.digital, {}),
    temp_email: vod(values.temp_email, ''),
    courses_as: vod(values.courses_as, []),
    wanted_subject: vod(values.wanted_subject, []),
    level: vod(values.level, {}),
    school: vod(values.school, {}),
    comment: vod(values.comment, ''),
    other_intervention: vod(values.other_intervention, []),
    campaign: vod(values.campaign, ''),
    site: vod(values.site, ''),
    workshop: vod(values.workshop, {}),
    id: vod(values._id, undefined),
    uuid: vod(values.uuid, undefined),
    errors: {
      last_name: false,
      campaign: false,
      type: false,
    },
    loading: false,
  };
}

function StudentFormComponent(props) {
  const { reload, mode, values, templates, studentsTemplates } = props;
  const students = studentsTemplates.students;
  const initialValues = getInitialValues(values);
  const [fields, setFields] = useState({
    ...initialValues,
  });
  const types = StudentEnums.getTypeArray();
  const interventions = ComonEnums.getOtherInterventionArray();

  const intl = props.intl.messages.scenes.student;

  function setFieldFunction(name) {
    return value => {
      setFields(f => ({ ...f, [name]: value }));
    };
  }
  function setFieldWithErrorFunction(name) {
    return value => {
      setFields(f => ({
        ...f,
        [name]: value,
        errors: { ...f.errors, [name]: f.errors[name] && value !== '' ? false : f.errors[name] },
      }));
    };
  }

  function setTypeFiedFunction(value) {
    setFieldWithErrorFunction('type')(value);
    if (mode === 'create' && StudentEnums.getAdultType().includes(value)) {
      setFieldFunction('wanted_subject')([studentsTemplates.themeSubjects.find(t => t.name === value)._id]);
    }
  }

  return (
    <Container maxWidth="lg">
      {fields.loading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="heigh-circular"
        >
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="full-width">
          <Grid container item xs={12} sm={12} className="padding-small">
            <Selector
              labelId="type"
              label={intl.type}
              selected={fields.type}
              setSelected={setTypeFiedFunction}
              items={types}
              error={fields.errors.type}
              disabled={fields.loading}
            />
          </Grid>
          <Grid container item xs={12} sm={12}>
            <Grid item xs={6} sm={6} className="padding-small">
              <GeneralForm
                data={fields.general_information}
                setData={setFieldFunction('general_information')}
                disabled={fields.loading}
                errors={fields.errors}
                mode="student"
                type={fields.type}
              />
              <FamilySituationForm
                data={fields.family_situation}
                setData={setFieldFunction('family_situation')}
                disabled={fields.loading}
              />
              <LifeStateForm
                data={fields.life_state}
                setData={setFieldFunction('life_state')}
                disabled={fields.loading}
              />
              <SocialMediationForm
                data={fields.social_mediation}
                setData={setFieldFunction('social_mediation')}
                disabled={fields.loading}
              />
              <DigitalForm data={fields.digital} setData={setFieldFunction('digital')} disabled={fields.loading} />
              <WorkshopFormComon
                student={fields}
                setStudent={setFields}
                workshops={ArrayToSelector.getArray(templates.bigWorkshops)}
              />
            </Grid>
            <Grid item xs={6} sm={6} className="padding-small">
              <RegisterForm
                data={fields.registration_information}
                setData={setFieldFunction('registration_information')}
                root={{ campaign: fields.campaign, draft: fields.draft, site: fields.site, external: fields.external }}
                setRoot={setFieldWithErrorFunction}
                errors={fields.errors}
                disabled={fields.loading}
                campaigns={ArrayToSelector.getArray(templates.campaigns) || {}}
                sites={ArrayToSelector.getArray(templates.sites) || {}}
              />
              <WantedSubjectForm
                data={fields}
                setData={setFieldFunction('wanted_subject')}
                themeSubjects={studentsTemplates.themeSubjects}
              />
              <Availabilities
                setData={setFieldFunction('availabilities_information')}
                data={fields.availabilities_information}
                mode="form"
                disabled={fields.loading}
              />
              <FamilyLinkForm
                setData={setFieldFunction('family_links')}
                data={fields.family_links}
                students={students}
                mode="form"
                disabled={fields.loading}
              />
              <FamilyRessourcesForm
                data={fields.family_ressources}
                setData={setFieldFunction('family_ressources')}
                type={fields.type}
                mode="student"
                disabled={fields.loading}
              />
              {fields.type !== 'AS' ? (
                <LevelForm
                  dataLevel={fields.level}
                  setDataLevel={setFieldFunction('level')}
                  dataSchool={fields.school}
                  setDataSchool={setFieldFunction('school')}
                  disabled={fields.loading}
                  type={fields.type}
                />
              ) : (
                <SchoolForm data={fields.school} setData={setFieldFunction('school')} disabled={fields.loading} />
              )}
              <Paper className="full-width marginB20 padding-small">
                <Grid item xs={12} sm={12}>
                  <MultipleSelector
                    labelId="other_intervention"
                    label={intl.other_intervention}
                    selected={fields.other_intervention}
                    setSelected={setFieldFunction('other_intervention')}
                    items={interventions}
                    disabled={fields.loading}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextInput
                    name="comment"
                    label={intl.comment}
                    value={fields.comment}
                    setField={setFieldFunction('comment')}
                    disabled={fields.loading}
                  />
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <StudentSubmitButton
            fields={fields}
            setFields={setFields}
            initialValues={initialValues}
            reload={reload}
            mode={mode}
          />
        </Box>
      )}
    </Container>
  );
}

const StudentForm = injectIntl(StudentFormComponent);

export { StudentForm };
