import LogoutIcon from '@mui/icons-material/Logout';
import { Box, IconButton, useTheme } from '@mui/material';
import { Selector } from 'components/Selector';
import { useConfigContext } from 'hooks';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { AuthenticationActioner } from 'services';
import { tokens } from 'themes';

function HeaderComponent({ logOut, campaigns, sites }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setCampaign, campaign, site, setSite } = useConfigContext();

  useEffect(() => {
    if (campaign === undefined) {
      setCampaign(campaigns[0]._id);
    }
  }, [campaigns, campaign, setCampaign]);

  useEffect(() => {
    if (site === undefined) {
      setSite(sites[0]._id);
    }
  }, [sites, site, setSite]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      backgroundColor={colors.primary[400]}
      paddingRight={2}
      paddingLeft={2}
    >
      <Box
        display="flex"
        flexDirection="row"
        borderRadius="3px"
        sx={{
          '& .MuiSelect-icon': {
            color: 'inherit',
          },
          '& .campaign-selector-color:before': {
            borderBottomColor: colors.primary[900],
          },
          '& .campaign-selector-color': {
            color: colors.primary[900],
          },
          '& .site-selector': {
            paddingLeft: '10% !important',
            minWidth: '100%',
          },
          '& .campaign-selector': {
            minWidth: '100%',
          },
        }}
      >
        <Selector
          className="campaign-selector"
          items={campaigns.map(t => ({ label: t.name, value: t._id }))}
          selected={campaign}
          setSelected={v => setCampaign(v)}
          variant="standard"
          inputClassName={'campaign-selector-color'}
          label="Campagne"
        />
        <Selector
          className="site-selector"
          items={sites.map(t => ({ label: t.name, value: t._id }))}
          selected={site}
          setSelected={v => setSite(v)}
          variant="standard"
          inputClassName={'campaign-selector-color'}
          label="Etablissement"
        />
      </Box>

      {/* ICONS */}
      <Box
        display="flex"
        sx={{
          '& .logout-icon': {
            color: colors.primary[900],
          },
        }}
      >
        <IconButton onClick={logOut}>
          <LogoutIcon className="logout-icon" />
        </IconButton>
      </Box>
    </Box>
  );
}

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(AuthenticationActioner.logout()),
});

const Header = connect(null, mapDispatchToProps)(HeaderComponent);

export { Header };
