import { AccountBox, Delete } from '@mui/icons-material';
import { Autocomplete } from '@mui/lab';
import { Box, Grid, IconButton, TextField } from '@mui/material';
import { CheckboxField } from 'components/CheckboxField';
import { TextInput } from 'components/TextInput';
import { injectIntl } from 'react-intl';
import { ComonUtils } from 'services/comon';
import { MatchingEnums } from 'services/matching';
import { ArrayUtils } from 'tools';
import { IconSelector } from './IconSelector';

function EntitySelectorComponent(props) {
  const { entity, selectEntity, setEntity, templates, withFilter, setWithFilter, setComment, comment } = props;

  const TYPES = MatchingEnums.getTypes();

  const openProfil = ComonUtils.openProfil;

  const entities = ArrayUtils.addTagArray([
    [TYPES.STUDENT, templates.students],
    [TYPES.VOLUNTEER, templates.volunteers],
  ]);

  function setEntityFunction(name) {
    return value => {
      const newEntity = { ...entity, [name]: value };
      setEntity(newEntity);
    };
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      {entity === undefined ? (
        <Grid item className="text-centered selector-60per" xs={6} sm={6} md={6}>
          <h4 className="marginB20">Choisir une personne </h4>
          <Autocomplete
            className="come-in-bottom"
            disablePortal
            id="combo-box-demo"
            getOptionLabel={option =>
              `${option.general_information.last_name} ${option.general_information.first_name}`
            }
            onChange={(e, v) => setEntity(selectEntity(v ?? undefined, undefined, templates, withFilter))}
            options={entities ?? []}
            renderInput={params => <TextField {...params} variant="outlined" label="Je suis en rendez-vous avec :" />}
          />
        </Grid>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
          <div className="margin20 position-relative come-in-left">
            <div className="info-img margin-auto"></div>
            <h3 className="marginT20">{`${entity.general_information.last_name} ${entity.general_information.first_name}`}</h3>
            <span className="left-icon">
              <IconButton size="medium" onClick={() => openProfil(entity.entity_type, entity._id)}>
                <AccountBox color="primary" />
              </IconButton>
            </span>
            <span className="right-icon">
              <IconButton size="medium" onClick={() => setEntity(undefined)}>
                <Delete color="secondary" />
              </IconButton>
            </span>
          </div>
          <div className="margin20 come-in-right">
            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
              <IconSelector
                title="Disponibilités :"
                items={entity.availabilities_information}
                setItems={setEntityFunction('availabilities_information')}
                multiple
                Output={({ item }) => (
                  <div className="text-centered">
                    <h3>{item.day === 'all days' ? 'Tous les jours'.toUpperCase() : item.day.toUpperCase()}</h3>{' '}
                    <h5>{`${item.start_hour} - ${item.end_hour}`}</h5>
                  </div>
                )}
              />
              <CheckboxField
                label="Activer les filtres sur les disponibilités et les cours"
                checked={withFilter}
                setField={setWithFilter}
              />
              <IconSelector
                title="Cours :"
                items={entity.entity_type === TYPES.STUDENT ? entity.wanted_subject : entity.proposed_subject}
                setItems={
                  entity.entity_type === TYPES.STUDENT
                    ? setEntityFunction('wanted_subject')
                    : setEntityFunction('proposed_subject')
                }
                Output={({ item }) => (
                  <div className="text-centered">
                    {entity.entity_type === TYPES.STUDENT ? (
                      <h3>{templates?.themeSubjects?.find(i => i._id === item.label)?.name ?? 'Inconnu'}</h3>
                    ) : (
                      <div>
                        <h3>
                          {templates?.themeSubjects?.find(
                            i => i._id === templates?.subjects?.find(i => i._id === item.label)?.name,
                          )?.name ?? 'Inconnu'}
                        </h3>{' '}
                        <h5>{`${templates?.subjects?.find(i => i._id === item.label)?.type} - ${
                          templates?.subjects?.find(i => i._id === item.label)?.level
                        }`}</h5>
                      </div>
                    )}
                  </div>
                )}
              />
            </Box>
          </div>
        </Box>
      )}
      <TextInput label="Commentaire sur le matching" value={comment} setField={setComment} required={false} />
    </Box>
  );
}

const EntitySelector = injectIntl(EntitySelectorComponent);

export { EntitySelector };
