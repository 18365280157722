import { paths } from 'routes';
import { XHTTP } from 'services';

const END_POINTS = {
  ...paths.api.interview,
};

const InterviewApi = {
  getList: campaign => {
    let query = `?`;
    if (campaign !== undefined) {
      query = `${query}campaign=${campaign}`;
    }
    return XHTTP(`${END_POINTS.list}${query}`);
  },

  getListByInterviewed: id => {
    let query = `?`;
    if (id !== undefined) {
      query = `${query}interviewed_id=${id}`;
    }
    return XHTTP(`${END_POINTS.listByInterviewed}${query}`);
  },

  getSpecific: id => XHTTP(`${END_POINTS.get}${id}`),

  create: fields => XHTTP(END_POINTS.create, { method: 'post', body: fields }),

  update: (id, fields) => XHTTP(`${END_POINTS.edit}${id}`, { method: 'put', body: fields }),

  delete: id => XHTTP(`${END_POINTS.delete}${id}`, { method: 'delete' }),
};

export { InterviewApi };
