const services = {
  users: {
    statuses: {
      pending: 'En attente',
      active: 'Actif',
      blocked: 'Bloqué',
    },
  },
};

export { services };
