import { Box } from '@mui/material';
import { injectIntl } from 'react-intl';

function ProfilCardComponent(props) {
  const { className, item } = props;

  return (
    <div key={item._id} className={`marginB20 marginT20 ${className}`}>
      <div className="margin20 position-relative">
        <Box className="fullwidth" display="flex" alignItems="center" justifyContent="flex-start">
          <div className="card-match-icon"></div>
          <h3 className="marginL20">{`${item.general_information.last_name} ${item.general_information.first_name}`}</h3>
        </Box>
      </div>
    </div>
  );
}

const ProfilCard = injectIntl(ProfilCardComponent);

export { ProfilCard };
