import { AddCircle, Edit } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, IconButton, Paper } from '@mui/material';
import { EnhancedTable } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { BigWorkshopActioner } from 'services/bigWorkshop';
import { WorkshopActioner } from 'services/workshop';
import { WorkshopTable } from './components';
import './workshop.css';

function WorkshopAllTable({ workshops, bigWorkshops, intlData, reload, ...props }) {
  const intl = intlData.messages.scenes.workshop.list;
  const commonDefaultTitles = intlData.messages.scenes.Table;
  const columnTitles = intl.columnTitles;
  const workShopUnclassifed = useMemo(
    () =>
      workshops.filter(w => {
        return bigWorkshops.find(b => b.workshop.includes(w._id)) === undefined;
      }),
    [bigWorkshops, workshops],
  );
  const allparts = bigWorkshops.map(element => {
    return (
      <Paper key={element._id} className="mgBottom">
        <WorkshopTable workshopFound={workshops} bigWorkshop={element} intlData={intlData} reload={reload} />
      </Paper>
    );
  });
  const columns = useMemo(
    () => [
      {
        Header: columnTitles.name,
        accessor: 'name',
      },
      {
        Header: commonDefaultTitles.actions,
        accessor: '_id',
        disableSortBy: true,
        Cell: ({ value }) => (
          <IconButton size="small" component={Link} to={paths.front.workshop.edit.replace(':id', value)}>
            <Edit />
          </IconButton>
        ),
      },
    ],
    [columnTitles.name, commonDefaultTitles.actions],
  );
  return (
    <>
      {allparts}
      <Paper className="mgBottom">
        <EnhancedTable
          title={'Non classifié'}
          columns={columns}
          data={workShopUnclassifed}
          skipPageReset={true}
          useMultipleSelect={false}
          useFooter={false}
          useSearch={false}
        />
      </Paper>
    </>
  );
}

function WorkshopListComponent(props) {
  const [workshops, setWorkshops] = useState([]);
  const [bigWorkshops, setBigWorkshops] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (loading) {
      Promise.all([WorkshopActioner.list(), BigWorkshopActioner.list()]).then(([workshops, bigworkshops]) => {
        setWorkshops(workshops);
        setBigWorkshops(bigworkshops);
        setLoading(false);
      });
    }
    return () => {};
  }, [loading]);

  return (
    <>
      {loading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="height-circular"
        >
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <>
          <WorkshopAllTable
            workshops={workshops}
            bigWorkshops={bigWorkshops}
            intlData={props.intl}
            reload={() => setLoading(true)}
          />
          <>
            <Box component="span" sx={{ p: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircle />}
                onClick={() => history.push(paths.front.bigWorkshop.create)}
              >
                Ajouter un thème d'atelier
              </Button>
            </Box>
            <Box component="span" sx={{ p: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddCircle />}
                onClick={() => history.push(paths.front.workshop.create)}
              >
                Ajouter un atelier
              </Button>
            </Box>
          </>
        </>
      )}
    </>
  );
}

const WorkshopList = injectIntl(WorkshopListComponent);

export { WorkshopList };
