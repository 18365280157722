import { toast } from 'components';
import { lacleStore } from 'store';
import { StatsApi } from './api';

const StatsTimelineActioner = {
  getTimeline: fields => {
    return StatsApi.getTimeline(fields)
      .then(res => res)
      .catch(err => {
        const messages = lacleStore.getState().I18n.messages.toast.error;
        toast.error(messages.unableToRetrieveStatsInfos);
        throw err;
      });
  },
  getGeneral: fields => {
    return StatsApi.getGeneral(fields)
      .then(res => res)
      .catch(err => {
        const messages = lacleStore.getState().I18n.messages.toast.error;
        toast.error(messages.unableToRetrieveStatsInfos);
        throw err;
      });
  }
};

export { StatsTimelineActioner };
