import { paths } from 'routes';
import { XHTTP } from 'services';

const END_POINTS = {
  ...paths.api.timeline,
};

const TimelineApi = {
  getRooms: site => XHTTP(`${END_POINTS.rooms}?site=${site}`),

  getList: (date, campaign, site) => XHTTP(`${END_POINTS.list}?date=${date}&campaign=${campaign}&site=${site}`),

  getMatchList: id => XHTTP(`${END_POINTS.matchList}${id}`),

  getStudentList: id => XHTTP(`${END_POINTS.studentList}${id}`),

  getVolunteerList: id => XHTTP(`${END_POINTS.volunteerList}${id}`),

  create: fields => XHTTP(END_POINTS.create, { method: 'post', body: fields }),

  update: (id, fields) => XHTTP(`${END_POINTS.edit}${id}`, { method: 'put', body: fields }),

  delete: id => XHTTP(`${END_POINTS.delete}${id}`, { method: 'delete' }),

  deleteAll: (id, startTime) => XHTTP(`${END_POINTS.deleteAll}${id}?start_time=${startTime}`, { method: 'delete' }),

  stats: (campaign, site) => XHTTP(`${END_POINTS.stats}?campaign=${campaign}&site=${site}`),
};

export { TimelineApi };
