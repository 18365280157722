import { CheckboxField } from 'components/CheckboxField';
import { useMemo } from 'react';
import './RollingButton.css';

function RollingButton(props) {
  const { className, label, items, selected, setSelected, disabled, isLocked, setReturnedFiled, returnedFile } = props;

  const item_selected = useMemo(() => items.find(i => i.value === selected), [items, selected]);
  const index_item_selected = useMemo(() => items.findIndex(i => i.value === selected), [items, selected]);

  function onRoll() {
    if (isLocked) {
      const newValue = items.find(item => item.value === 'CLOSED').value;
      setSelected(newValue);
    } else {
      const index = items.findIndex(item => item.value === selected);
      const new_index = index + 1 > items.length - 1 ? 0 : index + 1;
      setSelected(items[new_index].value);
    }
  }

  return (
    <>
      <div
        className={`rolling-button-container ${className}`}
        style={{ borderColor: `${item_selected?.color}`, opacity: `${disabled ? '0.5' : '1'}` }}
        onClick={() => onRoll()}
      >
        <h5 className="rolling-button-label">{label}</h5>
        {items.map((item, index) => (
          <h3
            key={index}
            className={`rolling-button-item ${
              index_item_selected > index ? 'lock-bottom' : index_item_selected < index ? 'lock-top' : ''
            }`}
            style={{ color: `${item_selected?.color}` }}
          >
            {item.label}
          </h3>
        ))}
      </div>
      <CheckboxField checked={returnedFile} setField={setReturnedFiled} label="Dossier rendu" labelPlacement="end" />
    </>
  );
}

export { RollingButton };
