import { Box, CircularProgress, Grid } from '@mui/material';
import { WaveAnimation } from 'components/WaveAnimation';
import moment from 'moment';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { MatchingEnums } from 'services/matching';
import { TimelineActioner, TimelineEnums } from 'services/timeline';
import { ProfilCard } from './components/ProfilCard';

function MatchingProfilComponent(props) {
  const [match, setMatch] = useState({});
  const [loading, setLoading] = useState(true);
  const [timelines, setTimelines] = useState([]);
  const id = props.match.params.id;
  const intl = props.intl.messages.scenes;

  useEffect(() => {
    if (loading) {
      Promise.all([TimelineActioner.matchList(id)]).then(([list]) => {
        setTimelines(R.sort((a, b) => moment(b.start_time).diff(a.start_time), list));
        setMatch(list[0]?.matching);
        setLoading(false);
      });
    }
    return () => {};
  }, [loading, id]);

  function isPast(date) {
    return moment(date).diff(moment()) < 0;
  }

  const all_status = MatchingEnums.getStatus(true);
  const volunteer_state = TimelineEnums.getVolunteerState();
  const student_state = TimelineEnums.getStudentsState();
  const types = TimelineEnums.getTypes();

  function getColor(e) {
    const colors = TimelineEnums.storeColors();
    if (e.courses_state === 'SUSPENDED') {
      return colors.suspended;
    }
    if (e.type === 'HOME') {
      return colors.visio;
    }
    if (
      e.student_states.some(st => st.state === 'JA' || st.state === 'NJA') ||
      e.volunteer_state.state === 'JP' ||
      e.volunteer_state.state === 'NJP'
    ) {
      return colors.error;
    }
    if (
      (e.student_states.some(st => st.state === 'P') && e.volunteer_state.state === '') ||
      (!e.student_states.every(st => st.state === 'P') && e.volunteer_state.state === 'P')
    ) {
      return colors.incomplete;
    }
    if (
      e.student_states.every(st => st.state === 'P') &&
      e.volunteer_state.state === 'P' &&
      (e.courses_state === 'IN_PROGRESS' || e.courses_state === 'UNIQUE')
    ) {
      return colors.validated;
    }
    return colors.default;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      {loading ? (
        <Grid container item spacing={0} direction="column" alignItems="center" justifyContent="center">
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="width80">
          <h1>{intl.matching.profil.title.toUpperCase()} </h1>
          <Grid container item spacing={1} xs={12} sm={12}>
            <Grid className="marginT40" item xs={12} sm={6}>
              <Grid className="padding-normal course-bg" item xs={12} sm={12}>
                <div className="come-in-bottom ">
                  <h2 className="margin20">
                    {match?.course?.name} - {match?.level}
                  </h2>
                  <h4 className="margin20">
                    {match?.day?.toUpperCase()} : {match?.start_hour} - {match?.end_hour}
                  </h4>
                  <h3 className="margin20">{all_status.find(s => s.value === match.status)?.label ?? ''}</h3>
                </div>
              </Grid>
              <Grid className="marginT20 padding-normal-side come-in-left height-min-40" item xs={12} sm={12}>
                <h3>Bénévole</h3>
                {match?.volunteers?.map(v => (
                  <ProfilCard key={v?._id} item={v} />
                ))}
              </Grid>
              <Grid className="marginT20 padding-normal-side come-in-left height-min-40" item xs={12} sm={12}>
                <h3>Apprenants</h3>
                <WaveAnimation time={300} delay={300}>
                  {match?.students?.map(s => (
                    <ProfilCard key={s?._id} item={s} />
                  ))}
                </WaveAnimation>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid className="text-centered marginT20" item xs={12} sm={12}>
                <h3>Cours</h3>
                <WaveAnimation time={300} animation="slideInFromRight">
                  {timelines
                    .sort((a, b) => moment(a.start_time) - moment(b.start_time))
                    .map(t => (
                      <div key={t?._id} className="margin20 course-bg">
                        <Box
                          className={`padding-small fullwidth course-bg-header`}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            backgroundColor: getColor(t),
                          }}
                        >
                          <h4>{types.find(type => type.value === t?.type)?.label ?? ''}</h4>
                          <h4>
                            {isPast(t.start_time) ? (isPast(t.end_time) ? 'FINI' : 'EN COURS..') : 'PREVU'}
                            {t.courses_state === 'UNIQUE' ? ' - Rattrapage' : ''}
                          </h4>
                          <h4>{t?.room?.name}</h4>
                        </Box>
                        <div className="padding-normal">
                          <h3>
                            {moment(t.start_time).format('dddd DD / MM / YYYY')?.toUpperCase()}:{' '}
                            {moment(t.start_time).format('HH:mm')} - {moment(t.end_time).format('HH:mm')}
                          </h3>
                          <Box
                            className="marginT20 padding-small fullwidth"
                            display="flex"
                            justifyContent="space-around"
                          >
                            <span className="text-centered">
                              <h4>Bénévole</h4>
                              <h6 className="margin10">
                                {volunteer_state.find(vs => vs.value === t?.volunteer_state?.state)?.label ??
                                  'non renseigné'}
                              </h6>
                            </span>
                            <span className="text-centered">
                              <h4>Apprenants</h4>
                              {t?.student_states.map(st => (
                                <h6 key={st._id} className="margin10">
                                  {student_state.find(ss => ss.value === st.state)?.label ?? 'non renseigné'}
                                </h6>
                              ))}
                            </span>
                          </Box>
                          {t.note !== undefined && t.note !== '' && (
                            <>
                              <b>Note :</b> {t.note}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                </WaveAnimation>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

const MatchingProfil = injectIntl(MatchingProfilComponent);

export { MatchingProfil };
