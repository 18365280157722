import { injectIntl } from 'react-intl';

const WaveAnimationComponent = props => {
  const { children, duration, animation, time, delay } = props;

  return (
    <>
      {children.map((child, index) => (
        <div
          key={index}
          style={{ animation: `${duration} ease-out ${delay + time * index}ms 1 ${animation} forwards` }}
        >
          {child}
        </div>
      ))}
    </>
  );
};

WaveAnimationComponent.defaultProps = {
  duration: '300ms',
  animation: 'slideInFromBottom',
  time: 300,
  delay: 0,
};

const WaveAnimation = injectIntl(WaveAnimationComponent);

export { WaveAnimation };
