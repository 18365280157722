import { Box, Paper } from '@mui/material';
import { Loader } from 'components';
import { useConfigContext } from 'hooks';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { InterviewActioner } from 'services/interview';
import { StudentActioner } from 'services/student';
import { VolunteerActioner } from 'services/volunteer';
import { InterviewForm } from './InterviewForm';

function InterviewEditComponent(props) {
  const { campaign } = useConfigContext();
  const [, reload] = useState();
  const id = props.match.params.id;
  const templates = props.location.state.templates;
  const intl = props.intl.messages.scenes.interview.edit;

  function editForm(values) {
    return (
      <InterviewForm
        values={values}
        templates={templates}
        reload={() => reload({})}
        mode={values.campaign !== campaign ? 'view' : 'edit'}
      />
    );
  }

  function loadInfos() {
    return InterviewActioner.get(id)
      .then(infos => {
        const values = { ...infos, id };
        if (values.campaign !== campaign) {
          return Promise.all([
            StudentActioner.list(values.campaign, values.site),
            VolunteerActioner.list(values.campaign, values.site),
          ]).then(([students, volunteers]) => {
            templates.students = students;
            templates.volunteers = volunteers;
            return values;
          });
        }
        return values;
      })
      .catch(err => {
        throw err;
      });
  }

  function renderEditForm(render) {
    loadInfos().then(values => {
      render(editForm(values));
    });
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className="full-width padding-small"
    >
      <Paper className="padding-small">
        <h2 className="text-centered">{intl.title}</h2>
        <Loader render={renderEditForm} />
      </Paper>
    </Box>
  );
}

const InterviewEdit = injectIntl(InterviewEditComponent);

export { InterviewEdit };
