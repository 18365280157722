import { Edit } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import { EnhancedTable } from 'components';
import { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { paths } from 'routes';

function WorkshopTableComponent({ workshopFound, bigWorkshop, intlData }) {
  const workshops = workshopFound.filter(elem => bigWorkshop.workshop.some(value => value === elem._id));
  const [data] = useState(useMemo(() => workshops, [workshops]));
  const [skipPageReset] = useState(false);
  const history = useHistory();

  const intl = intlData.messages.scenes.workshop.list;
  const commonDefaultTitles = intlData.messages.scenes.Table;
  const columnTitles = intl.columnTitles;

  const columns = useMemo(
    () => [
      {
        Header: columnTitles.name,
        accessor: 'name',
      },
      {
        Header: commonDefaultTitles.actions,
        accessor: '_id',
        disableSortBy: true,
        Cell: ({ value }) => (
          <IconButton size="small" component={Link} to={paths.front.workshop.edit.replace(':id', value)}>
            <Edit />
          </IconButton>
        ),
      },
    ],
    [columnTitles.name, commonDefaultTitles.actions],
  );

  const actions = [
    {
      render: () => (
        <>
          <Box component="span" sx={{ p: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Edit />}
              onClick={() => history.push(paths.front.bigWorkshop.edit.replace(':id', bigWorkshop._id))}
            >
              Modifier
            </Button>
          </Box>
        </>
      ),
      isFreeAction: true,
    },
  ];

  return (
    <EnhancedTable
      title={bigWorkshop.name}
      columns={columns}
      data={data}
      actions={actions}
      skipPageReset={skipPageReset}
      useMultipleSelect={false}
      useFooter={false}
      useSearch={false}
    />
  );
}

const WorkshopTable = injectIntl(WorkshopTableComponent);

export { WorkshopTable };
