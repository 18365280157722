import { AddCircle, Replay } from '@mui/icons-material';
import { Button, IconButton, Paper, Tooltip } from '@mui/material';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { useTableContext, useTableState } from 'hooks';
import MaterialReactTable from 'material-react-table';
import { useCallback, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { paths } from 'routes';
import { MatchingActioner } from 'services/matching';

function ClassifiedMatchingTable({ title, templates, matchingFound, intlData, reload }) {
  const [data] = useState(useMemo(() => matchingFound, [matchingFound]));
  const history = useHistory();
  const tableRef = useTableContext();
  const tableState = useTableState('classified_matching');

  const onReset = useCallback(
    id => {
      const fieldsToSend = {
        expired_date: null,
        status: 'DRAFT',
      };
      return MatchingActioner.update(id, fieldsToSend)
        .then(() => {
          reload();
        })
        .catch(() => {
          reload();
        });
    },
    [reload],
  );

  const intl = intlData.messages.scenes.matching.list;
  const columnTitles = intl.columnTitles;
  const columns = useMemo(
    () => [
      {
        header: '',
        accessorKey: 'status',
        align: 'center',
        Cell: () => (
          <Tooltip title="Classé">
            <div className={`roundcolor bg-grey`}></div>
          </Tooltip>
        ),
      },
      {
        header: columnTitles.course,
        accessorKey: 'course',
        Cell: ({ cell }) => <div>{templates.themeSubjects.find(t => t._id === cell.getValue())?.name}</div>,
      },
      {
        header: columnTitles.level,
        accessorKey: 'level',
      },
      {
        header: columnTitles.volunteers,
        accessorKey: 'volunteers',
        Cell: ({ cell }) => (
          <div>
            {cell
              .getValue()
              .map(v => templates.volunteers.find(s => s._id === v)?.general_information?.last_name)
              .join(' - ')}
          </div>
        ),
      },
      {
        header: columnTitles.students,
        accessorKey: 'students',
        Cell: ({ cell }) => (
          <div>
            {cell
              .getValue()
              .map(v => templates.students.find(s => s._id === v)?.general_information?.last_name)
              .join(' - ')}
          </div>
        ),
      },
    ],
    [
      columnTitles.course,
      columnTitles.level,
      columnTitles.students,
      columnTitles.volunteers,
      templates.students,
      templates.volunteers,
      templates.themeSubjects,
    ],
  );

  return (
    <Paper>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        enableDensityToggle={false}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 25, 50, 100, 150, { label: 'All', value: data?.length ?? 0 }],
        }}
        muiTableBodyCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            fontWeight: 'normal',
            fontSize: '110%',
          },
        }}
        positionActionsColumn="last"
        positionGlobalFilter="left" //show the global filter on the left side of the top toolbar
        initialState={{
          showGlobalFilter: true, //show the global filter by default
          density: 'compact',
          pagination: {
            pageSize: 25,
          },
        }}
        enableRowActions={true}
        renderRowActions={({ row }, index) => {
          return (
            <ConfirmDialog
              onConfirm={() => onReset(row.original._id)}
              button={({ onClick }) => (
                <IconButton size="small" onClick={onClick}>
                  <Replay color="primary" />
                </IconButton>
              )}
            >
              Réhabilité ce match
            </ConfirmDialog>
          );
        }}
        enableToolbarInternalActions={true}
        renderTopToolbarCustomActions={() => {
          return (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircle />}
              onClick={() => history.push(paths.front.matching.create)}
            >
              {intl.button.add}
            </Button>
          );
        }}
        tableInstanceRef={tableRef}
        autoResetPageIndex={false}
        {...tableState}
      />
    </Paper>
  );
}

const ClassifiedMatching = injectIntl(ClassifiedMatchingTable);

export { ClassifiedMatching };
