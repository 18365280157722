import { Box } from '@mui/material';
import { Loader } from 'components';
import { useConfigContext } from 'hooks';
import { injectIntl } from 'react-intl';
import { PositionedWorkshopActioner, PositionedWorkshopUtils } from 'services/positionedWorkshop';
import { PositionedWorkshopForm } from './PositionedWorkshopForm';

function PositionedWorkshopCreateComponent({ intl, ...props }) {
  const { campaign, site } = useConfigContext();
  function createForm(templates, values = {}, participants = []) {
    return <PositionedWorkshopForm templates={templates} values={values} participants={participants} mode="create" />;
  }

  function renderCreateForm(render) {
    const promises = [PositionedWorkshopUtils.getPositionedWorkshopTemplates(campaign, site)];
    if (props.location.state?.duplicate) {
      promises.push(PositionedWorkshopActioner.get(props.location.state.id));
      promises.push(PositionedWorkshopActioner.entitiesList(props.location.state.id));
    }
    Promise.all(promises).then(([templates, values, participants]) => {
      if (values === undefined) {
        render(createForm(templates));
      } else {
        delete values._id;
        delete values.positioned_date;
        render(createForm(templates, values, participants));
      }
    });
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="full-width">
      <h2>{intl.messages.scenes.positionedWorkshop.create.title}</h2>
      <Loader render={renderCreateForm} />
    </Box>
  );
}

const PositionedWorkshopCreate = injectIntl(PositionedWorkshopCreateComponent);

export { PositionedWorkshopCreate };
