import { Toolbar, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import GlobalFilter from '../GlobalFilter/GlobalFilter';
import { ToolBarActions } from './components';
import './TableToolBar.css';

function TableToolBarComponent(props) {
  const { title, selectedRowIds, preGlobalFilteredRows, setGlobalFilter, globalFilter, actions, filters, useSearch } =
    props;
  const numSelected = useMemo(() => {
    return selectedRowIds !== undefined ? selectedRowIds.length : 0;
  }, [selectedRowIds]);
  return (
    <Toolbar>
      {title && <Typography variant="h6">{title}</Typography>}
      <>
        {filters.length > 0 && (
          <>
            <div className="spacer" />
            {filters.map((f, index) => (
              <div key={index} className="filters">
                {f.render('Filter')}
              </div>
            ))}
          </>
        )}
      </>
      <div className="spacer" />
      {useSearch && (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      {numSelected !== 0 && <div className="spacer-small" />}
      <ToolBarActions actions={actions} selectedRowIds={selectedRowIds} />
    </Toolbar>
  );
}

TableToolBarComponent.propTypes = {
  title: PropTypes.string,
  numSelected: PropTypes.number,
};

const TableToolBar = injectIntl(TableToolBarComponent);

export { TableToolBar };
