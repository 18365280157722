import {
  AccessibilityNew,
  AccountCircle,
  BarChart,
  CalendarToday,
  CalendarViewDay,
  DateRange,
  Group,
  Home,
  HomeWork,
  ImportContacts,
  Museum,
  School,
  Settings,
  Subject,
} from '@mui/icons-material';
import { paths } from 'routes';
import { lacleStore } from 'store';

const getNavLinks = () => {
  const customNames = lacleStore.getState().I18n.messages.components.appframe.navbar;

  const navLinks = [
    {
      label: customNames.home,
      link: paths.front.default,
      Icon: Home,
    },
    {
      label: customNames.settings,
      Icon: Settings,
      items: [
        {
          label: customNames.users,
          Icon: AccountCircle,
          link: paths.front.user.home,
        },
        {
          label: customNames.campaign,
          Icon: CalendarViewDay,
          link: paths.front.campaign.home,
        },
        {
          label: customNames.site,
          Icon: Museum,
          link: paths.front.site.home,
        },
        {
          label: customNames.workshop,
          Icon: HomeWork,
          link: paths.front.workshop.home,
        },
        {
          label: customNames.subject,
          Icon: Subject,
          link: paths.front.subject.home,
        },
      ],
    },
    {
      label: customNames.gestion,
      Icon: ImportContacts,
      items: [
        {
          label: customNames.student,
          Icon: School,
          link: paths.front.student.home,
        },
        {
          label: customNames.volunteer,
          Icon: AccessibilityNew,
          link: paths.front.volunteer.home,
        },
        {
          label: customNames.positionedWorkshop,
          Icon: CalendarToday,
          link: paths.front.workshop.positionedWorkshop.home,
        },
        {
          label: customNames.matching,
          Icon: Group,
          link: paths.front.matching.home,
        },
        {
          label: customNames.timeline,
          Icon: DateRange,
          link: paths.front.timeline.home,
        },
        {
          label: 'Statistiques',
          Icon: BarChart,
          link: paths.front.stats.timeline,
        },
      ],
    },
  ];

  return navLinks;
};

export { getNavLinks };
