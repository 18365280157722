import { CheckBoxOutlined, Download, HourglassEmpty, RemoveRedEye } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, IconButton, Paper, useTheme } from '@mui/material';
import { ExportToCsv } from 'export-to-csv';
import { useConfigContext, useTableContext, useTableState } from 'hooks';
import MaterialReactTable from 'material-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { InterviewActioner } from 'services/interview';
import { MatchingActioner } from 'services/matching';
import { StudentActioner } from 'services/student';
import { ThemeSubjectActioner } from 'services/themeSubject';
import { TimelineActioner } from 'services/timeline';
import { VolunteerActioner } from 'services/volunteer';
import { tokens } from 'themes';

const TimelineStatsComponent = props => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState({ docs: {}, matchings: [], students: [], volunteers: [], loading: false });
  const tableRef = useTableContext();
  const tableState = useTableState('timeline_stats');
  const { campaign, site, config } = useConfigContext();
  useEffect(() => {
    if (data.loading) {
      Promise.all([
        TimelineActioner.getStats(campaign, site),
        MatchingActioner.list(campaign, site),
        StudentActioner.list(campaign, site),
        VolunteerActioner.list(campaign, site),
        ThemeSubjectActioner.list(),
        InterviewActioner.list(campaign),
      ]).then(([res, matchings, students, volunteers, courses, interviews]) => {
        setData({ docs: res, matchings, students, volunteers, courses, interviews, loading: false });
      });
    }
    return () => {};
  });

  useEffect(() => {
    setData(d => ({ docs: {}, matchings: [], students: [], volunteers: [], loading: true }));
  }, [config]);

  const columns = useMemo(
    () => [
      {
        header: 'Tandem',
        accessorFn: row => {
          const value = row.matching;
          const matching = data.matchings.find(m => m._id === value);
          return `${matching?.students
            .map(v => {
              const student = data.students.find(s => s._id === v);
              return `${student?.general_information?.first_name} ${student?.general_information?.last_name}`;
            })
            .join(' - ')} / ${matching?.volunteers
            .map(v => {
              const volunteer = data.volunteers.find(s => s._id === v);
              return `${volunteer?.general_information?.first_name} ${volunteer?.general_information?.last_name}`;
            })
            .join(' - ')}`;
        },
      },
      {
        header: 'BI',
        accessorFn: row => {
          const value = row.matching;
          const matching = data.matchings.find(m => m._id === value);
          const volunteer = data.volunteers.find(s => s._id === matching?.volunteers[0]);
          return data.interviews.find(i => i.interviewed_id === volunteer?._id && i.type === 'FOLLOW_BI')
            ? true
            : false;
        },
        Cell: ({ cell }) => {
          const hasBi = cell.getValue();
          if (!hasBi) {
            return <HourglassEmpty sx={{ color: colors.redAccent[500] }} />;
          }
          return <CheckBoxOutlined sx={{ color: colors.greenAccent[400] }} />;
        },
        maxSize: 50,
        size: 50,
        enableMultiSort: true,
      },
      {
        header: 'Cours',
        accessorKey: 'cours',
        Cell: ({ cell }) => {
          const matching = data.matchings.find(m => m._id === cell.row.original.matching);
          return `${matching.day ?? 'Inconnu'} de ${matching.start_hour} à ${matching.end_hour}`;
        },
      },
      {
        header: 'Nom du cours',
        accessorKey: 'type',
        Cell: ({ cell }) => {
          const matching = data.matchings.find(m => m._id === cell.row.original.matching);
          return data.courses?.find(c => c._id === matching?.course)?.name ?? 'Inconnu';
        },
        maxSize: 100,
        size: 100,
      },
      {
        header: 'Dernier cours fait',
        accessorKey: 'lastCourse',
        Cell: ({ cell }) => (cell.getValue() ? moment(cell.getValue()).format('DD-MM-YYYY HH:mm') : 'Jamais'),
        maxSize: 100,
        size: 100,
        enableMultiSort: true,
      },
      {
        header: 'Heures faites',
        accessorKey: 'hoursDone',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return `${Math.trunc(value)}h${value - Math.trunc(value) !== 0 ? `${(value - Math.trunc(value)) * 60}` : ''}`;
        },
        maxSize: 100,
        size: 100,
        enableMultiSort: true,
      },
      {
        header: 'Heures manquées',
        accessorKey: 'hoursMissed',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return `${Math.trunc(value)}h${value - Math.trunc(value) !== 0 ? `${(value - Math.trunc(value)) * 60}` : ''}`;
        },
        maxSize: 100,
        size: 100,
        enableMultiSort: true,
      },
      {
        header: 'Heures rattrapées',
        accessorKey: 'hoursMadeUp',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return `${Math.trunc(value)}h${value - Math.trunc(value) !== 0 ? `${(value - Math.trunc(value)) * 60}` : ''}`;
        },
        maxSize: 100,
        size: 100,
        enableMultiSort: true,
      },
      {
        header: 'Total',
        accessorKey: 'totalHours',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return `${Math.trunc(value)}h${value - Math.trunc(value) !== 0 ? `${(value - Math.trunc(value)) * 60}` : ''}`;
        },
        maxSize: 100,
        size: 100,
        enableMultiSort: true,
      },
    ],
    [
      colors.greenAccent,
      colors.redAccent,
      data.courses,
      data.interviews,
      data.matchings,
      data.students,
      data.volunteers,
    ],
  );

  const csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: ',',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map(c => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(
      data.docs?.timelineStats.map(row => {
        const value = row.matching;
        const matching = data.matchings.find(m => m._id === value);
        const Tandem = `${matching?.students
          .map(v => {
            const student = data.students.find(s => s._id === v);
            return `${student?.general_information?.first_name} ${student?.general_information?.last_name}`;
          })
          .join(' - ')} / ${matching?.volunteers
          .map(v => {
            const volunteer = data.volunteers.find(s => s._id === v);
            return `${volunteer?.general_information?.first_name} ${volunteer?.general_information?.last_name}`;
          })
          .join(' - ')}`;
        const volunteer = data.volunteers.find(s => s._id === matching?.volunteers[0]);
        const BI = data.interviews.find(i => i.interviewed_id === volunteer?._id && i.type === 'FOLLOW_BI')
          ? 'OUI'
          : 'NON';
        const type = data.courses?.find(c => c._id === matching?.course)?.name ?? 'Inconnu';
        const cours = `${matching.day ?? 'Inconnu'} de ${matching.start_hour} à ${matching.end_hour}`;
        const lastCourse = row.lastCourse ? moment(row.lastCourse).format('DD-MM-YYYY HH:mm') : 'Jamais';
        return {
          Tandem,
          BI,
          cours,
          type,
          lastCourse,
          hoursDone: row.hoursDone,
          hoursMissed: row.hoursMissed,
          hoursMadeUp: row.hoursMadeUp,
          totalHours: row.totalHours,
        };
      }),
    );
  };

  if (data.loading) {
    return (
      <Paper className="padding-small">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="height-circular"
        >
          <CircularProgress color="primary" />
        </Grid>
      </Paper>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Paper>
        <Box sx={{ p: 2 }}>
          <MaterialReactTable
            muiTablePaperProps={{
              elevation: 0, //change the mui box shadow
            }}
            columns={columns}
            data={data.docs?.timelineStats ?? []}
            enableMultiSort={true}
            enableDensityToggle={false}
            muiTablePaginationProps={{
              rowsPerPageOptions: [
                10,
                25,
                50,
                100,
                150,
                { label: 'All', value: data?.docs?.timelineStats?.length ?? 0 },
              ],
            }}
            muiTableBodyCellProps={{
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                fontWeight: 'normal',
                fontSize: '110%',
              },
            }}
            positionActionsColumn="last"
            positionGlobalFilter="left" //show the global filter on the left side of the top toolbar
            initialState={{
              density: 'compact',
              showGlobalFilter: true, //show the global filter by default
              pagination: {
                pageSize: 25,
              },
            }}
            enableRowActions={true}
            renderRowActions={({ row }, index) => {
              return (
                <IconButton
                  key={index}
                  size="small"
                  component={Link}
                  target="_blank"
                  to={paths.front.matching.profil.replace(':id', row.original.matching)}
                >
                  <RemoveRedEye />
                </IconButton>
              );
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<Download />}
                variant="contained"
              >
                Télécharger le csv
              </Button>
            )}
            tableInstanceRef={tableRef}
            autoResetPageIndex={false}
            {...tableState}
          />
        </Box>
      </Paper>
    </Box>
  );
};

const TimelineStats = TimelineStatsComponent;

export { TimelineStats };
