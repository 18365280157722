import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useLocalStorage } from 'hooks';
import PropTypes from 'prop-types';
import './TabAppBar.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const position = value === index ? '' : value > index ? 'lock-left' : 'lock-right';

  return (
    <div
      className={`tab-app-panel ${position}`}
      role="tabpanel"
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

function TabAppBar(props) {
  const { children, className, containerClassName, variant, initIndex, indicatorColor, textColor, stateName } = props;
  const [value, setValue] = useLocalStorage(stateName, initIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const All_LinkTab = children.map((child, index) => (
    <LinkTab
      key={index}
      label={child?.props?.tabLabel ?? ''}
      id={`nav-tab-${index}`}
      aria-controls={`nav-tabpanel-${index}`}
    />
  ));

  const All_TabPanel = children.map((child, index) => (
    <TabPanel key={index} value={value} index={index}>
      {child}
    </TabPanel>
  ));

  return (
    <div className={`tab-app-container ${containerClassName}`}>
      <AppBar position="static">
        <Tabs
          className={`tab-app-background ${className}`}
          variant={variant}
          value={value}
          onChange={handleChange}
          indicatorColor={indicatorColor}
          textColor={textColor}
        >
          {All_LinkTab}
        </Tabs>
      </AppBar>
      {All_TabPanel}
    </div>
  );
}

TabAppBar.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  initIndex: PropTypes.number,
  variant: PropTypes.string,
  indicatorColor: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.array,
};

TabAppBar.defaultProps = {
  containerClassName: '',
  className: '',
  initIndex: 0,
  variant: 'fullWidth',
  indicatorColor: 'secondary',
  textColor: 'secondary',
  children: [],
};

export { TabAppBar };
