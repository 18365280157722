import { Typography } from '@mui/material';
import { TableFormAndDisplay } from 'components/TableFormAndDisplay';
import { injectIntl } from 'react-intl';
import { PositionedWorkshopUtils } from 'services/positionedWorkshop';
import { ArrayUtils } from 'tools';
import '../style/PositionedWorkshop.css';
import { AddEntitiesList } from './AddEntitiesList';

function EntitiesListFormComponent({ data, setData, disabled, idBigWorkshop, templates, mode, ...props }) {
  const intl = props.intl.messages.scenes.positionedWorkshop.edit;
  const labels = intl.labels;
  const entities = PositionedWorkshopUtils.getInteressedEntities(
    idBigWorkshop,
    ArrayUtils.addTagArray([
      ['student', templates.students],
      ['volunteer', templates.volunteers],
    ]),
  );

  function notAlreadyIn(tab = [], data = []) {
    return tab.filter(elem => !data.map(e => e._id).includes(elem._id));
  }

  function deleteEntities(index) {
    return () => {
      const newEntities = [...data];
      newEntities.splice(index, 1);
      setData(newEntities);
    };
  }

  function editEntities(newData, index) {
    let newEntities = [...data];
    newEntities[index] = newData;
    setData(newEntities);
  }

  function addEntities(newData) {
    let newEntities = [...data];
    newEntities = newEntities.concat(newData);
    setData(newEntities);
  }

  const columns = [
    {
      id: 'Nom',
      align: 'left',
      label: labels.name,
      render: row => (
        <Typography variant="subtitle1">
          {row.general_information.last_name + '  ' + row.general_information.first_name}
        </Typography>
      ),
    },
  ];

  return (
    <div className="padding-small full-width marginB20 entities-list-form">
      <TableFormAndDisplay
        columns={columns}
        rows={data}
        title={intl.titletab}
        table={intl.table}
        mode={mode}
        useEdit={true}
        onDelete={deleteEntities}
        disabled={disabled}
      >
        <AddEntitiesList
          addEntities={addEntities}
          entities={notAlreadyIn(entities, data)}
          editEntities={editEntities}
        />
      </TableFormAndDisplay>
    </div>
  );
}

const EntitiesListForm = injectIntl(EntitiesListFormComponent);

export { EntitiesListForm };
