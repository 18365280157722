const InterviewEnums = {
  getTypes: () => {
    const types = [
      {
        label: 'Scolaire',
        value: 'SCHOOL',
      },
      {
        label: "Permanence d'écoute",
        value: 'LISTENING',
      },
      {
        label: 'Médiation Sociale',
        value: 'SOCIALE',
      },
      {
        label: 'Suivi',
        value: 'FOLLOW',
      },
      {
        label: 'Suivi - Bilan intermédiaire',
        value: 'FOLLOW_BI',
      },
    ];
    return types;
  },
  getThemes: () => {
    const themes = [
      {
        label: 'Sortie Positive',
        value: 'POSITIVE_OUTPUT',
      },
      {
        label: 'En cours',
        value: 'IN_PROGRESS',
      },
      {
        label: 'Sans suite',
        value: 'STOPPED',
      },
    ];
    return themes;
  },
};

export { InterviewEnums };
