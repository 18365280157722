import { Box, Grid } from '@mui/material';
import { RollingButton } from 'components/RollingButton';
import { TextInput } from 'components/TextInput';
import moment from 'moment';
import 'moment/locale/fr';
import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { MatchingEnums, MatchingUtils } from 'services/matching';
import { ArrayUtils, AvailabilitiesUtils, ValueUtils } from 'tools';
import './Matching.css';
import { EntitySelector, MatchingSubmitButton, SelectDrag } from './components';

const vod = ValueUtils.valueOrDefault;

const TYPES = MatchingEnums.getTypes();

function getRightList(array, selected) {
  if (selected.length === 0 || !selected) {
    return {
      items: array,
      selected: [],
    };
  } else {
    let newArray = [...array];
    const newSelected = newArray.filter(e => selected.includes(e._id.toString()));
    newArray = newArray.filter(e => !selected.includes(e._id.toString()));
    return {
      items: newArray,
      selected: newSelected,
    };
  }
}

function checkMatch(id, templates, id_match, withFilter) {
  if (withFilter === true) {
    return templates.matchings.filter(m => {
      const hasTime = m.expired_date ? moment(m?.expired_date).diff(moment()) > 0 : true;
      const isUsed = m.status === 'LOCK' || m.status === 'POSITIONED';
      const isEditMatch = m._id === id_match;
      return (m.students.includes(id) || m.volunteers.includes(id)) && hasTime && isUsed && !isEditMatch;
    });
  }
  return templates.matchings;
}

function AvailabilitiesChanges(ai, id, templates, id_match, withFilter) {
  let new_ai = [...ai];
  new_ai = AvailabilitiesUtils.allDaysTransform(new_ai);
  new_ai = AvailabilitiesUtils.excludeAvailabilities(new_ai, checkMatch(id, templates, id_match, withFilter));
  new_ai = new_ai.map(e => Object.assign({ ...e }, { select: true, label: `${e.day}-${e.start_hour}-${e.end_hour}` }));
  return AvailabilitiesUtils.sortAvailabilities(new_ai);
}

function selectEntity(e, values = {}, templates = {}, withFilter) {
  if (e === undefined) {
    return undefined;
  }
  if (e.availabilities_information === undefined) {
    e.availabilities_information = [];
  }
  const newEntity = { ...e };
  newEntity.availabilities_information = AvailabilitiesChanges(
    newEntity.availabilities_information,
    newEntity._id,
    templates,
    values._id,
    withFilter,
  );
  if (newEntity.entity_type === TYPES.STUDENT) {
    if (newEntity.wanted_subject === undefined) {
      newEntity.wanted_subject = [];
    }
    newEntity.wanted_subject = newEntity.wanted_subject.map(s => Object.assign({}, { label: s, select: false }));
    const index = newEntity.wanted_subject.findIndex(elem => elem.label === values.course);
    if (newEntity.wanted_subject[0] !== undefined) {
      newEntity.wanted_subject[index > 0 ? index : 0].select = true;
    }
  }
  if (newEntity.entity_type === TYPES.VOLUNTEER) {
    if (newEntity.proposed_subject === undefined) {
      newEntity.proposed_subject = [];
    }
    newEntity.proposed_subject = newEntity.proposed_subject.map(s => Object.assign({}, { label: s, select: false }));
    const focus_subject = templates?.subjects?.filter(s => s.name === values.course && s.level === values.level) ?? [];
    const index = newEntity.proposed_subject.findIndex(elem =>
      focus_subject.map(fs => fs._id.toString()).includes(elem.label),
    );
    if (newEntity.proposed_subject[0] !== undefined) {
      newEntity.proposed_subject[index > 0 ? index : 0].select = true;
    }
  }
  return newEntity;
}

function getInitialValues(values = {}, templates = {}, withFilter) {
  const initial = {
    duration: vod(values.duration, 60),
    status: vod(values.status, 'DRAFT'),
    id: vod(values._id, ''),
    course: vod(values.course, ''),
    level: vod(values.level, ''),
    day: vod(values.day, ''),
    start_hour: vod(values.start_hour, ''),
    end_hour: vod(values.end_hour, ''),
    expired_date: vod(values.expired_date, ''),
    returned_file: vod(values.returned_file, false),
    comment: vod(values.comment, ''),
    errors: {
      selected: false,
    },
  };
  let newEntity = {};
  if (values.focus !== undefined && values.focus_type !== undefined) {
    if (values.focus_type === TYPES.STUDENT) {
      newEntity = templates?.students?.find(s => s._id.toString() === values.focus) ?? undefined;
    }
    if (values.focus_type === TYPES.VOLUNTEER) {
      newEntity = templates?.volunteers?.find(s => s._id.toString() === values.focus) ?? undefined;
    }
    newEntity.entity_type = values.focus_type;
    newEntity = selectEntity(newEntity, values, templates, withFilter);
    return {
      ...initial,
      entity: newEntity,
      selectedItems: getInitialSelectedItems(newEntity, templates, values),
    };
  }
  return initial;
}

function getInitialSelectedItems(entity, templates, values) {
  if (entity === undefined) {
    return {};
  }
  if (entity.entity_type === TYPES.STUDENT) {
    return getRightList(vod(templates.volunteers, []), vod(values?.volunteers, []));
  }
  if (entity.entity_type === TYPES.VOLUNTEER) {
    return getRightList(vod(templates.students, []), vod(values?.students, []));
  }
}

function MatchingFormComponent(props) {
  const { intlData, values, templates, reload, mode } = props;
  const [withFilter, setWithFilter] = useState(true);
  const initialValues = getInitialValues(values, templates, withFilter);
  const [fields, setFields] = useState({
    ...initialValues,
  });

  const isLocked = fields.status === 'POSITIONED' || fields.status === 'LOCK' || fields.status === 'CLOSED';
  const all_status = MatchingEnums.getStatus(isLocked);

  function setFieldFunction(name) {
    return value => {
      setFields(f => ({ ...f, [name]: value }));
    };
  }

  function getLevel(elem) {
    if (elem === undefined) {
      return undefined;
    }
    if (elem?.level?.initial_level !== undefined && elem?.level?.initial_level !== '') {
      return MatchingUtils.translateLevel(elem.level.initial_level);
    }
    if (elem?.school?.level !== undefined && elem?.school?.level !== '') {
      return MatchingUtils.translateLevel(elem.school.level);
    }
    return undefined;
  }

  useEffect(() => {
    setFieldFunction('selectedItems')(getInitialSelectedItems(fields.entity, templates, values));
  }, [fields.entity, templates, values]);

  const intl = intlData.messages.scenes.matching.form;
  const common_ai =
    fields.entity !== undefined
      ? AvailabilitiesUtils.groupCommonAvailabilities([...(fields.selectedItems.selected ?? []), fields.entity])
      : [];
  const number_person =
    fields.entity !== undefined ? [...(fields.selectedItems.selected ?? []), fields.entity].length : 0;

  function entityHasSubjectAndLevel(elem, withFilter) {
    if (fields.entity === undefined || elem === undefined) {
      return false;
    }
    if (fields.entity.entity_type === TYPES.STUDENT) {
      const level = getLevel(fields.entity)?.toUpperCase();
      const student_subject = fields.entity.wanted_subject
        .filter(s => s.select)
        .map(s => s.label)
        .shift();
      let possible_subject = [];
      if (withFilter === true) {
        possible_subject = templates.subjects.filter(
          s => s.name === student_subject && s.level.toUpperCase() === level,
        );
      } else {
        possible_subject = templates.subjects.filter(s => s.name === student_subject);
      }
      const inter = elem.proposed_subject.filter(ps => possible_subject.map(s => s._id).includes(ps));
      return inter.length > 0;
    }
    if (fields.entity.entity_type === TYPES.VOLUNTEER) {
      const level = getLevel(elem)?.toUpperCase();
      const volunteer_subject = fields.entity.proposed_subject
        .filter(ps => ps.select)
        .map(s => templates?.subjects?.find(ts => ts._id === s.label) ?? 'Inconnu')
        .shift();
      let inter = [];
      if (withFilter === true) {
        inter = elem.wanted_subject.filter(
          ws => ws === volunteer_subject.name && level === volunteer_subject?.level?.toUpperCase(),
        );
      } else {
        inter = elem.wanted_subject.filter(ws => ws === volunteer_subject.name);
      }
      return inter.length > 0;
    }
    return false;
  }

  function getCommonAvailabilities(elem) {
    if (!ArrayUtils.isValidArray(elem) || !ArrayUtils.isValidArray(fields.entity.availabilities_information)) {
      return [];
    }
    const focus_ai = fields.entity.availabilities_information.filter(ai => ai.select);
    const common_availabilities = AvailabilitiesUtils.commonAvailabilities(elem, focus_ai);
    return common_availabilities;
  }

  function hasAvailabilities(elem, withFilter) {
    const new_ai = AvailabilitiesUtils.excludeAvailabilities(
      elem.availabilities_information,
      checkMatch(elem._id, templates, fields.id, withFilter),
    );
    const common_availabilities = getCommonAvailabilities(new_ai);
    return common_availabilities.filter(
      c_ai => AvailabilitiesUtils.diffTime(c_ai.start_hour, c_ai.end_hour) <= -1 * fields.duration,
    );
  }

  function isShow(elem, withFilter) {
    if (withFilter === true) {
      return entityHasSubjectAndLevel(elem, withFilter) && hasAvailabilities(elem, withFilter).length > 0;
    }
    return true;
  }

  return (
    <Box className="marginTop position-relative" alignItems="center">
      <Grid container item xs={1} sm={1} className="topright20">
        <RollingButton
          labelId="Status"
          label="Status"
          selected={fields.status}
          setSelected={setFieldFunction('status')}
          setReturnedFiled={setFieldFunction('returned_file')}
          returnedFile={fields.returned_file}
          items={all_status}
          isLocked={isLocked}
        />
      </Grid>
      <EntitySelector
        entity={fields.entity}
        setEntity={setFieldFunction('entity')}
        selectEntity={selectEntity}
        templates={templates}
        withFilter={withFilter}
        setWithFilter={setWithFilter}
        comment={fields.comment}
        setComment={setFieldFunction('comment')}
      />
      <Grid item className="margin-auto marginB20 come-in-bottom" xs={3} sm={3} md={3}>
        {isLocked ? (
          <div>
            <h3>
              Ce cours a lieu le {fields.day} de {fields.start_hour} à {fields.end_hour}
            </h3>
          </div>
        ) : (
          <TextInput
            name="duration"
            label="Durée du cours (minutes)"
            value={fields.duration}
            type="number"
            setField={setFieldFunction('duration')}
            fullWidth={false}
          />
        )}
      </Grid>

      {fields.entity !== undefined ? (
        <>
          <Box
            className="marginB20 position-relative come-in-bottom"
            display="flex"
            justifyContent="space-around"
            alignItems="end"
            flexWrap="wrap"
          >
            <div className="warning-common-ai">
              {fields.entity !== undefined && number_person > 2 && (
                <div>
                  <h5 className="marginB20">Disponibilités commune aux {number_person} personnes : </h5>
                  {common_ai.map(c_ai => (
                    <h6 key={`${c_ai.day}-${c_ai.start_hour}-${c_ai.end_hour}`} className="dragger-ai">
                      {c_ai.day?.toUpperCase()}: {c_ai.start_hour}-{c_ai.end_hour}
                    </h6>
                  ))}
                  {common_ai.length === 0 ? (
                    <h5 className="color-red">ATTENTION : Les membres de ce matching n'ont pas d'horaires en commun</h5>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
            <SelectDrag
              fields={fields.selectedItems}
              setFields={setFieldFunction('selectedItems')}
              isShow={isShow}
              hasAvailabilities={hasAvailabilities}
              title={fields.entity.entity_type === TYPES.STUDENT ? intl.section.volunteers : intl.section.students}
              entity_type={fields.entity.entity_type === TYPES.STUDENT ? TYPES.VOLUNTEER : TYPES.STUDENT}
              errors={fields.errors}
              withFilter={withFilter}
            />
          </Box>
          <MatchingSubmitButton
            userID={props.userID}
            templates={templates}
            fields={fields}
            thisId={vod(values?._id, '')}
            setFields={setFields}
            initialValues={initialValues}
            reload={reload}
            mode={mode}
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}
const mapStateToProps = state => ({
  userID: state.Authentication.userID,
});

const MatchingForm = connect(mapStateToProps)(injectIntl(MatchingFormComponent));

export { MatchingForm };
