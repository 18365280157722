import { toast } from 'components';
import { lacleStore } from 'store';
import { TimelineApi } from './api';

const TimelineActioner = {
  getRooms: site => {
    return TimelineApi.getRooms(site)
      .then(res => res)
      .catch(err => {
        const messages = lacleStore.getState().I18n.messages.toast.error;
        toast.error(messages.unableToRetrieveRoomsInfos);
        throw err;
      });
  },
  list: (date, campaign, site) => {
    return TimelineApi.getList(date, campaign, site)
      .then(res => res)
      .catch(err => {
        const messages = lacleStore.getState().I18n.messages.toast.error;
        toast.error(messages.unableToRetrieveSubjectList);
        throw err;
      });
  },
  matchList: id => {
    return TimelineApi.getMatchList(id)
      .then(res => res)
      .catch(err => {
        const messages = lacleStore.getState().I18n.messages.toast.error;
        toast.error(messages.unableToRetrieveSubjectList);
        throw err;
      });
  },
  studentList: id => {
    return TimelineApi.getStudentList(id)
      .then(res => res)
      .catch(err => {
        const messages = lacleStore.getState().I18n.messages.toast.error;
        toast.error(messages.unableToRetrieveSubjectList);
        throw err;
      });
  },
  volunteerList: id => {
    return TimelineApi.getVolunteerList(id)
      .then(res => res)
      .catch(err => {
        const messages = lacleStore.getState().I18n.messages.toast.error;
        toast.error(messages.unableToRetrieveSubjectList);
        throw err;
      });
  },
  create: fields => {
    return TimelineApi.create(fields)
      .then(res => {
        const messages = lacleStore.getState().I18n.messages.toast.success;
        toast.success(messages.successFullyCreated);
        return res;
      })
      .catch(err => {
        const messages = lacleStore.getState().I18n.messages.toast.error;
        const msg = err.description ? err.description : messages.errorOccured;
        toast.error(msg);

        throw err;
      });
  },
  update: (id, fields) => {
    return TimelineApi.update(id, fields)
      .then(() => {
        const messages = lacleStore.getState().I18n.messages.toast.success;
        toast.success(messages.successFullyEdited);
      })
      .catch(err => {
        const messages = lacleStore.getState().I18n.messages.toast.error;
        const msg = err.description ? err.description : messages.errorOccured;
        toast.error(msg);

        throw err;
      });
  },
  delete: id => {
    return TimelineApi.delete(id)
      .then(() => {
        const messages = lacleStore.getState().I18n.messages.toast.success;
        toast.success(messages.successFullyDeleted);
      })
      .catch(err => {
        const messages = lacleStore.getState().I18n.messages.toast.error;
        const msg = err.description ? err.description : messages.errorOccured;
        toast.error(msg);

        throw err;
      });
  },
  deleteAll: (id, startTime) => {
    return TimelineApi.deleteAll(id, startTime)
      .then(res => {
        const messages = lacleStore.getState().I18n.messages.toast.success;
        toast.success(messages.successFullyDeleted);
        return res;
      })
      .catch(err => {
        const messages = lacleStore.getState().I18n.messages.toast.error;
        const msg = err.description ? err.description : messages.errorOccured;
        toast.error(msg);

        throw err;
      });
  },
  getStats: (campaign, site) => {
    return TimelineApi.stats(campaign, site)
      .then(res => res)
      .catch(err => {
        const messages = lacleStore.getState().I18n.messages.toast.error;
        toast.error(err.description ? err.description : messages.errorOccured);
        throw err;
      });
  },
};

export { TimelineActioner };
