import { Box, CssBaseline } from '@mui/material';
import './AppFrame.css';
import { Header, Navbar } from './components';
import { getNavLinks } from './navLinks';

function removeUnauthorizedNavLinks(navLinks) {
  const authorizedLinks = [];
  for (const navigationLink of navLinks) {
    const link = { ...navigationLink };
    if (navigationLink.items) {
      link.items = removeUnauthorizedNavLinks(navigationLink.items);
    }
    authorizedLinks.push(link);
  }
  return authorizedLinks;
}

function AppFrameComponent(props) {
  const linksToSend = removeUnauthorizedNavLinks(getNavLinks());

  return (
    <>
      <CssBaseline />
      <div className="app">
        <Navbar navLinks={linksToSend} />
        <main className={'content'}>
          <Header campaigns={props.campaigns} sites={props.sites} />
          <Box p={1}>{props.children}</Box>
        </main>
      </div>
    </>
  );
}
const AppFrame = AppFrameComponent;

export { AppFrame };
