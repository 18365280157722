import { AddCircle, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, Paper } from '@mui/material';
import { EnhancedTable, Loader } from 'components';
import { useConfigContext } from 'hooks';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { ComonUtils } from 'services/comon';
import { InterviewActioner, InterviewEnums } from 'services/interview';
import { ArrayToSelector } from 'tools';
import './Interview.css';

function InterviewTable({ interviewedId, type, templates, interviewFound, intlData }) {
  const [data] = useState(useMemo(() => interviewFound, [interviewFound]));
  const [skipPageReset] = useState(false);
  const history = useHistory();

  const intl = intlData.messages.scenes.interview.list;
  const commonDefaultTitles = intlData.messages.scenes.Table;
  const columnTitles = intl.columnTitles;
  const themes = ArrayToSelector.getArray(templates.themes);
  const types = InterviewEnums.getTypes();

  const columns = useMemo(
    () => [
      {
        Header: columnTitles.title,
        accessor: 'title',
      },
      {
        Header: 'Campagne',
        accessor: 'campaign',
        Cell: ({ value }) => templates.campaigns.find(t => t._id === value)?.name ?? 'Inconnu',
      },
      {
        Header: columnTitles.type,
        accessor: 'type',
        Cell: ({ value }) => types.find(t => t.value === value)?.label ?? 'Inconnu',
      },
      {
        Header: 'Le',
        accessor: 'created_at',
        Cell: ({ value }) => moment(value).format('DD/MM/YYYY'),
      },
      {
        Header: columnTitles.school_subject,
        accessor: 'school_subject',
        Cell: ({ value }) => themes.find(t => t.value === value)?.label ?? 'Inconnu',
      },
      {
        Header: columnTitles.details,
        accessor: 'details',
        Cell: ({ value }) => (value.length > 50 ? `${value.slice(0, 50)} ...` : value.slice(0, 50)),
      },
      {
        Header: commonDefaultTitles.actions,
        accessor: '_id',
        disableSortBy: true,
        Cell: ({ value }) => (
          <IconButton
            size="small"
            component={Link}
            to={{ pathname: paths.front.interview.edit.replace(':id', value), state: { templates: templates } }}
          >
            <Edit />
          </IconButton>
        ),
      },
    ],
    [
      columnTitles.school_subject,
      columnTitles.title,
      columnTitles.details,
      commonDefaultTitles.actions,
      templates,
      themes,
      types,
      columnTitles.type,
    ],
  );

  const actions = [
    {
      render: () => (
        <Box component="span" sx={{ p: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={() =>
              history.push({
                pathname: paths.front.interview.create,
                state: {
                  interviewed_id: interviewedId,
                  type: type,
                  templates: templates,
                },
              })
            }
          >
            {intl.button.add}
          </Button>
        </Box>
      ),
      isFreeAction: true,
    },
  ];

  return (
    <EnhancedTable
      title={intl.title.replace(
        ':id',
        ComonUtils.getPorfilNames(interviewedId, templates.students.concat(templates.volunteers)),
      )}
      columns={columns}
      data={data}
      actions={actions}
      skipPageReset={skipPageReset}
      useMultipleSelect={false}
    />
  );
}

function InterviewListComponent({ interviewedId, type, campaign, ...props }) {
  const { campaign: idCampaign, site } = useConfigContext();

  function loadList() {
    return InterviewActioner.listByInterviewed(interviewedId)
      .then(docs => {
        const values = [...docs];
        return values;
      })
      .catch(err => {
        throw err;
      });
  }

  function tableList(values = [], templates) {
    return (
      <InterviewTable
        interviewedId={interviewedId}
        type={type}
        interviewFound={values}
        templates={templates}
        intlData={props.intl}
      />
    );
  }

  function renderCreateForm(render) {
    Promise.all([loadList(), ComonUtils.getInterviewTemplates(idCampaign, site)]).then(([values, templates]) => {
      render(tableList(values, templates));
    });
  }

  return (
    <Paper>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="full-width">
        <Loader render={renderCreateForm} />
      </Box>
    </Paper>
  );
}

const InterviewList = injectIntl(InterviewListComponent);

export { InterviewList };
