import { CircularProgress, Dialog, Grid } from '@mui/material';
import { useConfigContext } from 'hooks';
import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { MatchingActioner } from 'services/matching';
import { TimelineCreate } from './TimelineCreate';
import { TimelineEdit } from './TimelineEdit';
import { TimelineMove } from './TimelineMove';

function TimelineDialogComponent(props) {
  const { item, mode, openForm, setOpenForm } = props;
  const [loading, setLoading] = useState(true);
  const [matchings, setMatchings] = useState([]);
  const { campaign, site } = useConfigContext();
  useEffect(() => {
    setLoading(true);
  }, [openForm]);

  useEffect(() => {
    if (loading) {
      Promise.all([MatchingActioner.doneList(campaign, site)]).then(([matchs]) => {
        setMatchings(matchs);
        setLoading(false);
      });
    }
    return () => {};
  }, [loading, campaign, site]);

  return (
    <>
      <Dialog open={openForm} onClose={() => setOpenForm(false)} fullWidth maxWidth="md">
        {loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            className="height-circular"
          >
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          <div>
            {mode === 'create' ? (
              <TimelineCreate setOpen={setOpenForm} matchings={matchings} item={item} setLoading={setLoading} />
            ) : mode === 'edit' ? (
              <TimelineEdit setOpen={setOpenForm} item={item} />
            ) : mode === 'move' ? (
              <TimelineMove setOpen={setOpenForm} item={item} />
            ) : (
              ''
            )}
          </div>
        )}
      </Dialog>
    </>
  );
}

const TimelineDialog = injectIntl(TimelineDialogComponent);

export { TimelineDialog };
