import { Button, Grid } from '@mui/material';
import { useContext, useState } from 'react';
import { injectIntl } from 'react-intl';
import { TimelineActioner, TimelineContext } from 'services/timeline';

function TimelineMoveComponent(props) {
  const { setOpen, item } = props;

  const [lock, setLock] = useState(false);
  const context = useContext(TimelineContext);

  const new_item = {
    ...item,
    room: {
      _id: item.room,
    },
  };

  const intl = props.intl.messages.scenes.timeline;

  function editMoveItem(i) {
    setLock(true);
    TimelineActioner.update(i._id, i).then(docs => {
      if (docs !== null) {
        context.editItem(new_item);
        setLock(false);
        setOpen(false);
      }
    });
  }

  return (
    <Grid className="padding-small" justifyContent="center" container>
      <Grid className="marginB20 marginT20" justifyContent="space-evenly" container>
        {intl.move.replace(
          ':name',
          `${item.matching.volunteers[0].general_information.last_name} - ${item.matching.students.map(
            e => ` ${e.general_information.last_name}`,
          )}`,
        )}
      </Grid>
      <Grid justifyContent="flex-end" container>
        <Button onClick={() => editMoveItem({ ...item })} disabled={lock}>
          {intl.buttons.edit}
        </Button>
        <Button onClick={() => setOpen(false)} disabled={lock}>
          {intl.buttons.cancel}
        </Button>
      </Grid>
    </Grid>
  );
}

const TimelineMove = injectIntl(TimelineMoveComponent);

export { TimelineMove };
