import { Grid, Paper, Typography } from '@mui/material';
import { MultipleSelector } from 'components/MultipleSelector';
import { injectIntl } from 'react-intl';
import { ArrayToSelector, ValueUtils } from 'tools';

const vod = ValueUtils.valueOrDefault;

function WantedSubjectComponent(props) {
  const { data, setData, themeSubjects } = props;
  const wanted_subject = vod(data.wanted_subject, []);

  const intl = props.intl.messages.scenes.student.wanted_subject;

  function setFieldFunction() {
    return value => {
      const newWantedSubject = [...value];
      setData(newWantedSubject);
    };
  }
  return (
    <Paper className="padding-small full-width marginB20">
      <Typography variant="h5" gutterBottom>
        {intl.title}
      </Typography>
      <Grid container item xs={12} sm={12}>
        <MultipleSelector
          labelId="wanted_subject"
          label={intl.labels.wanted_subject}
          items={ArrayToSelector.getArray(themeSubjects)}
          selected={wanted_subject}
          setSelected={setFieldFunction()}
          className="full-width"
        />
      </Grid>
    </Paper>
  );
}

const WantedSubjectForm = injectIntl(WantedSubjectComponent);

export { WantedSubjectForm };
