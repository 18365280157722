import { Paper } from '@mui/material';
import { Loader } from 'components';
import { useConfigContext } from 'hooks';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { MatchingUtils } from 'services/matching';
import { MatchingForm } from './MatchingForm';

function MatchingCreateComponent(props) {
  const [, reload] = useState();
  const { campaign, site } = useConfigContext();
  const intl = props.intl.messages.scenes.matching.create;

  function editForm(templates) {
    return <MatchingForm intlData={props.intl} templates={templates} reload={() => reload({})} mode={'create'} />;
  }

  function renderEditForm(render) {
    MatchingUtils.getMatchingTemplates(campaign, site).then(templates => {
      render(editForm(templates));
    });
  }

  return (
    <Paper className="padding-small">
      <h2 className="text-centered">{intl.title}</h2>
      <Loader render={renderEditForm} />
    </Paper>
  );
}

const MatchingCreate = injectIntl(MatchingCreateComponent);

export { MatchingCreate };
