import { AccountBox, AddCircle, Check, Edit } from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import { TabAppBar } from 'components/TabAppBar';
import { TableProvider, useConfigContext, useTableContext, useTableState } from 'hooks';
import MaterialReactTable from 'material-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { MatchingEnums, MatchingUtils } from 'services/matching';
import { ArrayUtils } from 'tools';
import './Matching.css';
import { ClassifiedMatching } from './components';

function MatchingTable({ tableName, templates, matchingFound, intlData }) {
  const [data] = useState(useMemo(() => matchingFound, [matchingFound]));
  const history = useHistory();
  const tableRef = useTableContext();
  const tableState = useTableState(tableName);

  const status = MatchingEnums.getStatusColor();
  const intl = intlData.messages.scenes.matching.list;
  const columnTitles = intl.columnTitles;
  const columns = useMemo(
    () => [
      {
        header: '',
        accessorKey: 'status',
        align: 'center',
        Cell: ({ cell }) => {
          return (
            <Tooltip title={status?.find(elem => elem.value === cell.getValue())?.label ?? ''}>
              <div
                className={`roundcolor bg-${status?.find(elem => elem.value === cell.getValue())?.color ?? 'red'}`}
                style={{ opacity: '75% !important' }}
              ></div>
            </Tooltip>
          );
        },
        enableColumnFilter: false,
      },
      {
        header: 'Dossier rendu',
        accessorKey: 'returned_file',
        align: 'center',
        Cell: ({ cell }) => <>{cell.getValue() ? <Check style={{ color: 'green' }} /> : null}</>,
        enableColumnFilter: false,
      },
      {
        header: columnTitles.course,
        accessorKey: 'course',
        Cell: ({ cell }) => templates.themeSubjects.find(t => t._id === cell.getValue())?.name ?? '',
      },
      {
        header: columnTitles.volunteers,
        accessorFn: row => {
          return row.volunteers
            .map(v => {
              const volunteer = templates.volunteers.find(s => s._id === v);
              return `${volunteer?.general_information?.first_name} ${volunteer?.general_information?.last_name}`;
            })
            .join(' - ');
        },
      },
      {
        header: columnTitles.students,
        accessorFn: row => {
          return row.students
            .map(v => {
              const student = templates.students.find(s => s._id === v);
              return `${student?.general_information?.first_name} ${student?.general_information?.last_name}`;
            })
            .join(' - ');
        },
      },
      {
        header: 'Commentaire',
        accessorKey: 'comment',
        Cell: ({ cell }) => {
          return cell.getValue() ?? '';
        },
      },
    ],
    [
      columnTitles.course,
      columnTitles.students,
      columnTitles.volunteers,
      templates.students,
      templates.volunteers,
      templates.themeSubjects,
      status,
    ],
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableDensityToggle={false}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 25, 50, 100, 150, { label: 'All', value: data?.length ?? 0 }],
      }}
      muiTableBodyCellProps={{
        //simple styling with the `sx` prop, works just like a style prop in this example
        sx: {
          fontWeight: 'normal',
          fontSize: '110%',
        },
      }}
      positionActionsColumn="last"
      positionGlobalFilter="left" //show the global filter on the left side of the top toolbar
      initialState={{
        showGlobalFilter: true, //show the global filter by default
        density: 'compact',
        pagination: {
          pageSize: 25,
        },
      }}
      enableRowActions={true}
      renderRowActions={({ row }, index) => {
        return (
          <div key={index}>
            <IconButton size="small" component={Link} to={paths.front.matching.edit.replace(':id', row.original._id)}>
              <Edit color="primary" />
            </IconButton>
            {(row.original.status === 'LOCK' || row.original.status === 'POSITIONED') && (
              <IconButton
                size="small"
                component={Link}
                to={paths.front.matching.profil.replace(':id', row.original._id)}
              >
                <AccountBox color="primary" />
              </IconButton>
            )}
          </div>
        );
      }}
      enableToolbarInternalActions={true}
      renderTopToolbarCustomActions={() => {
        return (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={() => history.push(paths.front.matching.create)}
          >
            {intl.button.add}
          </Button>
        );
      }}
      tableInstanceRef={tableRef}
      autoResetPageIndex={false}
      {...tableState}
    />
  );
}

function MatchingListComponent(props) {
  const [matchings, setMatchings] = useState([]);
  const [templates, setTemplates] = useState({});
  const [loading, setLoading] = useState(true);
  const { campaign, site, config } = useConfigContext();
  const intl = props.intl.messages.scenes.matching.list;

  useEffect(() => {
    setLoading(true);
  }, [config]);

  useEffect(() => {
    if (loading) {
      Promise.all([MatchingUtils.getMatchingTemplates(campaign, site)]).then(([templates]) => {
        setMatchings(templates.matchings);
        setTemplates(templates);
        setLoading(false);
      });
    }
    return () => {};
  }, [loading, campaign, site]);

  const [classifedMatch, valideMatch] = ArrayUtils.splitArray(
    matchings,
    m => moment(m?.expired_date).diff(moment(), 'month') < 0,
  );
  const [myMatchs, otherMatchs] = ArrayUtils.splitArray(
    valideMatch,
    m => m?.user?.toString() === props?.userID?.toString(),
  );

  return (
    <Paper>
      {loading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="height-circular"
        >
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <TabAppBar stateName="matching_tab">
          <TableProvider tabLabel={intl.favorite}>
            <MatchingTable
              tableName="mine_matching"
              title={intl.favorite}
              templates={templates}
              matchingFound={myMatchs}
              intlData={props.intl}
            />
          </TableProvider>
          <TableProvider tabLabel={intl.title}>
            <MatchingTable
              tableName="all_matching"
              title={intl.title}
              templates={templates}
              matchingFound={otherMatchs}
              intlData={props.intl}
            />
          </TableProvider>
          <TableProvider tabLabel={intl.classified}>
            <ClassifiedMatching
              title={intl.classified}
              templates={templates}
              matchingFound={classifedMatch}
              intlData={props.intl}
            />
          </TableProvider>
        </TabAppBar>
      )}
    </Paper>
  );
}

const mapStateToProps = state => ({
  userID: state.Authentication.userID,
});

const MatchingList = connect(mapStateToProps)(injectIntl(MatchingListComponent));

export { MatchingList };
