import { paths } from 'routes';
import { XHTTP } from 'services';

const END_POINTS = {
  ...paths.api.stats,
};

const StatsApi = {
  getTimeline: fields => XHTTP(END_POINTS.timeline, { method: 'post', body: fields }),
  getGeneral: fields => XHTTP(END_POINTS.general, { method: 'post', body: fields }),
};

export { StatsApi };
