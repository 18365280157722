import { Check, Close } from '@mui/icons-material';
import { Button, CircularProgress, Grid, Paper, Typography, useTheme } from '@mui/material';
import { EnhancedTable } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { ComonEnums } from 'services/comon';
import { PositionedWorkshopActioner } from 'services/positionedWorkshop';
import { tokens } from 'themes';
import './style/PositionedWorkshop.css';

const PositionedEntitiesTable = ({ id, entitiesList, setLoading, intlData, ...props }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data] = useState(useMemo(() => entitiesList, [entitiesList]));
  const [skipPageReset] = useState(true);
  const allstatus = ComonEnums.getGeneralStatus();

  const intl = intlData.messages.scenes.positionedWorkshop.callList;
  const columnTitles = intl.labels;

  function setToStatusAndSend(list, type_status) {
    setLoading(true);
    const newList = list.map(x => {
      const newWorkshop = {
        ...x.original.workshop,
        workshop_managments: x.original?.workshop?.workshop_managments.map(x =>
          x.positioned_workshop === id ? { ...x, status: type_status } : x,
        ),
      };
      let newline = {
        id: x.original._id,
        entity_type: x.original.entity_type,
        workshop: newWorkshop,
      };
      return newline;
    });
    PositionedWorkshopActioner.updateList(id, newList);
  }

  const columns = useMemo(
    () => [
      {
        Header: columnTitles.mobile,
        accessor: 'general_information.mobile',
        Cell: ({ value }) => value ?? '',
      },
      {
        Header: 'Téléphone du père',
        accessor: 'general_information.dad_mobile',
        Cell: ({ value }) => value ?? '',
      },
      {
        Header: 'Téléphone de la mère',
        accessor: 'general_information.mum_mobile',
        Cell: ({ value }) => value ?? '',
      },
      {
        Header: 'Email',
        accessor: 'general_information.email.email',
        Cell: ({ value }) => value ?? '',
      },
      {
        Header: columnTitles.name,
        accessor: 'general_information',
        Cell: ({ value }) => value.last_name + ` ` + value.first_name,
      },
      {
        Header: columnTitles.status,
        accessor: 'workshop.workshop_managments',
        Cell: ({ value }) => (
          <div
            className={
              value?.find(x => x.positioned_workshop === id)?.status === 'HERE'
                ? 'status green'
                : value?.find(x => x.positioned_workshop === id)?.status === 'ABSENT'
                ? 'status red'
                : 'status grey'
            }
          >
            {allstatus.find(s => s.value === value?.find(x => x.positioned_workshop === id)?.status).label}
          </div>
        ),
      },
    ],
    [columnTitles.name, columnTitles.mobile, columnTitles.status, id, allstatus],
  );

  const actions = [
    {
      render: item => (
        <div className="buttonContainer">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Check />}
            onClick={() => setToStatusAndSend(item, 'HERE')}
            sx={{ marginRight: 1, backgroundColor: colors.greenAccent[300] }}
          >
            {intl.buttons.setPresent}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Close />}
            onClick={() => setToStatusAndSend(item, 'ABSENT')}
            sx={{ marginRight: 1, backgroundColor: colors.redAccent[500] }}
          >
            {intl.buttons.setAbsent}
          </Button>
          <Button
            variant="contained"
            startIcon={<Close />}
            onClick={() => setToStatusAndSend(item, 'EXCUSED')}
            sx={{ backgroundColor: colors.orangeAccent[500] }}
          >
            {'Mettre excusé'}
          </Button>
        </div>
      ),
      isFreeAction: false,
    },
  ];

  return (
    <EnhancedTable
      title={intl.title}
      columns={columns}
      data={data}
      actions={actions}
      skipPageReset={skipPageReset}
      useMultipleSelect={true}
    />
  );
};

const PositionedWorkshopEntitiesListComponent = ({ ...props }) => {
  const [entitiesList, setEntitiesList] = useState({});
  const [loading, setLoading] = useState(true);
  const id = props.match.params.id;

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    if (loading) {
      PositionedWorkshopActioner.entitiesList(id).then(entities => {
        let finalEntities = [...entities];
        finalEntities.sort((a, b) =>
          a.general_information.last_name > b.general_information.last_name
            ? 1
            : b.general_information.last_name > a.general_information.last_name
            ? -1
            : 0,
        );
        setEntitiesList(finalEntities);
        setLoading(false);
      });
    }
  }, [loading, id]);

  return (
    <Paper>
      {loading ? (
        <Grid container spacing={0} direction="column" alignItems="center" className="height-circular">
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <>
          {searchParams.get('workshop_name') && (
            <Typography sx={{ p: 2 }} variant="h1">
              {searchParams.get('workshop_name')}
            </Typography>
          )}
          <PositionedEntitiesTable id={id} entitiesList={entitiesList} setLoading={setLoading} intlData={props.intl} />
        </>
      )}
    </Paper>
  );
};

const PositionedWorkshopEntitiesList = injectIntl(PositionedWorkshopEntitiesListComponent);

export { PositionedWorkshopEntitiesList };
