import { AddCircle, Delete, Edit } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { toast } from 'components';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { useConfigContext } from 'hooks';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { paths } from 'routes';
import { MatchingActioner, MatchingEnums } from 'services/matching';
import { ArrayUtils } from 'tools';

function MatchingSubmitButtonComponent({
  fields,
  setFields,
  mode,
  thisId,
  initialValues,
  userID,
  templates,
  reload,
  disabled,
  ...props
}) {
  const intl = props.intl.messages.scenes.submitButtons;
  const toastMessages = props.intl.messages.toast;
  const id = thisId;
  const history = useHistory();
  const TYPES = MatchingEnums.getTypes();
  const { campaign, site } = useConfigContext();

  function setLoading(loading = true) {
    setFields(f => ({ ...f, loading }));
  }

  function setError(name) {
    setFields(f => ({ ...f, errors: { ...f.errors, [name]: true } }));
  }

  function isCheckFields(fields) {
    if (fields.selectedItems.selected.length > 1 && fields.entity.entity_type === TYPES.STUDENT) {
      setError('selected');
      return false;
    }
    return true;
  }

  function getEntityFields(fields) {
    if (!isCheckFields(fields)) {
      return {};
    }
    const newFields = {
      status: fields.status,
      duration: fields.duration,
      campaign,
      site,
      user: userID,
      focus: fields.entity._id,
      focus_type: fields.entity.entity_type,
      returned_file: fields.returned_file,
      comment: fields.comment,
    };
    if (fields.entity.entity_type === TYPES.STUDENT) {
      newFields.students = [fields.entity._id];
      newFields.volunteers = ArrayUtils.isValidArray(fields.selectedItems.selected)
        ? [fields.selectedItems.selected.map(s => s._id).shift()]
        : [];
      if (ArrayUtils.isValidArray(fields.entity.wanted_subject)) {
        newFields.course = fields.entity.wanted_subject
          .filter(s => s.select)
          .map(s => s.label)
          .shift();
      }
      newFields.level =
        fields?.entity?.school?.level !== undefined
          ? fields.entity.school.level
          : fields?.entity?.level?.initial_level !== undefined
          ? fields?.entity?.level?.initial_level
          : undefined;
    }
    if (fields.entity.entity_type === TYPES.VOLUNTEER) {
      newFields.volunteers = [fields.entity._id];
      newFields.students = ArrayUtils.isValidArray(fields.selectedItems.selected)
        ? fields.selectedItems.selected.map(s => s._id)
        : [];
      if (ArrayUtils.isValidArray(fields.entity.proposed_subject)) {
        const subject = templates.subjects.find(
          s =>
            s._id.toString() ===
            fields.entity.proposed_subject
              .filter(s => s.select)
              .map(s => s.label)
              .shift(),
        );
        newFields.course = subject?.name;
        newFields.level = subject?.level;
      }
    }
    return newFields;
  }

  function onEdit() {
    const newFields = getEntityFields(fields);
    if (newFields.status === 'POSITIONED' && newFields.students.length > 0 && newFields.volunteers.length > 0) {
      newFields.status = 'LOCK';
    }

    const fieldsToSend = { ...newFields };
    if (Object.entries(fieldsToSend).length === 0) {
      return toast.error(toastMessages.error.fieldMissing);
    }
    setLoading(true);
    MatchingActioner.update(id, fieldsToSend)
      .then(() => {
        setLoading(false);
        reload();
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function onCreate() {
    const newFields = getEntityFields(fields);

    const fieldsToSend = { ...newFields };

    if (Object.entries(fieldsToSend).length === 0) {
      return toast.error(toastMessages.error.fieldMissing);
    }

    setLoading(true);
    MatchingActioner.create(fieldsToSend)
      .then(() => {
        setLoading(false);
        history.push(paths.front.matching.home);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function onDelete() {
    setLoading(true);
    MatchingActioner.delete(id)
      .then(() => {
        setLoading(false);
        history.push(paths.front.matching.home);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <Grid justifyContent="space-evenly" container>
      {!disabled && (
        <Button
          variant="contained"
          color="primary"
          startIcon={mode === 'edit' ? <Edit /> : <AddCircle />}
          onClick={mode === 'edit' ? onEdit : onCreate}
          disabled={disabled}
        >
          {intl.save}
        </Button>
      )}
      {mode === 'edit' && (
        <ConfirmDialog
          onConfirm={onDelete}
          button={({ onClick }) => (
            <Button variant="contained" color="secondary" startIcon={<Delete />} onClick={onClick}>
              {intl.delete}
            </Button>
          )}
        />
      )}
    </Grid>
  );
}

const MatchingSubmitButton = injectIntl(MatchingSubmitButtonComponent);

export { MatchingSubmitButton };
