import { Container, Grid } from '@mui/material';
import { Selector } from 'components/Selector';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { ComonEnums } from 'services/comon';
import { ArrayToSelector, ValueUtils } from 'tools';
import { SubjectSubmitButton } from './components/SubjectSubmitButton';

const vod = ValueUtils.valueOrDefault;

function getInitialValues(values = {}) {
  return {
    name: vod(values.name, ''),
    type: vod(values.type, ''),
    level: vod(values.level, ''),
    id: vod(values._id, undefined),
  };
}

function SubjectFormComponent(props) {
  const { reload, mode, values, themes } = props;
  const initialValues = getInitialValues(values);
  const [fields, setFields] = useState({
    ...initialValues,
    errors: {
      name: false,
      type: false,
      level: false,
    },
    loading: false,
  });

  const child_level = ComonEnums.getLevelArray();
  const types = ComonEnums.getTypeArray();
  const adult_level = ComonEnums.getAdultLevelArray();
  const intl = props.intl.messages.scenes.subject.form;

  function setFieldWithErrorFunction(name) {
    return value => {
      setFields(f => ({
        ...f,
        [name]: value,
        errors: { ...f.errors, [name]: f.errors[name] && value !== '' ? false : f.errors[name] },
      }));
    };
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Selector
            labelId="name"
            label={intl.name}
            selected={fields.name}
            setSelected={setFieldWithErrorFunction('name')}
            items={ArrayToSelector.getArray(themes)}
            disabled={fields.loading}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Selector
            labelId="type"
            label={intl.type}
            selected={fields.type}
            setSelected={setFieldWithErrorFunction('type')}
            items={types}
            disabled={fields.loading}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Selector
            labelId="level"
            label={intl.level}
            selected={fields.level}
            setSelected={setFieldWithErrorFunction('level')}
            items={fields.type === 'Adulte' ? adult_level : child_level}
            disabled={fields.loading}
          />
        </Grid>
        <SubjectSubmitButton
          fields={fields}
          setFields={setFields}
          initialValues={initialValues}
          reload={reload}
          mode={mode}
        />
      </Grid>
    </Container>
  );
}

const SubjectForm = injectIntl(SubjectFormComponent);

export { SubjectForm };
