import { paths } from 'routes';
import { BigWorkshopActioner } from 'services/bigWorkshop';
import { CampaignActioner } from 'services/campaign';
import { MatchingEnums } from 'services/matching';
import { SiteActioner } from 'services/site';
import { StudentActioner } from 'services/student';
import { ThemeSubjectActioner } from 'services/themeSubject';
import { UserActioner } from 'services/user';
import { VolunteerActioner } from 'services/volunteer';

const TYPES = MatchingEnums.getTypes();

const ComonUtils = {
  getComonTemplates: async () => {
    return {
      campaigns: await CampaignActioner.list(),
      sites: await SiteActioner.list(),
      bigWorkshops: await BigWorkshopActioner.list(),
    };
  },
  getInterviewTemplates: async (campaign, site) => {
    return {
      campaigns: await CampaignActioner.list(),
      users: await UserActioner.getUserList(),
      students: await StudentActioner.list(campaign, site),
      volunteers: await VolunteerActioner.list(campaign, site),
      themes: await ThemeSubjectActioner.list(),
      sites: await SiteActioner.list(),
    };
  },
  getPorfilNames(idProfil, entities) {
    const item = entities.find(element => element._id === idProfil);
    if (item) {
      return `${item.general_information.last_name} ${item.general_information.first_name}`;
    } else {
      return 'Inconnu';
    }
  },
  getPositionedProfil(id, entities) {
    let result = [];
    if (entities.length === 0) {
      return 0;
    }
    entities.forEach(element => {
      element?.workshop?.workshop_managments?.forEach(workshop => {
        if (workshop.positioned_workshop === id) {
          result.push(element);
        }
      });
    });
    return result;
  },
  openProfil(type, value) {
    let path = '';
    if (type === TYPES.VOLUNTEER) {
      path = paths.front.volunteer.profil.replace(':id', value);
    }
    if (type === TYPES.STUDENT) {
      path = paths.front.student.profil.replace(':id', value);
    }
    const win = window.open(path, '_blank');
    win.focus();
  },
};

export { ComonUtils };
