import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { tokens } from 'themes';

const Item = ({ title, to, icon, selected, setSelected, className = '' }) => {
  return (
    <MenuItem
      active={selected === title}
      onClick={() => setSelected(title)}
      icon={icon}
      routerLink={<Link to={to} />}
      className={className}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const Navbar = ({ navLinks }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState('Dashboard');

  return (
    <Box
      sx={{
        '& .root-icon': {
          color: colors.primary[400],
        },
        '& .sub-menu .menu-item .menu-icon': {
          backgroundColor: 'transparent !important',
          color: colors.primary[600],
        },
        '& .sub-menu .menu-item': {
          backgroundColor: 'transparent !important',
          color: colors.orangeAccent[600],
        },
        '& .sub-menu': {
          color: colors.orangeAccent[600],
        },
        '.sidebar': {
          height: '100%',
        },
      }}
    >
      <Sidebar collapsed={isCollapsed}>
        <Menu>
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: '10px 0 20px 0',
            }}
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <Typography variant="h3">FRom3.0</Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            {navLinks.map((nav, indexNav) => {
              if (nav.link === undefined) {
                return (
                  <SubMenu
                    icon={<nav.Icon />}
                    label={nav.label}
                    key={indexNav}
                    onOpenChange={() => setIsCollapsed(false)}
                  >
                    {nav.items.map((subnav, index) => (
                      <Item
                        key={index}
                        title={subnav.label}
                        icon={<subnav.Icon />}
                        to={subnav.link}
                        selected={selected}
                        setSelected={value => {
                          setSelected(value);
                          setIsCollapsed(true);
                        }}
                      />
                    ))}
                  </SubMenu>
                );
              }
              return (
                <Item
                  key={indexNav}
                  title={nav.label}
                  icon={<nav.Icon />}
                  to={nav.link}
                  selected={selected}
                  setSelected={value => {
                    setSelected(value);
                    setIsCollapsed(true);
                  }}
                  className="root-icon"
                />
              );
            })}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export { Navbar };
