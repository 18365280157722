import { StudentActioner } from 'services/student';
import { ThemeSubjectActioner } from 'services/themeSubject';

const StudentUtils = {
  getStudentTemplates: async (campaign, site) => {
    return {
      students: await StudentActioner.list(campaign, site),
      themeSubjects: await ThemeSubjectActioner.list(),
    };
  },
};

export { StudentUtils };
