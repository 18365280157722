import { useTheme } from '@emotion/react';
import { Box, Button, Card, CardContent, FormControlLabel, Switch, Typography } from '@mui/material';
import { toast } from 'components';
import { DateInput } from 'components/DateInput';
import { Selector } from 'components/Selector';
import { useConfigContext } from 'hooks';
import moment from 'moment';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { StatsTimelineActioner } from 'services';
import { StudentEnums } from 'services/student';
import { tokens } from 'themes';
import { StatsGeneral } from '../General';
import './Timeline.css';

const StatsTimelineContext = createContext();

const StatsTimelineProvider = ({ children }) => {
  const config = useConfigContext();
  const [filters, setFilters] = useState({
    campaign: config.campaign,
    site: config.site,
    date: moment().utc().format(),
    dimension: 'day',
    with_compare: false,
  });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const search = useCallback(async () => {
    setLoading(true);
    const receviedData = await StatsTimelineActioner.getTimeline(filters);
    setData(receviedData);
    setLoading(false);
  }, [filters]);

  return (
    <StatsTimelineContext.Provider value={{ filters, setFilters, data, loading, search }}>
      {children}
    </StatsTimelineContext.Provider>
  );
};

const useStatsTimelineContext = () => useContext(StatsTimelineContext);

const StatsTimelineFilters = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const statsTimelineContext = useStatsTimelineContext();

  const types = StudentEnums.getTypeArray();

  const toogleStats = useMemo(() => Object.keys(statsTimelineContext.data).length > 0, [statsTimelineContext.data]);

  const handleSubmit = () => {
    if (statsTimelineContext.filters.dimension === undefined) {
      return toast.error('Merci de choisir une dimension');
    }
    statsTimelineContext.search();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <DateInput
          id="date"
          label="Date"
          name="date"
          value={moment(statsTimelineContext?.filters?.date)}
          setFieldFunction={value => statsTimelineContext?.setFilters(f => ({ ...f, date: value.utc().format() }))}
          format={'DD-MM-YYYY'}
        />
        <Selector
          className="stats-timeline-selector"
          label="Dimension"
          labelId="dimension"
          selected={statsTimelineContext?.filters.dimension}
          setSelected={value => statsTimelineContext?.setFilters(f => ({ ...f, dimension: value }))}
          items={[
            { label: 'Par jour', value: 'day' },
            { label: 'Par semaine', value: 'week' },
            { label: 'Par année', value: 'year' },
          ]}
        />
        <Selector
          className="stats-timeline-selector"
          labelId="type"
          label={"Type d'apprenant"}
          selected={statsTimelineContext.filters.student_type}
          setSelected={value => statsTimelineContext?.setFilters(f => ({ ...f, student_type: value }))}
          items={types}
          enableClear={true}
        />
        <FormControlLabel
          className="stats-timeline-selector"
          control={
            <Switch
              checked={statsTimelineContext?.filters?.with_compare}
              onChange={evt => statsTimelineContext?.setFilters(f => ({ ...f, with_compare: evt.target.checked }))}
            />
          }
          label="Comparaison"
        />
        {statsTimelineContext?.filters?.with_compare && (
          <DateInput
            id="date"
            label="Date de compraison"
            name="compare_date"
            value={moment(statsTimelineContext?.filters?.compare_date)}
            setFieldFunction={value =>
              statsTimelineContext?.setFilters(f => ({ ...f, compare_date: value.utc().format() }))
            }
            format={'DD-MM-YYYY'}
          />
        )}
        <Button className="stats-timeline-button" variant="contained" onClick={handleSubmit}>
          Recherche
        </Button>
      </Box>
      {toogleStats && (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: '2%', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ width: '20%' }}>
            <Card>
              <CardContent>
                <Typography color={'text.secondary'}>
                  {moment(statsTimelineContext.data?.initial?.date).format('DD-MM-YYYY')}
                </Typography>
                <Typography color="text.secondary">Heures faites</Typography>
                <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {`${Math.trunc(statsTimelineContext.data?.initial?.hoursDone)}h${
                    statsTimelineContext.data?.initial?.hoursDone -
                      Math.trunc(statsTimelineContext.data?.initial?.hoursDone) !==
                    0
                      ? `${
                          (statsTimelineContext.data?.initial?.hoursDone -
                            Math.trunc(statsTimelineContext.data?.initial?.hoursDone)) *
                          60
                        }`
                      : ''
                  }`}
                </Typography>
                {statsTimelineContext?.filters?.with_compare && statsTimelineContext?.data?.compare && (
                  <>
                    <Typography color={'text.secondary'}>
                      {moment(statsTimelineContext.data?.compare?.date).format('DD-MM-YYYY')}
                    </Typography>
                    <Typography color="text.secondary">Heures faites</Typography>
                    <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {`${Math.trunc(statsTimelineContext.data?.compare?.hoursDone)}h${
                        statsTimelineContext.data?.compare?.hoursDone -
                          Math.trunc(statsTimelineContext.data?.compare?.hoursDone) !==
                        0
                          ? `${
                              (statsTimelineContext.data?.compare?.hoursDone -
                                Math.trunc(statsTimelineContext.data?.compare?.hoursDone)) *
                              60
                            }`
                          : ''
                      }`}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color:
                          statsTimelineContext.data?.initial?.hoursDone -
                            statsTimelineContext.data?.compare?.hoursDone >
                          0
                            ? colors.greenAccent[500]
                            : colors.redAccent[500],
                      }}
                    >
                      {isNaN(
                        Math.trunc(
                          ((statsTimelineContext.data?.initial?.hoursDone -
                            statsTimelineContext.data?.compare?.hoursDone) /
                            statsTimelineContext.data?.initial?.hoursDone) *
                            100,
                        ),
                      )
                        ? 0
                        : Math.trunc(
                            ((statsTimelineContext.data?.initial?.hoursDone -
                              statsTimelineContext.data?.compare?.hoursDone) /
                              statsTimelineContext.data?.initial?.hoursDone) *
                              100,
                          )}{' '}
                      %
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: '20%', ml: 2 }}>
            <Card>
              <CardContent>
                <Typography color={'text.secondary'}>
                  {moment(statsTimelineContext.data?.initial?.date).format('DD-MM-YYYY')}
                </Typography>
                <Typography color="text.secondary">Heures manquées</Typography>
                <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {`${Math.trunc(statsTimelineContext.data?.initial?.hoursMissed)}h${
                    statsTimelineContext.data?.initial?.hoursMissed -
                      Math.trunc(statsTimelineContext.data?.initial?.hoursMissed) !==
                    0
                      ? `${
                          (statsTimelineContext.data?.initial?.hoursMissed -
                            Math.trunc(statsTimelineContext.data?.initial?.hoursMissed)) *
                          60
                        }`
                      : ''
                  }`}
                </Typography>
                {statsTimelineContext?.filters?.with_compare && statsTimelineContext?.data?.compare && (
                  <>
                    <Typography color={'text.secondary'}>
                      {moment(statsTimelineContext.data?.compare?.date).format('DD-MM-YYYY')}
                    </Typography>
                    <Typography color="text.secondary">Heures manquées</Typography>
                    <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {`${Math.trunc(statsTimelineContext.data?.compare?.hoursMissed)}h${
                        statsTimelineContext.data?.compare?.hoursMissed -
                          Math.trunc(statsTimelineContext.data?.compare?.hoursMissed) !==
                        0
                          ? `${
                              (statsTimelineContext.data?.compare?.hoursMissed -
                                Math.trunc(statsTimelineContext.data?.compare?.hoursMissed)) *
                              60
                            }`
                          : ''
                      }`}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color:
                          statsTimelineContext.data?.initial?.hoursMissed -
                            statsTimelineContext.data?.compare?.hoursMissed >
                          0
                            ? colors.greenAccent[500]
                            : colors.redAccent[500],
                      }}
                    >
                      {isNaN(
                        Math.trunc(
                          ((statsTimelineContext.data?.initial?.hoursMissed -
                            statsTimelineContext.data?.compare?.hoursMissed) /
                            statsTimelineContext.data?.initial?.hoursMissed) *
                            100,
                        ),
                      )
                        ? 0
                        : Math.trunc(
                            ((statsTimelineContext.data?.initial?.hoursMissed -
                              statsTimelineContext.data?.compare?.hoursMissed) /
                              statsTimelineContext.data?.initial?.hoursMissed) *
                              100,
                          )}{' '}
                      %
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: '20%', ml: 2 }}>
            <Card>
              <CardContent>
                <Typography color={'text.secondary'}>
                  {moment(statsTimelineContext.data?.initial?.date).format('DD-MM-YYYY')}
                </Typography>
                <Typography color="text.secondary">Heures rattrapées</Typography>
                <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {`${Math.trunc(statsTimelineContext.data?.initial?.hoursMadeUp)}h${
                    statsTimelineContext.data?.initial?.hoursMadeUp -
                      Math.trunc(statsTimelineContext.data?.initial?.hoursMadeUp) !==
                    0
                      ? `${
                          (statsTimelineContext.data?.initial?.hoursMadeUp -
                            Math.trunc(statsTimelineContext.data?.initial?.hoursMadeUp)) *
                          60
                        }`
                      : ''
                  }`}
                </Typography>
                {statsTimelineContext?.filters?.with_compare && statsTimelineContext?.data?.compare && (
                  <>
                    <Typography color={'text.secondary'}>
                      {moment(statsTimelineContext.data?.compare?.date).format('DD-MM-YYYY')}
                    </Typography>
                    <Typography color="text.secondary">Heures rattrapées</Typography>
                    <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {`${Math.trunc(statsTimelineContext.data?.compare?.hoursMadeUp)}h${
                        statsTimelineContext.data?.compare?.hoursMadeUp -
                          Math.trunc(statsTimelineContext.data?.compare?.hoursMadeUp) !==
                        0
                          ? `${
                              (statsTimelineContext.data?.compare?.hoursMadeUp -
                                Math.trunc(statsTimelineContext.data?.compare?.hoursMadeUp)) *
                              60
                            }`
                          : ''
                      }`}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color:
                          statsTimelineContext.data?.initial?.hoursMadeUp -
                            statsTimelineContext.data?.compare?.hoursMadeUp >
                          0
                            ? colors.greenAccent[500]
                            : colors.redAccent[500],
                      }}
                    >
                      {isNaN(
                        Math.trunc(
                          ((statsTimelineContext.data?.initial?.hoursMadeUp -
                            statsTimelineContext.data?.compare?.hoursMadeUp) /
                            statsTimelineContext.data?.initial?.hoursMadeUp) *
                            100,
                        ),
                      )
                        ? 0
                        : Math.trunc(
                            ((statsTimelineContext.data?.initial?.hoursMadeUp -
                              statsTimelineContext.data?.compare?.hoursMadeUp) /
                              statsTimelineContext.data?.initial?.hoursMadeUp) *
                              100,
                          )}{' '}
                      %
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: '20%', ml: 2 }}>
            <Card>
              <CardContent>
                <Typography color={'text.secondary'}>
                  {moment(statsTimelineContext.data?.initial?.date).format('DD-MM-YYYY')}
                </Typography>
                <Typography color="text.secondary">Heures totales</Typography>
                <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {`${Math.trunc(statsTimelineContext.data?.initial?.totalHours)}h${
                    statsTimelineContext.data?.initial?.totalHours -
                      Math.trunc(statsTimelineContext.data?.initial?.totalHours) !==
                    0
                      ? `${
                          (statsTimelineContext.data?.initial?.totalHours -
                            Math.trunc(statsTimelineContext.data?.initial?.totalHours)) *
                          60
                        }`
                      : ''
                  }`}
                </Typography>
                {statsTimelineContext?.filters?.with_compare && statsTimelineContext?.data?.compare && (
                  <>
                    <Typography color={'text.secondary'}>
                      {moment(statsTimelineContext.data?.compare?.date).format('DD-MM-YYYY')}
                    </Typography>
                    <Typography color="text.secondary">Heures totales</Typography>
                    <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {`${Math.trunc(statsTimelineContext.data?.compare?.totalHours)}h${
                        statsTimelineContext.data?.compare?.totalHours -
                          Math.trunc(statsTimelineContext.data?.compare?.totalHours) !==
                        0
                          ? `${
                              (statsTimelineContext.data?.compare?.totalHours -
                                Math.trunc(statsTimelineContext.data?.compare?.totalHours)) *
                              60
                            }`
                          : ''
                      }`}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color:
                          statsTimelineContext.data?.initial?.totalHours -
                            statsTimelineContext.data?.compare?.totalHours >
                          0
                            ? colors.greenAccent[500]
                            : colors.redAccent[500],
                      }}
                    >
                      {isNaN(
                        Math.trunc(
                          ((statsTimelineContext.data?.initial?.totalHours -
                            statsTimelineContext.data?.compare?.totalHours) /
                            statsTimelineContext.data?.initial?.totalHours) *
                            100,
                        ),
                      )
                        ? 0
                        : Math.trunc(
                            ((statsTimelineContext.data?.initial?.totalHours -
                              statsTimelineContext.data?.compare?.totalHours) /
                              statsTimelineContext.data?.initial?.totalHours) *
                              100,
                          )}{' '}
                      %
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const StatsTimeline = () => {
  return (
    <>
      <StatsTimelineProvider>
        <StatsTimelineFilters />
      </StatsTimelineProvider>
      <StatsGeneral />
    </>
    
  );
};

export { StatsTimeline };
