import { AddCircle } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Selector } from 'components/Selector';
import { Table } from 'components/Table';
import { TextInput } from 'components/TextInput';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { ComonEnums } from 'services/comon';

function AddReminderComponent({ addReminders, disabled, ...props }) {
  const emptyReminder = { date: new Date(), status: 'WAITING' };
  const [newReminder, setNewReminder] = useState(emptyReminder);

  const status = ComonEnums.getReminderStatus();
  const intl = props.intl.messages.scenes.common.reminder;
  const labels = intl.labels;

  function setFieldFunction(name) {
    return value => {
      setNewReminder(a => ({ ...a, [name]: value }));
    };
  }

  function addNewReminders() {
    addReminders({ ...newReminder });
  }

  const columns = [
    {
      id: 'day',
      align: 'center',
      label: labels.date,
      render: row => (
        <DatePicker
          renderInput={props => <TextField fullWidth style={{ marginTop: '3%' }} {...props} />}
          margin="normal"
          id="date"
          label={'Date'}
          inputFormat="DD/MM/YYYY"
          name="submit_date"
          value={row.date}
          onChange={setFieldFunction('date')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          inputVariant="outlined"
          fullWidth
          error={false}
          invalidDateMessage={''}
        />
      ),
    },
    {
      id: 'comment',
      align: 'left',
      label: labels.comment,
      render: (row, index) => (
        <TextInput name="comment" label={labels.comment} value={row.comment} setField={setFieldFunction('comment')} />
      ),
    },
    {
      id: 'status',
      align: 'center',
      render: (row, index) => (
        <Selector
          labelId="status"
          label={labels.status}
          selected={newReminder.status}
          setSelected={setFieldFunction('status')}
          items={status}
          disabled={disabled}
        />
      ),
    },
    {
      align: 'center',
      render: () => (
        <IconButton
          disabled={disabled}
          onClick={() => {
            addNewReminders();
            setNewReminder(emptyReminder);
          }}
        >
          <AddCircle color={disabled ? 'disabled' : 'secondary'} />
        </IconButton>
      ),
    },
  ];

  return <Table hideHeader columns={columns} rows={[newReminder]} className="overflow-y-hidden" />;
}

const AddReminders = injectIntl(AddReminderComponent);

export { AddReminders };
