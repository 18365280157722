import { TableProvider } from 'hooks';
import { paths } from 'routes';
import { AuthenticatedRoute } from 'routes/components';
import { Timeline, TimelineStats } from 'scenes';

const timelineRoutes = [
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.timeline.home,
      exact: true,
      component: Timeline,
    },
  },
  {
    render: AuthenticatedRoute,
    props: {
      path: paths.front.timeline.stats,
      exact: true,
      component: () => (
        <TableProvider>
          <TimelineStats />
        </TableProvider>
      ),
    },
  },
];

export { timelineRoutes };
