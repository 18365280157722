import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import 'moment/locale/fr';
import { injectIntl } from 'react-intl';

const DateInputComponent = props => {
  const { id, label, name, inputVariant, value, setFieldFunction, ampm, format, fullWidth, disabled } = props;

  return (
    <DatePicker
      renderInput={props => <TextField fullWidth={fullWidth} style={{ marginTop: '3%' }} {...props} />}
      margin="normal"
      id={id}
      label={label}
      inputFormat={format}
      name={name}
      value={value}
      onChange={value => setFieldFunction(value)}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      inputVariant={inputVariant}
      disabled={disabled}
      ampm={ampm}
      fullWidth={fullWidth}
    />
  );
};

DateInputComponent.defaultProps = {
  id: 'Date',
  label: 'Date',
  name: 'Date',
  value: null,
  setFieldFunction: () => {},
  ampm: false,
  format: 'DD/MM/yyyy HH:mm',
  inputVariant: 'outlined',
  fullWidth: false,
  disabled: false,
};

const DateInput = injectIntl(DateInputComponent);

export { DateInput };
