import { AccountBox } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { Loader } from 'components';
import { useConfigContext } from 'hooks';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { StudentForm } from 'scenes';
import { ComonUtils } from 'services/comon';
import { StudentActioner, StudentUtils } from 'services/student';
import './style/StudentForm.css';

function StudentEditComponent(props) {
  const [, reload] = useState();
  const { campaign, site } = useConfigContext();
  const id = props.match.params.id;
  const intl = props.intl.messages.scenes.student.edit;

  function editForm(values, templates, studentsTemplates) {
    return (
      <StudentForm
        values={values}
        templates={templates}
        studentsTemplates={studentsTemplates}
        reload={() => reload({})}
        mode={'edit'}
      />
    );
  }

  function loadInfos() {
    return StudentActioner.get(id)
      .then(infos => {
        const values = { ...infos, id };
        return values;
      })
      .catch(err => {
        throw err;
      });
  }

  function renderEditForm(render) {
    Promise.all([loadInfos(), ComonUtils.getComonTemplates(), StudentUtils.getStudentTemplates(campaign, site)]).then(
      ([values, templates, studentsTemplates]) => {
        render(editForm(values, templates, studentsTemplates));
      },
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="full-width">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        className="full-width strongTitle"
      >
        <h2>{intl.title}</h2>
        <IconButton size="medium" component={Link} to={paths.front.student.profil.replace(':id', id)}>
          <AccountBox color="primary" />
        </IconButton>
      </Box>
      <Loader render={renderEditForm} />
    </Box>
  );
}

const StudentEdit = injectIntl(StudentEditComponent);

export { StudentEdit };
