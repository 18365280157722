import { MatchingActioner } from 'services/matching';
import { StudentActioner } from 'services/student';
import { SubjectActioner } from 'services/subject';
import { ThemeSubjectActioner } from 'services/themeSubject';
import { VolunteerActioner } from 'services/volunteer';

const MatchingUtils = {
  getMatchingTemplates: async (campaign, site) => {
    return {
      students: await StudentActioner.list(campaign, site),
      volunteers: await VolunteerActioner.list(campaign, site),
      themeSubjects: await ThemeSubjectActioner.list(),
      subjects: await SubjectActioner.list(),
      matchings: await MatchingActioner.list(campaign, site),
    };
  },
  translateLevel: level => {
    switch (level) {
      case 'A1.1':
        return 'Grand Débutant';
      case 'A1':
        return 'Débutant';
      case 'A2':
        return 'Faux Débutant';
      case 'B1':
        return 'Intermédiaire';
      case 'B2':
        return 'Perfectionnement';
      case 'C1':
      case 'C2':
        return 'Perfectionnement';
      default:
        return level;
    }
  },
};

export { MatchingUtils };
