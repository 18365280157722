import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { injectIntl } from 'react-intl';

function ListDragComponent(props) {
  const { className, idkey, items, type, children, error } = props;
  return (
    <Droppable className={`${className} ${error ? 'itemError' : ''}`} key={idkey} droppableId={type} type="PERSON">
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={snapshot.isDraggingOver ? 'dropper dropOver' : 'dropper'}
          >
            {items?.map((item, index) => {
              return React.cloneElement(children, {
                item: item,
                index: index,
                type: type,
                key: item._id,
              });
            })}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
}

const ListDrag = injectIntl(ListDragComponent);

export { ListDrag };
