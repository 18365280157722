import { ObjectUtils } from 'tools';

function defaultComparator(a, b) {
  return a === b;
}

const ArrayUtils = {
  compareArrays: (a, b, comparator = defaultComparator) => {
    if (a === b) return true;
    if (!Array.isArray(a) || !Array.isArray(b)) return false;
    if (a.length !== b.length) return false;

    const aClone = [...a];
    const bClone = [...b];

    aClone.sort();
    bClone.sort();

    for (let i = 0; i < aClone.length; ++i) {
      if (!comparator(aClone[i], bClone[i])) {
        return false;
      }
    }
    return true;
  },

  copyJsonObjectArray: array => JSON.parse(JSON.stringify(array)),

  loop: (n, cb) => {
    for (let i = 0; i < n; i++) {
      cb(i, n);
    }
  },

  diffArraysAdd: (old, newTab) => {
    var diff = [];
    if (old.length === 0) {
      diff = newTab;
    }
    for (let i = 0; i < newTab.length; i++) {
      var isIN = false;
      for (let j = 0; j < old.length; j++) {
        if (!ObjectUtils.compareSimpleObjects(newTab[i], old[j]) && !isIN) {
          diff.push(newTab[i]);
        } else {
          isIN = true;
        }
      }
    }
    return diff;
  },
  // Format tab = [['tag1', tableau1], ['tag2',tableau2]]
  addTagArray: tab => {
    var newTab = [];
    if (!Array.isArray(tab)) {
      return [];
    }
    tab.forEach(element => {
      element[1].forEach(elem => {
        newTab.push({ ...elem, entity_type: element[0] });
      });
    });
    return newTab;
  },

  isValidArray: arr => {
    return arr !== undefined && arr.length > 0;
  },

  splitArray: (arr, split) => {
    if (!Array.isArray(arr)) {
      return arr;
    }
    if (typeof split !== 'function') {
      return arr;
    }
    const arr_true = [];
    const arr_false = [];
    arr.forEach(item => {
      if (split(item)) {
        arr_true.push(item);
      } else {
        arr_false.push(item);
      }
    });
    return [arr_true, arr_false];
  },
};

export { ArrayUtils };
