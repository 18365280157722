import { Grid } from '@mui/material';
import { injectIntl } from 'react-intl';
import { AvailabilitiesUtils } from 'tools';

function ItemMatchingComponent(props) {
  const { item } = props;

  const common_ai = AvailabilitiesUtils.groupCommonAvailabilities([
    ...(item?.students ?? []),
    item?.volunteers[0] ?? {},
  ]);

  return (
    <Grid container item xs={12} sm={12} className="padding-small" spacing={1}>
      <div className="item-spliter"></div>
      <Grid className="padding-small marginB20" container item xs={6} sm={6}>
        <div>
          <div>
            <h6>Bénévoles</h6>
          </div>
          <div>
            <h5>{item?.volunteers[0]?.general_information?.last_name ?? 'Pas de bénévoles'}</h5>
          </div>
        </div>
      </Grid>
      <Grid className="padding-small" container item xs={6} sm={6}>
        <div>
          <div>
            <h6>Apprenants</h6>
          </div>
          <div>
            {item?.students?.map(s => (
              <h5 key={s._id}>{s.general_information.last_name}</h5>
            ))}
            {item?.students === undefined || (item.students.length === 0 && <h5>Pas d'apprenants</h5>)}
          </div>
        </div>
      </Grid>
      <Grid container item xs={12} sm={12} className="padding-small">
        <Grid container item xs={6} sm={6} className="padding-small" direction="column">
          <h6>
            {item?.course?.name} - {item?.level}
          </h6>
          <h6>Durée : {item.duration} minutes</h6>
        </Grid>
        <Grid container item xs={6} sm={6} className="padding-small item-match-ai" direction="column">
          {common_ai.map(c_ai => (
            <h6 key={`${c_ai.day}:${c_ai.start_hour}-${c_ai.end_hour}`}>{`${c_ai.day?.toUpperCase()}: ${
              c_ai.start_hour
            }-${c_ai.end_hour}`}</h6>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

const ItemMatching = injectIntl(ItemMatchingComponent);

export { ItemMatching };
