import { Grid } from '@mui/material';
import { injectIntl } from 'react-intl';

function ItemMatchingMenuComponent(props) {
  const { item } = props;

  return (
    <Grid container item xs={12} sm={12} className="padding-normal" spacing={1}>
      <Grid className="padding-normal marginB20" container item xs={6} sm={6}>
        <div className="text-centered full-width">
          <h6 className="marginB20">Bénévoles</h6>
          <h5>{item?.volunteers[0]?.general_information?.last_name ?? 'Pas de bénévoles'}</h5>
        </div>
      </Grid>
      <Grid className="padding-normal marginB20" container item xs={6} sm={6}>
        <div className="text-centered full-width">
          <div className="marginB20">
            <h6>Apprenants</h6>
          </div>
          <div>
            {item?.students?.map(s => (
              <h5 key={s._id}>{s.general_information.last_name}</h5>
            ))}
            {item?.students === undefined || (item.students.length === 0 && <h5>Pas d'apprenants</h5>)}
          </div>
        </div>
      </Grid>
      <Grid className="full-width" justifyContent="space-around" container>
        <h6>
          {item?.course?.name} - {item?.level}
        </h6>
        <h6>Durée : {item.duration} minutes</h6>
      </Grid>
    </Grid>
  );
}

const ItemMatchingMenu = injectIntl(ItemMatchingMenuComponent);

export { ItemMatchingMenu };
