import { TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import 'moment/locale/fr';
import { injectIntl } from 'react-intl';

const TimeInputComponent = props => {
  const { label, value, setFieldFunction, ampm, invalidDateMessage, disabled } = props;

  const checked_value = value ? moment(value, 'HH:mm') : null;

  return (
    <TimePicker
      inputVariant="outlined"
      renderInput={props => <TextField fullWidth style={{ marginTop: '3%' }} {...props} />}
      label={label}
      ampm={ampm}
      value={checked_value}
      onChange={value => setFieldFunction(value.format('HH:mm'))}
      invalidDateMessage={invalidDateMessage}
      disabled={disabled}
      fullWidth
    />
  );
};

TimeInputComponent.defaultProps = {
  labelId: 'Heure',
  label: 'Heure',
  value: null,
  setFieldFunction: () => {},
  ampm: false,
  invalidDateMessage: '',
  disabled: false,
};

const TimeInput = injectIntl(TimeInputComponent);

export { TimeInput };
