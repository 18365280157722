import { useEffect, useState } from 'react';

function getStorageValue(key, defaultValue) {
  const saved = localStorage.getItem('lacle');
  const initial = JSON.parse(saved);
  return initial?.[key] || defaultValue;
}

function setStorageValue(key, value) {
  const saved = localStorage.getItem('lacle');
  const inital = JSON.parse(saved);
  const tmpInitial = { ...inital, [key]: value };
  localStorage.setItem('lacle', JSON.stringify(tmpInitial));
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    setStorageValue(key, value);
  }, [key, value]);

  return [value, setValue];
};
