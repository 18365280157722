import { Box } from '@mui/material';
import { Loader } from 'components';
import { useConfigContext } from 'hooks';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { PositionedWorkshopActioner, PositionedWorkshopUtils } from 'services/positionedWorkshop';
import { PositionedWorkshopForm } from './PositionedWorkshopForm';

function PositionedWorkshopEditComponent(props) {
  const [, reload] = useState();
  const id = props.match.params.id;
  const intl = props.intl.messages.scenes.positionedWorkshop.edit;
  const { campaign, site } = useConfigContext();
  function editForm(values, templates, participants) {
    return (
      <PositionedWorkshopForm
        values={{ ...values, id }}
        templates={templates}
        participants={participants}
        reload={() => reload({})}
        mode="edit"
      />
    );
  }

  function renderEditForm(render) {
    Promise.all([
      PositionedWorkshopActioner.get(id),
      PositionedWorkshopUtils.getPositionedWorkshopTemplates(campaign, site),
      PositionedWorkshopActioner.entitiesList(id),
    ]).then(([values, templates, participants]) => {
      render(editForm(values, templates, participants));
    });
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="full-width">
      <h2>{intl.title}</h2>
      <Loader render={renderEditForm} />
    </Box>
  );
}

const PositionedWorkshopEdit = injectIntl(PositionedWorkshopEditComponent);

export { PositionedWorkshopEdit };
